// Variables
@import "variables";

// PrimeVue with PrimeFlex
@import '../../../node_modules/primeicons/primeicons.css';
@import '../../../node_modules/primevue/resources/themes/lara-light-green/theme.css';
@import '../../../node_modules/primeflex/primeflex.css';

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";

// Custom
@import "custom";
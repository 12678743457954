@font-face {
  font-family: primeicons;
  font-display: block;
  src: url("primeicons.81e5fde4.eot");
  src: url("primeicons.81e5fde4.eot#iefix") format("embedded-opentype"), url("primeicons.bb5567ec.woff2") format("woff2"), url("primeicons.51e61582.woff") format("woff"), url("primeicons.05540d0d.ttf") format("truetype"), url("primeicons.34fb6b01.svg#primeicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.pi {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: primeicons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.pi:before {
  --webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.pi-fw {
  text-align: center;
  width: 1.28571em;
}

.pi-spin {
  animation: 2s linear infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.pi-eraser:before {
  content: "";
}

.pi-stopwatch:before {
  content: "";
}

.pi-verified:before {
  content: "";
}

.pi-delete-left:before {
  content: "";
}

.pi-hourglass:before {
  content: "";
}

.pi-truck:before {
  content: "";
}

.pi-wrench:before {
  content: "";
}

.pi-microphone:before {
  content: "";
}

.pi-megaphone:before {
  content: "";
}

.pi-arrow-right-arrow-left:before {
  content: "";
}

.pi-bitcoin:before {
  content: "";
}

.pi-file-edit:before {
  content: "";
}

.pi-language:before {
  content: "";
}

.pi-file-export:before {
  content: "";
}

.pi-file-import:before {
  content: "";
}

.pi-file-word:before {
  content: "";
}

.pi-gift:before {
  content: "";
}

.pi-cart-plus:before {
  content: "";
}

.pi-thumbs-down-fill:before {
  content: "";
}

.pi-thumbs-up-fill:before {
  content: "";
}

.pi-arrows-alt:before {
  content: "";
}

.pi-calculator:before {
  content: "";
}

.pi-sort-alt-slash:before {
  content: "";
}

.pi-arrows-h:before {
  content: "";
}

.pi-arrows-v:before {
  content: "";
}

.pi-pound:before {
  content: "";
}

.pi-prime:before {
  content: "";
}

.pi-chart-pie:before {
  content: "";
}

.pi-reddit:before {
  content: "";
}

.pi-code:before {
  content: "";
}

.pi-sync:before {
  content: "";
}

.pi-shopping-bag:before {
  content: "";
}

.pi-server:before {
  content: "";
}

.pi-database:before {
  content: "";
}

.pi-hashtag:before {
  content: "";
}

.pi-bookmark-fill:before {
  content: "";
}

.pi-filter-fill:before {
  content: "";
}

.pi-heart-fill:before {
  content: "";
}

.pi-flag-fill:before {
  content: "";
}

.pi-circle:before {
  content: "";
}

.pi-circle-fill:before {
  content: "";
}

.pi-bolt:before {
  content: "";
}

.pi-history:before {
  content: "";
}

.pi-box:before {
  content: "";
}

.pi-at:before {
  content: "";
}

.pi-arrow-up-right:before {
  content: "";
}

.pi-arrow-up-left:before {
  content: "";
}

.pi-arrow-down-left:before {
  content: "";
}

.pi-arrow-down-right:before {
  content: "";
}

.pi-telegram:before {
  content: "";
}

.pi-stop-circle:before {
  content: "";
}

.pi-stop:before {
  content: "";
}

.pi-whatsapp:before {
  content: "";
}

.pi-building:before {
  content: "";
}

.pi-qrcode:before {
  content: "";
}

.pi-car:before {
  content: "";
}

.pi-instagram:before {
  content: "";
}

.pi-linkedin:before {
  content: "";
}

.pi-send:before {
  content: "";
}

.pi-slack:before {
  content: "";
}

.pi-sun:before {
  content: "";
}

.pi-moon:before {
  content: "";
}

.pi-vimeo:before {
  content: "";
}

.pi-youtube:before {
  content: "";
}

.pi-flag:before {
  content: "";
}

.pi-wallet:before {
  content: "";
}

.pi-map:before {
  content: "";
}

.pi-link:before {
  content: "";
}

.pi-credit-card:before {
  content: "";
}

.pi-discord:before {
  content: "";
}

.pi-percentage:before {
  content: "";
}

.pi-euro:before {
  content: "";
}

.pi-book:before {
  content: "";
}

.pi-shield:before {
  content: "";
}

.pi-paypal:before {
  content: "";
}

.pi-amazon:before {
  content: "";
}

.pi-phone:before {
  content: "";
}

.pi-filter-slash:before {
  content: "";
}

.pi-facebook:before {
  content: "";
}

.pi-github:before {
  content: "";
}

.pi-twitter:before {
  content: "";
}

.pi-step-backward-alt:before {
  content: "";
}

.pi-step-forward-alt:before {
  content: "";
}

.pi-forward:before {
  content: "";
}

.pi-backward:before {
  content: "";
}

.pi-fast-backward:before {
  content: "";
}

.pi-fast-forward:before {
  content: "";
}

.pi-pause:before {
  content: "";
}

.pi-play:before {
  content: "";
}

.pi-compass:before {
  content: "";
}

.pi-id-card:before {
  content: "";
}

.pi-ticket:before {
  content: "";
}

.pi-file-o:before {
  content: "";
}

.pi-reply:before {
  content: "";
}

.pi-directions-alt:before {
  content: "";
}

.pi-directions:before {
  content: "";
}

.pi-thumbs-up:before {
  content: "";
}

.pi-thumbs-down:before {
  content: "";
}

.pi-sort-numeric-down-alt:before {
  content: "";
}

.pi-sort-numeric-up-alt:before {
  content: "";
}

.pi-sort-alpha-down-alt:before {
  content: "";
}

.pi-sort-alpha-up-alt:before {
  content: "";
}

.pi-sort-numeric-down:before {
  content: "";
}

.pi-sort-numeric-up:before {
  content: "";
}

.pi-sort-alpha-down:before {
  content: "";
}

.pi-sort-alpha-up:before {
  content: "";
}

.pi-sort-alt:before {
  content: "";
}

.pi-sort-amount-up:before {
  content: "";
}

.pi-sort-amount-down:before {
  content: "";
}

.pi-sort-amount-down-alt:before {
  content: "";
}

.pi-sort-amount-up-alt:before {
  content: "";
}

.pi-palette:before {
  content: "";
}

.pi-undo:before {
  content: "";
}

.pi-desktop:before {
  content: "";
}

.pi-sliders-v:before {
  content: "";
}

.pi-sliders-h:before {
  content: "";
}

.pi-search-plus:before {
  content: "";
}

.pi-search-minus:before {
  content: "";
}

.pi-file-excel:before {
  content: "";
}

.pi-file-pdf:before {
  content: "";
}

.pi-check-square:before {
  content: "";
}

.pi-chart-line:before {
  content: "";
}

.pi-user-edit:before {
  content: "";
}

.pi-exclamation-circle:before {
  content: "";
}

.pi-android:before {
  content: "";
}

.pi-google:before {
  content: "";
}

.pi-apple:before {
  content: "";
}

.pi-microsoft:before {
  content: "";
}

.pi-heart:before {
  content: "";
}

.pi-mobile:before {
  content: "";
}

.pi-tablet:before {
  content: "";
}

.pi-key:before {
  content: "";
}

.pi-shopping-cart:before {
  content: "";
}

.pi-comments:before {
  content: "";
}

.pi-comment:before {
  content: "";
}

.pi-briefcase:before {
  content: "";
}

.pi-bell:before {
  content: "";
}

.pi-paperclip:before {
  content: "";
}

.pi-share-alt:before {
  content: "";
}

.pi-envelope:before {
  content: "";
}

.pi-volume-down:before {
  content: "";
}

.pi-volume-up:before {
  content: "";
}

.pi-volume-off:before {
  content: "";
}

.pi-eject:before {
  content: "";
}

.pi-money-bill:before {
  content: "";
}

.pi-images:before {
  content: "";
}

.pi-image:before {
  content: "";
}

.pi-sign-in:before {
  content: "";
}

.pi-sign-out:before {
  content: "";
}

.pi-wifi:before {
  content: "";
}

.pi-sitemap:before {
  content: "";
}

.pi-chart-bar:before {
  content: "";
}

.pi-camera:before {
  content: "";
}

.pi-dollar:before {
  content: "";
}

.pi-lock-open:before {
  content: "";
}

.pi-table:before {
  content: "";
}

.pi-map-marker:before {
  content: "";
}

.pi-list:before {
  content: "";
}

.pi-eye-slash:before {
  content: "";
}

.pi-eye:before {
  content: "";
}

.pi-folder-open:before {
  content: "";
}

.pi-folder:before {
  content: "";
}

.pi-video:before {
  content: "";
}

.pi-inbox:before {
  content: "";
}

.pi-lock:before {
  content: "";
}

.pi-unlock:before {
  content: "";
}

.pi-tags:before {
  content: "";
}

.pi-tag:before {
  content: "";
}

.pi-power-off:before {
  content: "";
}

.pi-save:before {
  content: "";
}

.pi-question-circle:before {
  content: "";
}

.pi-question:before {
  content: "";
}

.pi-copy:before {
  content: "";
}

.pi-file:before {
  content: "";
}

.pi-clone:before {
  content: "";
}

.pi-calendar-times:before {
  content: "";
}

.pi-calendar-minus:before {
  content: "";
}

.pi-calendar-plus:before {
  content: "";
}

.pi-ellipsis-v:before {
  content: "";
}

.pi-ellipsis-h:before {
  content: "";
}

.pi-bookmark:before {
  content: "";
}

.pi-globe:before {
  content: "";
}

.pi-replay:before {
  content: "";
}

.pi-filter:before {
  content: "";
}

.pi-print:before {
  content: "";
}

.pi-align-right:before {
  content: "";
}

.pi-align-left:before {
  content: "";
}

.pi-align-center:before {
  content: "";
}

.pi-align-justify:before {
  content: "";
}

.pi-cog:before {
  content: "";
}

.pi-cloud-download:before {
  content: "";
}

.pi-cloud-upload:before {
  content: "";
}

.pi-cloud:before {
  content: "";
}

.pi-pencil:before {
  content: "";
}

.pi-users:before {
  content: "";
}

.pi-clock:before {
  content: "";
}

.pi-user-minus:before {
  content: "";
}

.pi-user-plus:before {
  content: "";
}

.pi-trash:before {
  content: "";
}

.pi-external-link:before {
  content: "";
}

.pi-window-maximize:before {
  content: "";
}

.pi-window-minimize:before {
  content: "";
}

.pi-refresh:before {
  content: "";
}

.pi-user:before {
  content: "";
}

.pi-exclamation-triangle:before {
  content: "";
}

.pi-calendar:before {
  content: "";
}

.pi-chevron-circle-left:before {
  content: "";
}

.pi-chevron-circle-down:before {
  content: "";
}

.pi-chevron-circle-right:before {
  content: "";
}

.pi-chevron-circle-up:before {
  content: "";
}

.pi-angle-double-down:before {
  content: "";
}

.pi-angle-double-left:before {
  content: "";
}

.pi-angle-double-right:before {
  content: "";
}

.pi-angle-double-up:before {
  content: "";
}

.pi-angle-down:before {
  content: "";
}

.pi-angle-left:before {
  content: "";
}

.pi-angle-right:before {
  content: "";
}

.pi-angle-up:before {
  content: "";
}

.pi-upload:before {
  content: "";
}

.pi-download:before {
  content: "";
}

.pi-ban:before {
  content: "";
}

.pi-star-fill:before {
  content: "";
}

.pi-star:before {
  content: "";
}

.pi-chevron-left:before {
  content: "";
}

.pi-chevron-right:before {
  content: "";
}

.pi-chevron-down:before {
  content: "";
}

.pi-chevron-up:before {
  content: "";
}

.pi-caret-left:before {
  content: "";
}

.pi-caret-right:before {
  content: "";
}

.pi-caret-down:before {
  content: "";
}

.pi-caret-up:before {
  content: "";
}

.pi-search:before {
  content: "";
}

.pi-check:before {
  content: "";
}

.pi-check-circle:before {
  content: "";
}

.pi-times:before {
  content: "";
}

.pi-times-circle:before {
  content: "";
}

.pi-plus:before {
  content: "";
}

.pi-plus-circle:before {
  content: "";
}

.pi-minus:before {
  content: "";
}

.pi-minus-circle:before {
  content: "";
}

.pi-circle-on:before {
  content: "";
}

.pi-circle-off:before {
  content: "";
}

.pi-sort-down:before {
  content: "";
}

.pi-sort-up:before {
  content: "";
}

.pi-sort:before {
  content: "";
}

.pi-step-backward:before {
  content: "";
}

.pi-step-forward:before {
  content: "";
}

.pi-th-large:before {
  content: "";
}

.pi-arrow-down:before {
  content: "";
}

.pi-arrow-left:before {
  content: "";
}

.pi-arrow-right:before {
  content: "";
}

.pi-arrow-up:before {
  content: "";
}

.pi-bars:before {
  content: "";
}

.pi-arrow-circle-down:before {
  content: "";
}

.pi-arrow-circle-left:before {
  content: "";
}

.pi-arrow-circle-right:before {
  content: "";
}

.pi-arrow-circle-up:before {
  content: "";
}

.pi-info:before {
  content: "";
}

.pi-info-circle:before {
  content: "";
}

.pi-home:before {
  content: "";
}

.pi-spinner:before {
  content: "";
}

:root {
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  font-variation-settings: normal;
  --font-family: "Inter var", sans-serif;
  --font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  --surface-a: #fff;
  --surface-b: #f9fafb;
  --surface-c: #f3f4f6;
  --surface-d: #e5e7eb;
  --surface-e: #fff;
  --surface-f: #fff;
  --text-color: #4b5563;
  --text-color-secondary: #6b7280;
  --primary-color: #10b981;
  --primary-color-text: #fff;
  --surface-0: #fff;
  --surface-50: #f9fafb;
  --surface-100: #f3f4f6;
  --surface-200: #e5e7eb;
  --surface-300: #d1d5db;
  --surface-400: #9ca3af;
  --surface-500: #6b7280;
  --surface-600: #4b5563;
  --surface-700: #374151;
  --surface-800: #1f2937;
  --surface-900: #111827;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --content-padding: 1.25rem;
  --inline-spacing: .5rem;
  --border-radius: 6px;
  --surface-ground: #f9fafb;
  --surface-section: #fff;
  --surface-card: #fff;
  --surface-overlay: #fff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 .2rem #a7f3d0;
  --maskbg: #0006;
  --highlight-bg: #f0fdfa;
  --highlight-text-color: #047857;
  color-scheme: light;
  font-family: Inter var, sans-serif;
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Inter-roman.var.b695afbe.woff2") format("woff2");
}

@font-face {
  font-family: Inter var;
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("Inter-italic.var.69eb0fe1.woff2") format("woff2");
}

:root {
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6de95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #6366f1;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --red-50: #fff5f5;
  --red-100: #ffd0ce;
  --red-200: #ffaca7;
  --red-300: #ff8780;
  --red-400: #ff6259;
  --red-500: #ff3d32;
  --red-600: #d9342b;
  --red-700: #b32b23;
  --red-800: #8c221c;
  --red-900: #661814;
  --primary-50: #f3fcf9;
  --primary-100: #c6eee1;
  --primary-200: #98e1c9;
  --primary-300: #6bd4b1;
  --primary-400: #3dc699;
  --primary-500: #10b981;
  --primary-600: #0e9d6e;
  --primary-700: #0b825a;
  --primary-800: #096647;
  --primary-900: #064a34;
}

.p-editor-container .p-editor-toolbar {
  background: #f9fafb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6b7280;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6b7280;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  color: #6b7280;
  border: 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #fff;
  border: 0;
  border-radius: 6px;
  padding: .75rem 0;
  box-shadow: 0 2px 12px #0000001a;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #4b5563;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #4b5563;
  background: #f3f4f6;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: .75rem 1.25rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #e5e7eb;
}

.p-editor-container .p-editor-content .ql-editor {
  color: #4b5563;
  background: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover, .p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #4b5563;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #4b5563;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #4b5563;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #10b981;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #10b981;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #10b981;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #10b981;
}

@layer primevue {
  * {
    box-sizing: border-box;
  }

  .p-component {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
    font-weight: normal;
  }

  .p-component-overlay {
    background-color: #0006;
    transition-duration: .2s;
  }

  .p-disabled, .p-component:disabled {
    opacity: .6;
  }

  .p-error {
    color: #e24c4c;
  }

  .p-text-secondary {
    color: #6b7280;
  }

  .pi {
    font-size: 1rem;
  }

  .p-icon {
    width: 1rem;
    height: 1rem;
  }

  .p-link {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    border-radius: 6px;
    font-size: 1rem;
  }

  .p-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-component-overlay-enter {
    animation: .15s forwards p-component-overlay-enter-animation;
  }

  .p-component-overlay-leave {
    animation: .15s forwards p-component-overlay-leave-animation;
  }

  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: #0000;
    }

    to {
      background-color: var(--maskbg);
    }
  }

  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }

    to {
      background-color: #0000;
    }
  }

  .p-autocomplete .p-autocomplete-loader {
    right: .75rem;
  }

  .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 3.75rem;
  }

  .p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
    border-color: #10b981;
  }

  .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-autocomplete .p-autocomplete-multiple-container {
    gap: .5rem;
    padding: .375rem .75rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: .375rem 0;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #4b5563;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    color: #4b5563;
    background: #e5e7eb;
    border-radius: 16px;
    padding: .375rem .75rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: .5rem;
  }

  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
    color: #4b5563;
    background: #d1d5db;
  }

  .p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #e24c4c;
  }

  .p-autocomplete-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-autocomplete-panel .p-autocomplete-items {
    padding: .75rem 0;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    color: #4b5563;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: box-shadow .2s;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    color: #374151;
    background: #fff;
    margin: 0;
    padding: .75rem 1.25rem;
    font-weight: 700;
  }

  .p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #e24c4c;
  }

  .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datepicker {
    color: #4b5563;
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: .5rem;
  }

  .p-datepicker:not(.p-datepicker-inline) {
    background: #fff;
    border: 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #fff;
  }

  .p-datepicker .p-datepicker-header {
    color: #4b5563;
    background: #fff;
    border-bottom: 1px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
    padding: .5rem;
    font-weight: 600;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible, .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 2rem;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #4b5563;
    padding: .5rem;
    font-weight: 600;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #10b981;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: .5rem;
  }

  .p-datepicker table {
    margin: .5rem 0;
    font-size: 1rem;
  }

  .p-datepicker table th {
    padding: .5rem;
  }

  .p-datepicker table th > span {
    width: 2.5rem;
    height: 2.5rem;
  }

  .p-datepicker table td {
    padding: .5rem;
  }

  .p-datepicker table td > span {
    border: 1px solid #0000;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    transition: box-shadow .2s;
  }

  .p-datepicker table td > span.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datepicker table td > span:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datepicker table td.p-datepicker-today > span {
    color: #4b5563;
    background: #d1d5db;
    border-color: #0000;
  }

  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datepicker .p-datepicker-buttonbar {
    border-top: 1px solid #e5e7eb;
    padding: 1rem 0;
  }

  .p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
  }

  .p-datepicker .p-timepicker {
    border-top: 1px solid #e5e7eb;
    padding: .5rem;
  }

  .p-datepicker .p-timepicker button {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-datepicker .p-timepicker button:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-datepicker .p-timepicker button:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datepicker .p-timepicker button:last-child {
    margin-top: .2em;
  }

  .p-datepicker .p-timepicker span {
    font-size: 1.25rem;
  }

  .p-datepicker .p-timepicker > div {
    padding: 0 .5rem;
  }

  .p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0;
  }

  .p-datepicker .p-monthpicker {
    margin: .5rem 0;
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month {
    border-radius: 6px;
    padding: .5rem;
    transition: box-shadow .2s;
  }

  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datepicker .p-yearpicker {
    margin: .5rem 0;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year {
    border-radius: 6px;
    padding: .5rem;
    transition: box-shadow .2s;
  }

  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #e5e7eb;
    padding: 0 .5rem;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    border-left: 0;
    padding-left: 0;
  }

  .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
  }

  .p-datepicker.p-datepicker-mobile table th, .p-datepicker.p-datepicker-mobile table td {
    padding: 0;
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #f3f4f6;
  }

  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #f3f4f6;
  }

  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #f3f4f6;
  }

  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-cascadeselect {
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-cascadeselect:not(.p-disabled):hover {
    border-color: #10b981;
  }

  .p-cascadeselect:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-cascadeselect .p-cascadeselect-label {
    background: none;
    border: 0;
    padding: .75rem;
  }

  .p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #6b7280;
  }

  .p-cascadeselect .p-cascadeselect-label:enabled:focus {
    box-shadow: none;
    outline: 0;
  }

  .p-cascadeselect .p-cascadeselect-trigger {
    color: #6b7280;
    background: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 3rem;
  }

  .p-cascadeselect.p-invalid.p-component {
    border-color: #e24c4c;
  }

  .p-cascadeselect-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-cascadeselect-panel .p-cascadeselect-items {
    padding: .75rem 0;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    color: #4b5563;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    transition: box-shadow .2s;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: .75rem 1.25rem;
  }

  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: .875rem;
  }

  .p-input-filled .p-cascadeselect {
    background: #f3f4f6;
  }

  .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-checkbox {
    width: 22px;
    height: 22px;
  }

  .p-checkbox .p-checkbox-box {
    color: #4b5563;
    background: #fff;
    border: 2px solid #d1d5db;
    border-radius: 6px;
    width: 22px;
    height: 22px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #fff;
    font-size: 14px;
    transition-duration: .2s;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
    width: 14px;
    height: 14px;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background: #10b981;
    border-color: #10b981;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #10b981;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    color: #fff;
    background: #047857;
    border-color: #047857;
  }

  .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #e24c4c;
  }

  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #10b981;
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #047857;
  }

  .p-chips:not(.p-disabled):hover .p-chips-multiple-container {
    border-color: #10b981;
  }

  .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-chips .p-chips-multiple-container {
    padding: .375rem .75rem;
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    color: #4b5563;
    background: #e5e7eb;
    border-radius: 16px;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-chips .p-chips-multiple-container .p-chips-token.p-focus {
    color: #4b5563;
    background: #d1d5db;
  }

  .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: .5rem;
  }

  .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: .375rem 0;
  }

  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #4b5563;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  .p-chips.p-invalid.p-component > .p-inputtext {
    border-color: #e24c4c;
  }

  .p-colorpicker-preview {
    width: 2rem;
    height: 2rem;
  }

  .p-colorpicker-panel {
    background: #323232;
    border: 1px solid #191919;
  }

  .p-colorpicker-panel .p-colorpicker-color-handle, .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #fff;
  }

  .p-colorpicker-overlay-panel {
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-dropdown {
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: #10b981;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.75rem;
  }

  .p-dropdown .p-dropdown-label {
    background: none;
    border: 0;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #6b7280;
  }

  .p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
    box-shadow: none;
    outline: 0;
  }

  .p-dropdown .p-dropdown-trigger {
    color: #6b7280;
    background: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 3rem;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: #6b7280;
    right: 3rem;
  }

  .p-dropdown.p-invalid.p-component {
    border-color: #e24c4c;
  }

  .p-dropdown-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-dropdown-panel .p-dropdown-header {
    color: #374151;
    background: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
    padding: .75rem 1.25rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    margin-right: -1.75rem;
    padding-right: 1.75rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    color: #6b7280;
    right: .75rem;
  }

  .p-dropdown-panel .p-dropdown-items {
    padding: .75rem 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: #4b5563;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: box-shadow .2s;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    color: #374151;
    background: #fff;
    margin: 0;
    padding: .75rem 1.25rem;
    font-weight: 700;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    color: #4b5563;
    background: none;
    padding: .75rem 1.25rem;
  }

  .p-input-filled .p-dropdown {
    background: #f3f4f6;
  }

  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: #0000;
  }

  .p-inputgroup-addon {
    color: #6b7280;
    background: #f3f4f6;
    border-top: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
    border-left: 1px solid #d1d5db;
    min-width: 3rem;
    padding: .75rem;
  }

  .p-inputgroup-addon:last-child {
    border-right: 1px solid #d1d5db;
  }

  .p-inputgroup > .p-component, .p-inputgroup > .p-inputwrapper > .p-inputtext, .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0;
  }

  .p-inputgroup > .p-component + .p-inputgroup-addon, .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon, .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0;
  }

  .p-inputgroup > .p-component:focus, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus, .p-inputgroup > .p-float-label > .p-component:focus, .p-inputgroup > .p-component:focus ~ label, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label, .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1;
  }

  .p-inputgroup-addon:first-child, .p-inputgroup button:first-child, .p-inputgroup input:first-child, .p-inputgroup > .p-inputwrapper:first-child, .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext, .p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext, .p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .p-fluid .p-inputgroup .p-button {
    width: auto;
  }

  .p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 3rem;
  }

  .p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #e24c4c;
  }

  .p-inputswitch {
    width: 3rem;
    height: 1.75rem;
  }

  .p-inputswitch .p-inputswitch-slider {
    background: #d1d5db;
    border-radius: 30px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background: #fff;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -.625rem;
    transition-duration: .2s;
    left: .25rem;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #b7bcc5;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #10b981;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff;
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #059669;
  }

  .p-inputswitch.p-invalid .p-inputswitch-slider {
    border-color: #e24c4c;
  }

  .p-inputtext {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    color: #4b5563;
    appearance: none;
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: .75rem;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-inputtext:enabled:hover {
    border-color: #10b981;
  }

  .p-inputtext:enabled:focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-inputtext.p-invalid.p-component {
    border-color: #e24c4c;
  }

  .p-inputtext.p-inputtext-sm {
    padding: .65625rem;
    font-size: .875rem;
  }

  .p-inputtext.p-inputtext-lg {
    padding: .9375rem;
    font-size: 1.25rem;
  }

  .p-float-label > label {
    color: #6b7280;
    transition-duration: .2s;
    left: .75rem;
  }

  .p-float-label > .p-invalid + label {
    color: #e24c4c;
  }

  .p-input-icon-left > svg:first-of-type, .p-input-icon-left > i:first-of-type {
    color: #6b7280;
    left: .75rem;
  }

  .p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem;
  }

  .p-input-icon-left.p-float-label > label {
    left: 2.5rem;
  }

  .p-input-icon-right > svg:last-of-type, .p-input-icon-right > i:last-of-type {
    color: #6b7280;
    right: .75rem;
  }

  .p-input-icon-right > .p-inputtext {
    padding-right: 2.5rem;
  }

  ::-webkit-input-placeholder {
    color: #6b7280;
  }

  :-moz-placeholder {
    color: #6b7280;
  }

  ::-moz-placeholder {
    color: #6b7280;
  }

  :-ms-input-placeholder {
    color: #6b7280;
  }

  .p-input-filled .p-inputtext, .p-input-filled .p-inputtext:enabled:hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-inputtext:enabled:focus {
    background-color: #fff;
  }

  .p-inputtext-sm .p-inputtext {
    padding: .65625rem;
    font-size: .875rem;
  }

  .p-inputtext-lg .p-inputtext {
    padding: .9375rem;
    font-size: 1.25rem;
  }

  .p-listbox {
    color: #4b5563;
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-listbox .p-listbox-header {
    color: #374151;
    background: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
    padding: .75rem 1.25rem;
  }

  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.75rem;
  }

  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    color: #6b7280;
    right: .75rem;
  }

  .p-listbox .p-listbox-list {
    outline: 0;
    padding: .75rem 0;
  }

  .p-listbox .p-listbox-list .p-listbox-item {
    color: #4b5563;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: box-shadow .2s;
  }

  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-listbox .p-listbox-list .p-listbox-item-group {
    color: #374151;
    background: #fff;
    margin: 0;
    padding: .75rem 1.25rem;
    font-weight: 700;
  }

  .p-listbox .p-listbox-list .p-listbox-empty-message {
    color: #4b5563;
    background: none;
    padding: .75rem 1.25rem;
  }

  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-listbox.p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-listbox.p-invalid {
    border-color: #e24c4c;
  }

  .p-multiselect {
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-multiselect:not(.p-disabled):hover {
    border-color: #10b981;
  }

  .p-multiselect:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-multiselect .p-multiselect-label {
    padding: .75rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #6b7280;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    color: #4b5563;
    background: #e5e7eb;
    border-radius: 16px;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: .5rem;
  }

  .p-multiselect .p-multiselect-trigger {
    color: #6b7280;
    background: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 3rem;
  }

  .p-multiselect.p-invalid.p-component {
    border-color: #e24c4c;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: .375rem .75rem;
  }

  .p-multiselect-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-multiselect-panel .p-multiselect-header {
    color: #374151;
    background: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
    padding: .75rem 1.25rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    color: #6b7280;
    right: .75rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: .5rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-left: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-multiselect-panel .p-multiselect-items {
    padding: .75rem 0;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: #4b5563;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: box-shadow .2s;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: .5rem;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    color: #374151;
    background: #fff;
    margin: 0;
    padding: .75rem 1.25rem;
    font-weight: 700;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    color: #4b5563;
    background: none;
    padding: .75rem 1.25rem;
  }

  .p-input-filled .p-multiselect {
    background: #f3f4f6;
  }

  .p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-password.p-invalid.p-component > .p-inputtext {
    border-color: #e24c4c;
  }

  .p-password-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    padding: 1.25rem;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-password-panel .p-password-meter {
    background: #e5e7eb;
    margin-bottom: .5rem;
  }

  .p-password-panel .p-password-meter .p-password-strength.weak {
    background: #ea5455;
  }

  .p-password-panel .p-password-meter .p-password-strength.medium {
    background: #ff9f42;
  }

  .p-password-panel .p-password-meter .p-password-strength.strong {
    background: #29c76f;
  }

  .p-radiobutton {
    width: 22px;
    height: 22px;
  }

  .p-radiobutton .p-radiobutton-box {
    color: #4b5563;
    background: #fff;
    border: 2px solid #d1d5db;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #10b981;
  }

  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    background-color: #fff;
    width: 12px;
    height: 12px;
    transition-duration: .2s;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #10b981;
    border-color: #10b981;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    color: #fff;
    background: #047857;
    border-color: #047857;
  }

  .p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #e24c4c;
  }

  .p-radiobutton:focus {
    outline: 0;
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box, .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #10b981;
  }

  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #047857;
  }

  .p-rating {
    gap: .5rem;
  }

  .p-rating .p-rating-item .p-rating-icon {
    color: #4b5563;
    font-size: 1.143rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem;
  }

  .p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #ea5455;
  }

  .p-rating .p-rating-item.p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon, .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: #10b981;
  }

  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #e73d3e;
  }

  .p-selectbutton .p-button {
    color: #4b5563;
    background: #fff;
    border: 1px solid #d1d5db;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-selectbutton .p-button .p-button-icon-left, .p-selectbutton .p-button .p-button-icon-right {
    color: #6b7280;
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
    border-color: #d1d5db;
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #374151;
  }

  .p-selectbutton .p-button.p-highlight {
    color: #fff;
    background: #10b981;
    border-color: #10b981;
  }

  .p-selectbutton .p-button.p-highlight .p-button-icon-left, .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff;
  }

  .p-selectbutton .p-button.p-highlight:hover {
    color: #fff;
    background: #059669;
    border-color: #059669;
  }

  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left, .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
  }

  .p-selectbutton.p-invalid > .p-button {
    border-color: #e24c4c;
  }

  .p-slider {
    background: #e5e7eb;
    border: 0;
    border-radius: 6px;
  }

  .p-slider.p-slider-horizontal {
    height: .286rem;
  }

  .p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -.5715rem;
    margin-left: -.5715rem;
  }

  .p-slider.p-slider-vertical {
    width: .286rem;
  }

  .p-slider.p-slider-vertical .p-slider-handle {
    margin-bottom: -.5715rem;
    margin-left: -.5715rem;
  }

  .p-slider .p-slider-handle {
    background: #fff;
    border: 2px solid #10b981;
    border-radius: 50%;
    width: 1.143rem;
    height: 1.143rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-slider .p-slider-handle:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-slider .p-slider-range {
    background: #10b981;
  }

  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #10b981;
    border-color: #10b981;
  }

  .p-treeselect {
    background: #fff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-treeselect:not(.p-disabled):hover {
    border-color: #10b981;
  }

  .p-treeselect:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-treeselect .p-treeselect-label {
    padding: .75rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-treeselect .p-treeselect-label.p-placeholder {
    color: #6b7280;
  }

  .p-treeselect.p-treeselect-chip .p-treeselect-token {
    color: #4b5563;
    background: #e5e7eb;
    border-radius: 16px;
    margin-right: .5rem;
    padding: .375rem .75rem;
  }

  .p-treeselect .p-treeselect-trigger {
    color: #6b7280;
    background: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 3rem;
  }

  .p-treeselect.p-invalid.p-component {
    border-color: #e24c4c;
  }

  .p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: .375rem .75rem;
  }

  .p-treeselect-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0;
  }

  .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    color: #4b5563;
    background: none;
    padding: .75rem 1.25rem;
  }

  .p-input-filled .p-treeselect {
    background: #f3f4f6;
  }

  .p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #f3f4f6;
  }

  .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #fff;
  }

  .p-togglebutton.p-button {
    color: #4b5563;
    background: #fff;
    border: 1px solid #d1d5db;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right {
    color: #6b7280;
  }

  .p-togglebutton.p-button:not(.p-disabled).p-focus {
    outline-offset: 0;
    border-color: #10b981;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
    border-color: #d1d5db;
  }

  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #374151;
  }

  .p-togglebutton.p-button.p-highlight {
    color: #fff;
    background: #10b981;
    border-color: #10b981;
  }

  .p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #fff;
  }

  .p-togglebutton.p-button.p-highlight:hover {
    color: #fff;
    background: #059669;
    border-color: #059669;
  }

  .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left, .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #fff;
  }

  .p-togglebutton.p-button.p-invalid > .p-button {
    border-color: #e24c4c;
  }

  .p-button {
    color: #fff;
    background: #10b981;
    border: 1px solid #10b981;
    border-radius: 6px;
    padding: .75rem 1.25rem;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-button:not(:disabled):hover {
    color: #fff;
    background: #059669;
    border-color: #059669;
  }

  .p-button:not(:disabled):active {
    color: #fff;
    background: #047857;
    border-color: #047857;
  }

  .p-button.p-button-outlined {
    color: #10b981;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-outlined:not(:disabled):hover {
    color: #10b981;
    background: #10b9810a;
    border: 1px solid;
  }

  .p-button.p-button-outlined:not(:disabled):active {
    color: #10b981;
    background: #10b98129;
    border: 1px solid;
  }

  .p-button.p-button-outlined.p-button-plain {
    color: #6b7280;
    border-color: #6b7280;
  }

  .p-button.p-button-outlined.p-button-plain:not(:disabled):hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .p-button.p-button-outlined.p-button-plain:not(:disabled):active {
    color: #6b7280;
    background: #e5e7eb;
  }

  .p-button.p-button-text {
    color: #10b981;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-text:not(:disabled):hover {
    color: #10b981;
    background: #10b9810a;
    border-color: #0000;
  }

  .p-button.p-button-text:not(:disabled):active {
    color: #10b981;
    background: #10b98129;
    border-color: #0000;
  }

  .p-button.p-button-text.p-button-plain {
    color: #6b7280;
  }

  .p-button.p-button-text.p-button-plain:not(:disabled):hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .p-button.p-button-text.p-button-plain:not(:disabled):active {
    color: #6b7280;
    background: #e5e7eb;
  }

  .p-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-button .p-button-label {
    transition-duration: .2s;
  }

  .p-button .p-button-icon-left {
    margin-right: .5rem;
  }

  .p-button .p-button-icon-right {
    margin-left: .5rem;
  }

  .p-button .p-button-icon-bottom {
    margin-top: .5rem;
  }

  .p-button .p-button-icon-top {
    margin-bottom: .5rem;
  }

  .p-button .p-badge {
    color: #10b981;
    background-color: #fff;
    min-width: 1rem;
    height: 1rem;
    margin-left: .5rem;
    line-height: 1rem;
  }

  .p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  }

  .p-button.p-button-rounded {
    border-radius: 2rem;
  }

  .p-button.p-button-icon-only {
    width: 3rem;
    padding: .75rem 0;
  }

  .p-button.p-button-icon-only .p-button-icon-left, .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0;
  }

  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 3rem;
  }

  .p-button.p-button-sm {
    padding: .65625rem 1.09375rem;
    font-size: .875rem;
  }

  .p-button.p-button-sm .p-button-icon {
    font-size: .875rem;
  }

  .p-button.p-button-lg {
    padding: .9375rem 1.5625rem;
    font-size: 1.25rem;
  }

  .p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem;
  }

  .p-button.p-button-loading-label-only .p-button-label {
    margin-left: .5rem;
  }

  .p-button.p-button-loading-label-only .p-button-loading-icon {
    margin-right: 0;
  }

  .p-fluid .p-button {
    width: 100%;
  }

  .p-fluid .p-button-icon-only {
    width: 3rem;
  }

  .p-fluid .p-buttonset {
    display: flex;
  }

  .p-fluid .p-buttonset .p-button {
    flex: 1;
  }

  .p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
    color: #fff;
    background: #64748b;
    border: 1px solid #64748b;
  }

  .p-button.p-button-secondary:not(:disabled):hover, .p-buttonset.p-button-secondary > .p-button:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):hover {
    color: #fff;
    background: #475569;
    border-color: #475569;
  }

  .p-button.p-button-secondary:not(:disabled):focus, .p-buttonset.p-button-secondary > .p-button:not(:disabled):focus, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #e2e8f0;
  }

  .p-button.p-button-secondary:not(:disabled):active, .p-buttonset.p-button-secondary > .p-button:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button:not(:disabled):active {
    color: #fff;
    background: #334155;
    border-color: #334155;
  }

  .p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
    color: #64748b;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-secondary.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):hover {
    color: #64748b;
    background: #64748b0a;
    border: 1px solid;
  }

  .p-button.p-button-secondary.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(:disabled):active {
    color: #64748b;
    background: #64748b29;
    border: 1px solid;
  }

  .p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
    color: #64748b;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-secondary.p-button-text:not(:disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):hover {
    color: #64748b;
    background: #64748b0a;
    border-color: #0000;
  }

  .p-button.p-button-secondary.p-button-text:not(:disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(:disabled):active {
    color: #64748b;
    background: #64748b29;
    border-color: #0000;
  }

  .p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
    color: #fff;
    background: #0ea5e9;
    border: 1px solid #0ea5e9;
  }

  .p-button.p-button-info:not(:disabled):hover, .p-buttonset.p-button-info > .p-button:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button:not(:disabled):hover {
    color: #fff;
    background: #0284c7;
    border-color: #0284c7;
  }

  .p-button.p-button-info:not(:disabled):focus, .p-buttonset.p-button-info > .p-button:not(:disabled):focus, .p-splitbutton.p-button-info > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #bfdbfe;
  }

  .p-button.p-button-info:not(:disabled):active, .p-buttonset.p-button-info > .p-button:not(:disabled):active, .p-splitbutton.p-button-info > .p-button:not(:disabled):active {
    color: #fff;
    background: #0369a1;
    border-color: #0369a1;
  }

  .p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
    color: #0ea5e9;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-info.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):hover {
    color: #0ea5e9;
    background: #0ea5e90a;
    border: 1px solid;
  }

  .p-button.p-button-info.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(:disabled):active {
    color: #0ea5e9;
    background: #0ea5e929;
    border: 1px solid;
  }

  .p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
    color: #0ea5e9;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-info.p-button-text:not(:disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):hover {
    color: #0ea5e9;
    background: #0ea5e90a;
    border-color: #0000;
  }

  .p-button.p-button-info.p-button-text:not(:disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(:disabled):active {
    color: #0ea5e9;
    background: #0ea5e929;
    border-color: #0000;
  }

  .p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #fff;
    background: #22c55e;
    border: 1px solid #22c55e;
  }

  .p-button.p-button-success:not(:disabled):hover, .p-buttonset.p-button-success > .p-button:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button:not(:disabled):hover {
    color: #fff;
    background: #16a34a;
    border-color: #16a34a;
  }

  .p-button.p-button-success:not(:disabled):focus, .p-buttonset.p-button-success > .p-button:not(:disabled):focus, .p-splitbutton.p-button-success > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #bbf7d0;
  }

  .p-button.p-button-success:not(:disabled):active, .p-buttonset.p-button-success > .p-button:not(:disabled):active, .p-splitbutton.p-button-success > .p-button:not(:disabled):active {
    color: #fff;
    background: #15803d;
    border-color: #15803d;
  }

  .p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
    color: #22c55e;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-success.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):hover {
    color: #22c55e;
    background: #22c55e0a;
    border: 1px solid;
  }

  .p-button.p-button-success.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(:disabled):active {
    color: #22c55e;
    background: #22c55e29;
    border: 1px solid;
  }

  .p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
    color: #22c55e;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-success.p-button-text:not(:disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):hover {
    color: #22c55e;
    background: #22c55e0a;
    border-color: #0000;
  }

  .p-button.p-button-success.p-button-text:not(:disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(:disabled):active {
    color: #22c55e;
    background: #22c55e29;
    border-color: #0000;
  }

  .p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
    color: #fff;
    background: #f97316;
    border: 1px solid #f97316;
  }

  .p-button.p-button-warning:not(:disabled):hover, .p-buttonset.p-button-warning > .p-button:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button:not(:disabled):hover {
    color: #fff;
    background: #ea580c;
    border-color: #ea580c;
  }

  .p-button.p-button-warning:not(:disabled):focus, .p-buttonset.p-button-warning > .p-button:not(:disabled):focus, .p-splitbutton.p-button-warning > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #fde68a;
  }

  .p-button.p-button-warning:not(:disabled):active, .p-buttonset.p-button-warning > .p-button:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button:not(:disabled):active {
    color: #fff;
    background: #c2410c;
    border-color: #c2410c;
  }

  .p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
    color: #f97316;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-warning.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):hover {
    color: #f97316;
    background: #f973160a;
    border: 1px solid;
  }

  .p-button.p-button-warning.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(:disabled):active {
    color: #f97316;
    background: #f9731629;
    border: 1px solid;
  }

  .p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
    color: #f97316;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-warning.p-button-text:not(:disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):hover {
    color: #f97316;
    background: #f973160a;
    border-color: #0000;
  }

  .p-button.p-button-warning.p-button-text:not(:disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(:disabled):active {
    color: #f97316;
    background: #f9731629;
    border-color: #0000;
  }

  .p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
    color: #fff;
    background: #a855f7;
    border: 1px solid #a855f7;
  }

  .p-button.p-button-help:not(:disabled):hover, .p-buttonset.p-button-help > .p-button:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button:not(:disabled):hover {
    color: #fff;
    background: #9333ea;
    border-color: #9333ea;
  }

  .p-button.p-button-help:not(:disabled):focus, .p-buttonset.p-button-help > .p-button:not(:disabled):focus, .p-splitbutton.p-button-help > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #e9d5ff;
  }

  .p-button.p-button-help:not(:disabled):active, .p-buttonset.p-button-help > .p-button:not(:disabled):active, .p-splitbutton.p-button-help > .p-button:not(:disabled):active {
    color: #fff;
    background: #7e22ce;
    border-color: #7e22ce;
  }

  .p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
    color: #a855f7;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-help.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):hover {
    color: #a855f7;
    background: #a855f70a;
    border: 1px solid;
  }

  .p-button.p-button-help.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(:disabled):active {
    color: #a855f7;
    background: #a855f729;
    border: 1px solid;
  }

  .p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
    color: #a855f7;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-help.p-button-text:not(:disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):hover {
    color: #a855f7;
    background: #a855f70a;
    border-color: #0000;
  }

  .p-button.p-button-help.p-button-text:not(:disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(:disabled):active {
    color: #a855f7;
    background: #a855f729;
    border-color: #0000;
  }

  .p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
    color: #fff;
    background: #ef4444;
    border: 1px solid #ef4444;
  }

  .p-button.p-button-danger:not(:disabled):hover, .p-buttonset.p-button-danger > .p-button:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button:not(:disabled):hover {
    color: #fff;
    background: #dc2626;
    border-color: #dc2626;
  }

  .p-button.p-button-danger:not(:disabled):focus, .p-buttonset.p-button-danger > .p-button:not(:disabled):focus, .p-splitbutton.p-button-danger > .p-button:not(:disabled):focus {
    box-shadow: 0 0 0 .2rem #fecaca;
  }

  .p-button.p-button-danger:not(:disabled):active, .p-buttonset.p-button-danger > .p-button:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button:not(:disabled):active {
    color: #fff;
    background: #b91c1c;
    border-color: #b91c1c;
  }

  .p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
    color: #ef4444;
    background-color: #0000;
    border: 1px solid;
  }

  .p-button.p-button-danger.p-button-outlined:not(:disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):hover {
    color: #ef4444;
    background: #ef44440a;
    border: 1px solid;
  }

  .p-button.p-button-danger.p-button-outlined:not(:disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(:disabled):active {
    color: #ef4444;
    background: #ef444429;
    border: 1px solid;
  }

  .p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
    color: #ef4444;
    background-color: #0000;
    border-color: #0000;
  }

  .p-button.p-button-danger.p-button-text:not(:disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):hover {
    color: #ef4444;
    background: #ef44440a;
    border-color: #0000;
  }

  .p-button.p-button-danger.p-button-text:not(:disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(:disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(:disabled):active {
    color: #ef4444;
    background: #ef444429;
    border-color: #0000;
  }

  .p-button.p-button-link {
    color: #047857;
    background: none;
    border: #0000;
  }

  .p-button.p-button-link:not(:disabled):hover {
    color: #047857;
    background: none;
    border-color: #0000;
  }

  .p-button.p-button-link:not(:disabled):hover .p-button-label {
    text-decoration: underline;
  }

  .p-button.p-button-link:not(:disabled):focus {
    background: none;
    border-color: #0000;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-button.p-button-link:not(:disabled):active {
    color: #047857;
    background: none;
    border-color: #0000;
  }

  .p-speeddial-button.p-button.p-button-icon-only {
    width: 4rem;
    height: 4rem;
  }

  .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1.3rem;
  }

  .p-speeddial-button.p-button.p-button-icon-only .p-icon {
    width: 1.3rem;
    height: 1.3rem;
  }

  .p-speeddial-list {
    outline: 0;
  }

  .p-speeddial-item.p-focus > .p-speeddial-action {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-speeddial-action {
    color: #fff;
    background: #4b5563;
    width: 3rem;
    height: 3rem;
  }

  .p-speeddial-action:hover {
    color: #fff;
    background: #022354;
  }

  .p-speeddial-direction-up .p-speeddial-item {
    margin: .25rem 0;
  }

  .p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: .5rem;
  }

  .p-speeddial-direction-down .p-speeddial-item {
    margin: .25rem 0;
  }

  .p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: .5rem;
  }

  .p-speeddial-direction-left .p-speeddial-item {
    margin: 0 .25rem;
  }

  .p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: .5rem;
  }

  .p-speeddial-direction-right .p-speeddial-item {
    margin: 0 .25rem;
  }

  .p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: .5rem;
  }

  .p-speeddial-circle .p-speeddial-item, .p-speeddial-semi-circle .p-speeddial-item, .p-speeddial-quarter-circle .p-speeddial-item, .p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child, .p-speeddial-semi-circle .p-speeddial-item:first-child, .p-speeddial-semi-circle .p-speeddial-item:last-child, .p-speeddial-quarter-circle .p-speeddial-item:first-child, .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0;
  }

  .p-speeddial-mask {
    background-color: #0006;
    border-radius: 6px;
  }

  .p-splitbutton {
    border-radius: 6px;
  }

  .p-splitbutton.p-button-outlined > .p-button {
    color: #10b981;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #10b981;
    background: #10b9810a;
  }

  .p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #10b981;
    background: #10b98129;
  }

  .p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #6b7280;
    border-color: #6b7280;
  }

  .p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #6b7280;
    background: #e5e7eb;
  }

  .p-splitbutton.p-button-text > .p-button {
    color: #10b981;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #10b981;
    background: #10b9810a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #10b981;
    background: #10b98129;
    border-color: #0000;
  }

  .p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #6b7280;
  }

  .p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #6b7280;
    background: #e5e7eb;
  }

  .p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  }

  .p-splitbutton.p-button-rounded, .p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem;
  }

  .p-splitbutton.p-button-sm > .p-button {
    padding: .65625rem 1.09375rem;
    font-size: .875rem;
  }

  .p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: .875rem;
  }

  .p-splitbutton.p-button-lg > .p-button {
    padding: .9375rem 1.5625rem;
    font-size: 1.25rem;
  }

  .p-splitbutton.p-button-lg > .p-button.p-button-icon-only {
    width: auto;
  }

  .p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem;
  }

  .p-splitbutton.p-button-lg > .p-button .p-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    color: #64748b;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #64748b;
    background: #64748b0a;
  }

  .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #64748b;
    background: #64748b29;
  }

  .p-splitbutton.p-button-secondary.p-button-text > .p-button {
    color: #64748b;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #64748b;
    background: #64748b0a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #64748b;
    background: #64748b29;
    border-color: #0000;
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button {
    color: #0ea5e9;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #0ea5e9;
    background: #0ea5e90a;
  }

  .p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #0ea5e9;
    background: #0ea5e929;
  }

  .p-splitbutton.p-button-info.p-button-text > .p-button {
    color: #0ea5e9;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #0ea5e9;
    background: #0ea5e90a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #0ea5e9;
    background: #0ea5e929;
    border-color: #0000;
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button {
    color: #22c55e;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #22c55e;
    background: #22c55e0a;
  }

  .p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #22c55e;
    background: #22c55e29;
  }

  .p-splitbutton.p-button-success.p-button-text > .p-button {
    color: #22c55e;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #22c55e;
    background: #22c55e0a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #22c55e;
    background: #22c55e29;
    border-color: #0000;
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    color: #f97316;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #f97316;
    background: #f973160a;
  }

  .p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #f97316;
    background: #f9731629;
  }

  .p-splitbutton.p-button-warning.p-button-text > .p-button {
    color: #f97316;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #f97316;
    background: #f973160a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #f97316;
    background: #f9731629;
    border-color: #0000;
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button {
    color: #a855f7;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #a855f7;
    background: #a855f70a;
  }

  .p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #a855f7;
    background: #a855f729;
  }

  .p-splitbutton.p-button-help.p-button-text > .p-button {
    color: #a855f7;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #a855f7;
    background: #a855f70a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #a855f7;
    background: #a855f729;
    border-color: #0000;
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    color: #ef4444;
    background-color: #0000;
    border: 1px solid;
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #ef4444;
    background: #ef44440a;
  }

  .p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #ef4444;
    background: #ef444429;
  }

  .p-splitbutton.p-button-danger.p-button-text > .p-button {
    color: #ef4444;
    background-color: #0000;
    border-color: #0000;
  }

  .p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    color: #ef4444;
    background: #ef44440a;
    border-color: #0000;
  }

  .p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    color: #ef4444;
    background: #ef444429;
    border-color: #0000;
  }

  .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible, .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-carousel .p-carousel-indicators {
    padding: 1rem;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-bottom: .5rem;
    margin-right: .5rem;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #d1d5db;
    border-radius: 0;
    width: 2rem;
    height: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #9ca3af;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datatable .p-paginator-top, .p-datatable .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-datatable .p-datatable-header {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-width: 1px 0;
    padding: 1rem;
    font-weight: 700;
  }

  .p-datatable .p-datatable-footer {
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 700;
  }

  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 700;
    transition: box-shadow .2s;
  }

  .p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 700;
  }

  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #374151;
    margin-left: .5rem;
  }

  .p-datatable .p-sortable-column .p-sortable-column-badge {
    color: #047857;
    background: #f0fdfa;
    border-radius: 50%;
    min-width: 1.143rem;
    height: 1.143rem;
    margin-left: .5rem;
    line-height: 1.143rem;
  }

  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    color: #374151;
    background: #f3f4f6;
  }

  .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #374151;
  }

  .p-datatable .p-sortable-column.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #047857;
  }

  .p-datatable .p-sortable-column.p-highlight:hover {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: #047857;
  }

  .p-datatable .p-sortable-column:focus-visible {
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #a7f3d0;
  }

  .p-datatable .p-datatable-tbody > tr {
    color: #4b5563;
    background: #fff;
    transition: box-shadow .2s;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: .5rem;
  }

  .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    font-weight: 700;
  }

  .p-datatable .p-datatable-tbody > tr:focus-visible {
    outline-offset: -.15rem;
    outline: .15rem solid #a7f3d0;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
    outline-offset: -.15rem;
    outline: .15rem solid #a7f3d0;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-datatable .p-column-resizer-helper {
    background: #10b981;
  }

  .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: #f9fafb;
  }

  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
  }

  .p-datatable .p-datatable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px;
  }

  .p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px;
  }

  .p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #f8f8fa;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler, .p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #047857;
  }

  .p-datatable.p-datatable-sm .p-datatable-header, .p-datatable.p-datatable-sm .p-datatable-thead > tr > th, .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td, .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td, .p-datatable.p-datatable-sm .p-datatable-footer {
    padding: .5rem;
  }

  .p-datatable.p-datatable-lg .p-datatable-header, .p-datatable.p-datatable-lg .p-datatable-thead > tr > th, .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td, .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td, .p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem;
  }

  .p-dataview .p-paginator-top, .p-dataview .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-dataview .p-dataview-header {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-width: 1px 0;
    padding: 1rem;
    font-weight: 700;
  }

  .p-dataview .p-dataview-content {
    color: #4b5563;
    background: #fff;
    border: 0;
    padding: 0;
  }

  .p-dataview .p-dataview-footer {
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 1rem;
    font-weight: 700;
  }

  .p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
    margin-left: .5rem;
  }

  .p-column-filter-menu-button {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-column-filter-menu-button:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    color: #374151;
    background: #f3f4f6;
  }

  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    color: #047857;
    background: #f0fdfa;
  }

  .p-column-filter-menu-button:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-column-filter-clear-button {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-column-filter-clear-button:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-column-filter-clear-button:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-column-filter-overlay {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    min-width: 12.5rem;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-column-filter-overlay .p-column-filter-row-items {
    padding: .75rem 0;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    color: #4b5563;
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: box-shadow .2s;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #a7f3d0;
  }

  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-operator {
    color: #374151;
    background: #f9fafb;
    border-bottom: 1px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
    padding: .75rem 1.25rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint {
    border-bottom: 1px solid #e5e7eb;
    padding: 1.25rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: .5rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: .5rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: .75rem 1.25rem;
  }

  .p-column-filter-overlay-menu .p-column-filter-buttonbar, .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
  }

  .p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: .5rem;
  }

  .p-orderlist .p-orderlist-header {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-orderlist .p-orderlist-list {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    outline: 0;
    padding: .75rem 0;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    color: #4b5563;
    background: none;
    border: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: transform .2s, box-shadow .2s;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item.p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(2n) {
    background: #f8f8fa;
  }

  .p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(2n):hover {
    background: #f3f4f6;
  }

  .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #80eed5;
  }

  .p-organizationchart .p-organizationchart-line-down {
    background: #e5e7eb;
  }

  .p-organizationchart .p-organizationchart-line-left {
    border-color: #e5e7eb;
    border-right-style: solid;
    border-right-width: 1px;
  }

  .p-organizationchart .p-organizationchart-line-top {
    border-color: #e5e7eb;
    border-top-style: solid;
    border-top-width: 1px;
  }

  .p-organizationchart .p-organizationchart-node-content {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
  }

  .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;
  }

  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-paginator {
    color: #6b7280;
    background: #fff;
    border: 0 solid #f3f4f6;
    border-radius: 6px;
    padding: .5rem 1rem;
  }

  .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    color: #6b7280;
    background-color: #0000;
    border: 0;
    border-radius: 50%;
    min-width: 3rem;
    height: 3rem;
    margin: .143rem;
    transition: box-shadow .2s;
  }

  .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-paginator .p-paginator-first {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .p-paginator .p-paginator-last {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .p-paginator .p-dropdown {
    height: 3rem;
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0;
  }

  .p-paginator .p-paginator-page-input {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 3rem;
  }

  .p-paginator .p-paginator-current {
    color: #6b7280;
    background-color: #0000;
    border: 0;
    min-width: 3rem;
    height: 3rem;
    margin: .143rem;
    padding: 0 .5rem;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    color: #6b7280;
    background-color: #0000;
    border: 0;
    border-radius: 50%;
    min-width: 3rem;
    height: 3rem;
    margin: .143rem;
    transition: box-shadow .2s;
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    color: #047857;
    background: #f0fdfa;
    border-color: #f0fdfa;
  }

  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
  }

  .p-picklist .p-picklist-buttons .p-button {
    margin-bottom: .5rem;
  }

  .p-picklist .p-picklist-header {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-picklist .p-picklist-list {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    outline: 0;
    padding: .75rem 0;
  }

  .p-picklist .p-picklist-list .p-picklist-item {
    color: #4b5563;
    background: none;
    border: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    transition: transform .2s, box-shadow .2s;
  }

  .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-focus {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: #10b9813d;
  }

  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(2n) {
    background: #f8f8fa;
  }

  .p-picklist.p-picklist-striped .p-picklist-list .p-picklist-item:nth-child(2n):hover {
    background: #f3f4f6;
  }

  .p-timeline .p-timeline-event-marker {
    background-color: #fff;
    border: 2px solid #10b981;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }

  .p-timeline .p-timeline-event-connector {
    background-color: #e5e7eb;
  }

  .p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem;
  }

  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px;
  }

  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite, .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0;
  }

  .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px;
  }

  .p-tree {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 1.25rem;
  }

  .p-tree .p-tree-container .p-treenode {
    outline: 0;
    padding: .143rem;
  }

  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #a7f3d0;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 6px;
    padding: .5rem;
    transition: box-shadow .2s;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #4b5563;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #047857;
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-tree .p-tree-filter-container {
    margin-bottom: .5rem;
  }

  .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.75rem;
  }

  .p-tree .p-tree-filter-container .p-tree-filter-icon {
    color: #6b7280;
    right: .75rem;
  }

  .p-tree .p-treenode-children {
    padding: 0 0 0 1rem;
  }

  .p-tree .p-tree-loading-icon {
    font-size: 2rem;
  }

  .p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-treetable .p-paginator-top, .p-treetable .p-paginator-bottom {
    border-width: 0 0 1px;
    border-radius: 0;
  }

  .p-treetable .p-treetable-header {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-width: 1px 0;
    padding: 1rem;
    font-weight: 700;
  }

  .p-treetable .p-treetable-footer {
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 700;
  }

  .p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 700;
    transition: box-shadow .2s;
  }

  .p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    color: #374151;
    background: #f9fafb;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
    font-weight: 700;
  }

  .p-treetable .p-sortable-column {
    outline-color: #a7f3d0;
  }

  .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #374151;
    margin-left: .5rem;
  }

  .p-treetable .p-sortable-column .p-sortable-column-badge {
    color: #047857;
    background: #f0fdfa;
    border-radius: 50%;
    min-width: 1.143rem;
    height: 1.143rem;
    margin-left: .5rem;
    line-height: 1.143rem;
  }

  .p-treetable .p-sortable-column:not(.p-highlight):hover {
    color: #374151;
    background: #f3f4f6;
  }

  .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #374151;
  }

  .p-treetable .p-sortable-column.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #047857;
  }

  .p-treetable .p-treetable-tbody > tr {
    color: #4b5563;
    background: #fff;
    transition: box-shadow .2s;
  }

  .p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 0 solid #e5e7eb;
    border-bottom-width: 1px;
    padding: 1rem;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
    margin-right: .5rem;
  }

  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #4b5563;
  }

  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline-offset: -.15rem;
    outline: .15rem solid #a7f3d0;
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight {
    color: #047857;
    background: #f0fdfa;
  }

  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler, .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #047857;
  }

  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #4b5563;
  }

  .p-treetable .p-column-resizer-helper {
    background: #10b981;
  }

  .p-treetable .p-treetable-scrollable-header, .p-treetable .p-treetable-scrollable-footer {
    background: #f9fafb;
  }

  .p-treetable .p-treetable-loading-icon {
    font-size: 2rem;
  }

  .p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px;
  }

  .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th, .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td, .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px;
  }

  .p-treetable.p-treetable-sm .p-treetable-header {
    padding: .875rem;
  }

  .p-treetable.p-treetable-sm .p-treetable-thead > tr > th, .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td, .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td, .p-treetable.p-treetable-sm .p-treetable-footer {
    padding: .5rem;
  }

  .p-treetable.p-treetable-lg .p-treetable-header, .p-treetable.p-treetable-lg .p-treetable-thead > tr > th, .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td, .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td, .p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    color: #6b7280;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 1.25rem;
    font-weight: 700;
    transition: box-shadow .2s;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: .5rem;
  }

  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .2rem #a7f3d0;
  }

  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    color: #374151;
    background: #f3f4f6;
    border-color: #e5e7eb;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    color: #374151;
    background: #f9fafb;
    border-color: #e5e7eb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    color: #374151;
    background: #f3f4f6;
    border-color: #e5e7eb;
  }

  .p-accordion .p-accordion-content {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    padding: 1.25rem;
  }

  .p-accordion .p-accordion-tab {
    margin-bottom: 4px;
  }

  .p-card {
    color: #4b5563;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  }

  .p-card .p-card-body {
    padding: 1.25rem;
  }

  .p-card .p-card-title {
    margin-bottom: .5rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  .p-card .p-card-subtitle {
    color: #6b7280;
    margin-bottom: .5rem;
    font-weight: 400;
  }

  .p-card .p-card-content {
    padding: 1.25rem 0;
  }

  .p-card .p-card-footer {
    padding: 1.25rem 0 0;
  }

  .p-fieldset {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
  }

  .p-fieldset .p-fieldset-legend {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    color: #374151;
    border-radius: 6px;
    padding: 1.25rem;
    transition: box-shadow .2s;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: .5rem;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
    color: #374151;
  }

  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #e5e7eb;
  }

  .p-fieldset .p-fieldset-content {
    padding: 1.25rem;
  }

  .p-divider .p-divider-content {
    background-color: #fff;
  }

  .p-divider.p-divider-horizontal {
    margin: 1.25rem 0;
    padding: 0 1.25rem;
  }

  .p-divider.p-divider-horizontal:before {
    border-top: 1px #e5e7eb;
  }

  .p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 .5rem;
  }

  .p-divider.p-divider-vertical {
    margin: 0 1.25rem;
    padding: 1.25rem 0;
  }

  .p-divider.p-divider-vertical:before {
    border-left: 1px #e5e7eb;
  }

  .p-divider.p-divider-vertical .p-divider-content {
    padding: .5rem 0;
  }

  .p-panel .p-panel-header {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1.25rem;
  }

  .p-panel .p-panel-header .p-panel-title {
    font-weight: 700;
  }

  .p-panel .p-panel-header .p-panel-header-icon {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-panel.p-panel-toggleable .p-panel-header {
    padding: .75rem 1.25rem;
  }

  .p-panel .p-panel-content {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    padding: 1.25rem;
  }

  .p-panel .p-panel-content:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-panel .p-panel-footer {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: .75rem 1.25rem;
  }

  .p-scrollpanel .p-scrollpanel-bar {
    background: #f9fafb;
    border: 0;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-scrollpanel .p-scrollpanel-bar:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-splitter {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
  }

  .p-splitter .p-splitter-gutter {
    background: #f9fafb;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #e5e7eb;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-splitter .p-splitter-gutter-resizing {
    background: #e5e7eb;
  }

  .p-tabview .p-tabview-nav {
    background: #fff;
    border: 0 solid #e5e7eb;
    border-bottom-width: 2px;
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #6b7280;
    background: #fff;
    border: 0 solid #0000;
    border-bottom: 2px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0 0 -2px;
    padding: 1.25rem;
    font-weight: 700;
    transition: box-shadow .2s;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .2rem #a7f3d0;
  }

  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    color: #6b7280;
    background: #fff;
    border-color: #9ca3af;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #10b981;
    background: #fff;
    border-color: #10b981;
  }

  .p-tabview .p-tabview-nav-btn.p-link {
    color: #10b981;
    background: #fff;
    border-radius: 0;
    width: 3rem;
    box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  }

  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .2rem #a7f3d0;
  }

  .p-tabview .p-tabview-panels {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 1.25rem;
  }

  .p-toolbar {
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    gap: .5rem;
    padding: 1.25rem;
  }

  .p-toolbar .p-toolbar-separator {
    margin: 0 .5rem;
  }

  .p-confirm-popup {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 1px 3px #0000004d;
  }

  .p-confirm-popup .p-confirm-popup-content {
    padding: 1.25rem;
  }

  .p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 1.25rem 1.25rem;
  }

  .p-confirm-popup .p-confirm-popup-footer button {
    width: auto;
    margin: 0 .5rem 0 0;
  }

  .p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0;
  }

  .p-confirm-popup:after {
    border: solid #fff0;
    border-bottom-color: #fff;
  }

  .p-confirm-popup:before {
    border: solid #fff0;
    border-bottom-color: #f2f2f2;
  }

  .p-confirm-popup.p-confirm-popup-flipped:after, .p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #fff;
  }

  .p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem;
  }

  .p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem;
  }

  .p-dialog {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 1px 3px #0000004d;
  }

  .p-dialog .p-dialog-header {
    color: #374151;
    background: #fff;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 1.5rem;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }

  .p-dialog .p-dialog-content {
    color: #4b5563;
    background: #fff;
    padding: 0 1.5rem 2rem;
  }

  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .p-dialog .p-dialog-footer {
    color: #4b5563;
    text-align: right;
    background: #fff;
    border-top: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 0 1.5rem 1.5rem;
  }

  .p-dialog .p-dialog-footer button {
    width: auto;
    margin: 0 .5rem 0 0;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message:not(:first-child) {
    margin-left: 1rem;
  }

  .p-overlaypanel {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 1px 3px #0000004d;
  }

  .p-overlaypanel .p-overlaypanel-content {
    padding: 1.25rem;
  }

  .p-overlaypanel .p-overlaypanel-close {
    color: #fff;
    background: #10b981;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    position: absolute;
    top: -1rem;
    right: -1rem;
  }

  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    color: #fff;
    background: #059669;
  }

  .p-overlaypanel:after {
    border: solid #fff0;
    border-bottom-color: #fff;
  }

  .p-overlaypanel:before {
    border: solid #fff0;
    border-bottom-color: #f2f2f2;
  }

  .p-overlaypanel.p-overlaypanel-flipped:after, .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #fff;
  }

  .p-sidebar {
    color: #4b5563;
    background: #fff;
    border: 0;
    box-shadow: 0 1px 3px #0000004d;
  }

  .p-sidebar .p-sidebar-header {
    padding: 1.25rem;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-header-content {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
    color: #6b7280;
    background: none;
    border: 0;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #374151;
    background: #f3f4f6;
    border-color: #0000;
  }

  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }

  .p-sidebar .p-sidebar-content {
    padding: 1.25rem;
  }

  .p-tooltip .p-tooltip-text {
    color: #fff;
    background: #4b5563;
    border-radius: 6px;
    padding: .75rem;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #4b5563;
  }

  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #4b5563;
  }

  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #4b5563;
  }

  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #4b5563;
  }

  .p-fileupload .p-fileupload-buttonbar {
    color: #374151;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    gap: .5rem;
    padding: 1.25rem;
  }

  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-fileupload .p-fileupload-content {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 2rem 1rem;
  }

  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    background-color: #f0fdfa;
    border: 1px dashed #10b981;
  }

  .p-fileupload .p-fileupload-file {
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    gap: .5rem;
    margin-bottom: .5rem;
    padding: 1rem;
  }

  .p-fileupload .p-fileupload-file:last-child {
    margin-bottom: 0;
  }

  .p-fileupload .p-fileupload-file-name {
    margin-bottom: .5rem;
  }

  .p-fileupload .p-fileupload-file-size {
    margin-right: .5rem;
  }

  .p-fileupload .p-progressbar {
    height: .25rem;
  }

  .p-fileupload .p-fileupload-row > div {
    padding: 1rem;
  }

  .p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0;
  }

  .p-fileupload-choose:not(.p-disabled):hover {
    color: #fff;
    background: #059669;
    border-color: #059669;
  }

  .p-fileupload-choose:not(.p-disabled):active {
    color: #fff;
    background: #047857;
    border-color: #047857;
  }

  .p-breadcrumb {
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 1rem;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
    border-radius: 6px;
    transition: box-shadow .2s;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
  }

  .p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
    color: #4b5563;
    margin: 0 .5rem;
  }

  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
    color: #4b5563;
  }

  .p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
    color: #6b7280;
  }

  .p-contextmenu {
    color: #4b5563;
    background: #fff;
    border: 0;
    border-radius: 6px;
    min-width: 12.5rem;
    padding: .5rem 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-contextmenu .p-contextmenu-root-list {
    outline: 0;
  }

  .p-contextmenu .p-submenu-list {
    background: #fff;
    border: 0;
    border-radius: 6px;
    padding: .5rem 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 0;
    transition: box-shadow .2s;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #4b5563;
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-contextmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #047857;
    background: #f0fdfa;
  }

  .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #047857;
  }

  .p-contextmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #10b9813d;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #4b5563;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-contextmenu .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-contextmenu .p-submenu-icon {
    font-size: .875rem;
  }

  .p-contextmenu .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-dock .p-dock-list-container {
    background: #ffffff1a;
    border: 1px solid #fff3;
    border-radius: .5rem;
    padding: .5rem;
  }

  .p-dock .p-dock-list-container .p-dock-list {
    outline: 0;
  }

  .p-dock .p-dock-item {
    border-radius: 6px;
    padding: .5rem;
  }

  .p-dock .p-dock-item.p-focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .15rem #a7f3d0;
  }

  .p-dock .p-dock-link {
    width: 4rem;
    height: 4rem;
  }

  .p-dock.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-bottom .p-dock-item-second-next {
    margin: 0 .9rem;
  }

  .p-dock.p-dock-top .p-dock-item-prev, .p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem;
  }

  .p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem;
  }

  .p-dock.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-right .p-dock-item-second-next {
    margin: .9rem 0;
  }

  .p-dock.p-dock-left .p-dock-item-prev, .p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev, .p-dock.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0;
  }

  .p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0;
  }

  .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container {
    width: 100%;
    overflow-x: auto;
  }

  .p-dock.p-dock-mobile.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }

  .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container {
    height: 100%;
    overflow-y: auto;
  }

  .p-dock.p-dock-mobile.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-mobile.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }

  .p-dock.p-dock-mobile .p-dock-list .p-dock-item {
    margin: 0;
    transform: none;
  }

  .p-megamenu {
    color: #4b5563;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: .5rem;
  }

  .p-megamenu .p-megamenu-root-list {
    outline: 0;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 0;
    transition: box-shadow .2s;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #4b5563;
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-megamenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #047857;
    background: #f0fdfa;
  }

  .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #047857;
  }

  .p-megamenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #10b9813d;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #4b5563;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-megamenu .p-megamenu-panel {
    color: #4b5563;
    background: #fff;
    border: 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-megamenu .p-submenu-header {
    color: #374151;
    background: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0;
    padding: .75rem 1.25rem;
    font-weight: 700;
  }

  .p-megamenu .p-submenu-list {
    min-width: 12.5rem;
    padding: .5rem 0;
  }

  .p-megamenu .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-megamenu.p-megamenu-vertical {
    min-width: 12.5rem;
    padding: .5rem 0;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 6px;
    transition: box-shadow .2s;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
    margin-left: .5rem;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-megamenu.p-megamenu-horizontal .p-megamenu-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-megamenu.p-megamenu-mobile.p-megamenu-vertical {
    width: 100%;
    padding: .5rem;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-button {
    color: #6b7280;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-button:hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list {
    background: #fff;
    border: 0;
    padding: .5rem 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
    transition: transform .2s;
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
    transition: transform .2s;
    transform: rotate(90deg);
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-megamenu.p-megamenu-mobile .p-megamenu-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 2.25rem;
  }

  .p-menu {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-width: 12.5rem;
    padding: .5rem 0;
  }

  .p-menu .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 0;
    transition: box-shadow .2s;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #4b5563;
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-menu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #047857;
    background: #f0fdfa;
  }

  .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #047857;
  }

  .p-menu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #10b9813d;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #4b5563;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-menu.p-menu-overlay {
    background: #fff;
    border: 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-menu .p-submenu-header {
    color: #374151;
    background: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    padding: .75rem 1.25rem;
    font-weight: 700;
  }

  .p-menu .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-menubar {
    color: #4b5563;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: .5rem;
  }

  .p-menubar .p-menubar-root-list {
    outline: 0;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 6px;
    transition: box-shadow .2s;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
    margin-left: .5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-menubar .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 0;
    transition: box-shadow .2s;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #4b5563;
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content {
    color: #047857;
    background: #f0fdfa;
  }

  .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #047857;
  }

  .p-menubar .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #10b9813d;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #4b5563;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-menubar .p-submenu-list {
    background: #fff;
    border: 0;
    min-width: 12.5rem;
    padding: .5rem 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-menubar .p-submenu-list .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-button {
    color: #6b7280;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-menubar.p-menubar-mobile .p-menubar-button:hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .p-menubar.p-menubar-mobile .p-menubar-button:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list {
    background: #fff;
    border: 0;
    padding: .5rem 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-icon {
    font-size: .875rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-left: auto;
    transition: transform .2s;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform .2s;
    transform: rotate(90deg);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-content > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 2.25rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 3.75rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 5.25rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 6.75rem;
  }

  .p-menubar.p-menubar-mobile .p-menubar-root-list .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-submenu-list .p-menuitem .p-menuitem-content .p-menuitem-link {
    padding-left: 8.25rem;
  }

  .p-panelmenu .p-panelmenu-header {
    outline: 0;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content {
    color: #6b7280;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    transition: box-shadow .2s;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action {
    color: #6b7280;
    padding: 1.25rem;
    font-weight: 700;
  }

  .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-submenu-icon, .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content .p-panelmenu-header-action .p-menuitem-icon {
    margin-right: .5rem;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .2rem #a7f3d0;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover .p-panelmenu-header-content {
    color: #374151;
    background: #f3f4f6;
    border-color: #e5e7eb;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
    color: #374151;
    background: #f9fafb;
    border-color: #e5e7eb;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
  }

  .p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight:hover .p-panelmenu-header-content {
    color: #374151;
    background: #f3f4f6;
    border-color: #e5e7eb;
  }

  .p-panelmenu .p-panelmenu-content {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-top: 0;
    border-radius: 0 0 6px 6px;
    padding: .5rem 0;
  }

  .p-panelmenu .p-panelmenu-content .p-panelmenu-root-list {
    outline: 0;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 0;
    transition: box-shadow .2s;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #4b5563;
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content {
    color: #047857;
    background: #f0fdfa;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #047857;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #10b9813d;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #4b5563;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-panelmenu .p-panelmenu-content .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    margin-right: .5rem;
  }

  .p-panelmenu .p-panelmenu-content .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-list) {
    padding: 0 0 0 1rem;
  }

  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 4px;
  }

  .p-steps .p-steps-item .p-menuitem-link {
    background: #fff;
    border-radius: 6px;
    transition: box-shadow .2s;
  }

  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #4b5563;
    z-index: 1;
    background: #fff;
    border: 1px solid #f3f4f6;
    border-radius: 50%;
    min-width: 2rem;
    height: 2rem;
    font-size: 1.143rem;
    line-height: 2rem;
  }

  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    color: #6b7280;
    margin-top: .5rem;
  }

  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-steps .p-steps-item.p-highlight .p-steps-title {
    color: #4b5563;
    font-weight: 700;
  }

  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #e5e7eb;
    width: 100%;
    margin-top: -1rem;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
  }

  .p-tabmenu .p-tabmenu-nav {
    background: #fff;
    border: 0 solid #e5e7eb;
    border-bottom-width: 2px;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    color: #6b7280;
    background: #fff;
    border: 0 solid #0000;
    border-bottom: 2px solid #e5e7eb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin: 0 0 -2px;
    padding: 1.25rem;
    font-weight: 700;
    transition: box-shadow .2s;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: .5rem;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: inset 0 0 0 .2rem #a7f3d0;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    color: #6b7280;
    background: #fff;
    border-color: #9ca3af;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: #10b981;
    background: #fff;
    border-color: #10b981;
  }

  .p-tieredmenu {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    min-width: 12.5rem;
    padding: .5rem 0;
  }

  .p-tieredmenu.p-tieredmenu-overlay {
    background: #fff;
    border: 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-tieredmenu .p-tieredmenu-root-list {
    outline: 0;
  }

  .p-tieredmenu .p-submenu-list {
    background: #fff;
    border: 0;
    padding: .5rem 0;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content {
    color: #4b5563;
    border-radius: 0;
    transition: box-shadow .2s;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link {
    color: #4b5563;
    -webkit-user-select: none;
    user-select: none;
    padding: .75rem 1.25rem;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon {
    color: #6b7280;
    margin-right: .5rem;
  }

  .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content {
    color: #047857;
    background: #f0fdfa;
  }

  .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-highlight > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #047857;
  }

  .p-tieredmenu .p-menuitem.p-highlight.p-focus > .p-menuitem-content {
    background: #10b9813d;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
    color: #4b5563;
    background: #e5e7eb;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-text, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content .p-menuitem-link .p-submenu-icon {
    color: #4b5563;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-text {
    color: #4b5563;
  }

  .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem:not(.p-highlight):not(.p-disabled) > .p-menuitem-content:hover .p-menuitem-link .p-submenu-icon {
    color: #6b7280;
  }

  .p-tieredmenu .p-menuitem-separator {
    border-top: 1px solid #e5e7eb;
    margin: .25rem 0;
  }

  .p-tieredmenu .p-submenu-icon {
    font-size: .875rem;
  }

  .p-tieredmenu .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem;
  }

  .p-inline-message {
    border-radius: 6px;
    margin: 0;
    padding: .75rem;
  }

  .p-inline-message.p-inline-message-info {
    color: #3b82f6;
    background: #dbeafeb3;
    border: 0 solid #3b82f6;
  }

  .p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #3b82f6;
  }

  .p-inline-message.p-inline-message-success {
    color: #1ea97c;
    background: #e4f8f0b3;
    border: 0 solid #1ea97c;
  }

  .p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #1ea97c;
  }

  .p-inline-message.p-inline-message-warn {
    color: #cc8925;
    background: #fff2e2b3;
    border: 0 solid #cc8925;
  }

  .p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #cc8925;
  }

  .p-inline-message.p-inline-message-error {
    color: #ff5757;
    background: #ffe7e6b3;
    border: 0 solid #ff5757;
  }

  .p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #ff5757;
  }

  .p-inline-message .p-inline-message-icon {
    margin-right: .5rem;
    font-size: 1rem;
  }

  .p-inline-message .p-inline-message-text {
    font-size: 1rem;
  }

  .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0;
  }

  .p-message {
    border-radius: 6px;
    margin: 1rem 0;
  }

  .p-message .p-message-wrapper {
    padding: 1.25rem 1.75rem;
  }

  .p-message .p-message-close {
    background: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-message .p-message-close:hover {
    background: #ffffff80;
  }

  .p-message .p-message-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-message.p-message-info {
    color: #3b82f6;
    background: #dbeafeb3;
    border: 0 solid #3b82f6;
    border-left-width: 6px;
  }

  .p-message.p-message-info .p-message-icon, .p-message.p-message-info .p-message-close {
    color: #3b82f6;
  }

  .p-message.p-message-success {
    color: #1ea97c;
    background: #e4f8f0b3;
    border: 0 solid #1ea97c;
    border-left-width: 6px;
  }

  .p-message.p-message-success .p-message-icon, .p-message.p-message-success .p-message-close {
    color: #1ea97c;
  }

  .p-message.p-message-warn {
    color: #cc8925;
    background: #fff2e2b3;
    border: 0 solid #cc8925;
    border-left-width: 6px;
  }

  .p-message.p-message-warn .p-message-icon, .p-message.p-message-warn .p-message-close {
    color: #cc8925;
  }

  .p-message.p-message-error {
    color: #ff5757;
    background: #ffe7e6b3;
    border: 0 solid #ff5757;
    border-left-width: 6px;
  }

  .p-message.p-message-error .p-message-icon, .p-message.p-message-error .p-message-close {
    color: #ff5757;
  }

  .p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500;
  }

  .p-message .p-message-icon {
    margin-right: .5rem;
    font-size: 1.5rem;
  }

  .p-message .p-icon:not(.p-message-close-icon) {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-toast {
    opacity: 1;
  }

  .p-toast .p-toast-message {
    border-radius: 6px;
    margin: 0 0 1rem;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-toast .p-toast-message .p-toast-message-content {
    border-width: 0 0 0 6px;
    padding: 1rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700;
  }

  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: .5rem 0 0;
  }

  .p-toast .p-toast-message .p-toast-icon-close {
    background: none;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-toast .p-toast-message .p-toast-icon-close:hover {
    background: #ffffff80;
  }

  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-toast .p-toast-message.p-toast-message-info {
    color: #3b82f6;
    background: #dbeafeb3;
    border: 0 solid #3b82f6;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #3b82f6;
  }

  .p-toast .p-toast-message.p-toast-message-success {
    color: #1ea97c;
    background: #e4f8f0b3;
    border: 0 solid #1ea97c;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #1ea97c;
  }

  .p-toast .p-toast-message.p-toast-message-warn {
    color: #cc8925;
    background: #fff2e2b3;
    border: 0 solid #cc8925;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #cc8925;
  }

  .p-toast .p-toast-message.p-toast-message-error {
    color: #ff5757;
    background: #ffe7e6b3;
    border: 0 solid #ff5757;
    border-left-width: 6px;
  }

  .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #ff5757;
  }

  .p-galleria .p-galleria-close {
    color: #f9fafb;
    background: none;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem;
  }

  .p-galleria .p-galleria-close .p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-galleria .p-galleria-close:hover {
    color: #f9fafb;
    background: #ffffff1a;
  }

  .p-galleria .p-galleria-item-nav {
    color: #f9fafb;
    background: none;
    border-radius: 6px;
    width: 4rem;
    height: 4rem;
    margin: 0 .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem;
  }

  .p-galleria .p-galleria-item-nav .p-icon {
    width: 2rem;
    height: 2rem;
  }

  .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    color: #f9fafb;
    background: #ffffff1a;
  }

  .p-galleria .p-galleria-caption {
    color: #f9fafb;
    background: #00000080;
    padding: 1rem;
  }

  .p-galleria .p-galleria-indicators {
    padding: 1rem;
  }

  .p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #d1d5db;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #9ca3af;
  }

  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    color: #047857;
    background: #f0fdfa;
  }

  .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: .5rem;
  }

  .p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: .5rem;
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: #00000080;
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: #fff6;
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #fff9;
  }

  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    color: #047857;
    background: #f0fdfa;
  }

  .p-galleria .p-galleria-thumbnail-container {
    background: #000000e6;
    padding: 1rem .25rem;
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    color: #f9fafb;
    background-color: #0000;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    color: #f9fafb;
    background: #ffffff1a;
  }

  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-galleria-mask, .p-image-mask {
    --maskbg: #000000e6;
  }

  .p-image-preview-indicator {
    color: #f8f9fa;
    background-color: #0000;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-image-preview-indicator .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: #00000080;
  }

  .p-image-toolbar {
    padding: 1rem;
  }

  .p-image-action.p-link {
    color: #f8f9fa;
    background-color: #0000;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-right: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-image-action.p-link:last-child {
    margin-right: 0;
  }

  .p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: #ffffff1a;
  }

  .p-image-action.p-link i {
    font-size: 1.5rem;
  }

  .p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-avatar {
    background-color: #e5e7eb;
    border-radius: 6px;
  }

  .p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }

  .p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem;
  }

  .p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
  }

  .p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem;
  }

  .p-avatar-group .p-avatar {
    border: 2px solid #fff;
  }

  .p-badge {
    color: #fff;
    background: #10b981;
    min-width: 1.5rem;
    height: 1.5rem;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  .p-badge.p-badge-secondary {
    color: #fff;
    background-color: #64748b;
  }

  .p-badge.p-badge-success {
    color: #fff;
    background-color: #22c55e;
  }

  .p-badge.p-badge-info {
    color: #fff;
    background-color: #0ea5e9;
  }

  .p-badge.p-badge-warning {
    color: #fff;
    background-color: #f97316;
  }

  .p-badge.p-badge-danger {
    color: #fff;
    background-color: #ef4444;
  }

  .p-badge.p-badge-lg {
    min-width: 2.25rem;
    height: 2.25rem;
    font-size: 1.125rem;
    line-height: 2.25rem;
  }

  .p-badge.p-badge-xl {
    min-width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    line-height: 3rem;
  }

  .p-chip {
    color: #4b5563;
    background-color: #e5e7eb;
    border-radius: 16px;
    padding: 0 .75rem;
  }

  .p-chip .p-chip-text {
    margin-top: .375rem;
    margin-bottom: .375rem;
    line-height: 1.5;
  }

  .p-chip .p-chip-icon {
    margin-right: .5rem;
  }

  .p-chip img {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: -.75rem;
    margin-right: .5rem;
  }

  .p-chip .p-chip-remove-icon {
    border-radius: 6px;
    margin-left: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
  }

  .p-chip .p-chip-remove-icon:focus-visible {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-chip .p-chip-remove-icon:focus {
    outline: 0;
  }

  .p-inplace .p-inplace-display {
    border-radius: 6px;
    padding: .75rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-inplace .p-inplace-display:not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
  }

  .p-inplace .p-inplace-display:focus {
    outline-offset: 0;
    outline: 0;
    box-shadow: 0 0 0 .2rem #a7f3d0;
  }

  .p-progressbar {
    background: #e5e7eb;
    border: 0;
    border-radius: 6px;
    height: 1.5rem;
  }

  .p-progressbar .p-progressbar-value {
    background: #10b981;
    border: 0;
    margin: 0;
  }

  .p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 1.5rem;
  }

  .p-progress-spinner-svg {
    animation: 2s linear infinite p-progress-spinner-rotate;
  }

  .p-progress-spinner-circle {
    stroke-dasharray: 89 200;
    stroke-dashoffset: 0;
    stroke: #ff5757;
    stroke-linecap: round;
    animation: 1.5s ease-in-out infinite p-progress-spinner-dash, 6s ease-in-out infinite p-progress-spinner-color;
  }

  @keyframes p-progress-spinner-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes p-progress-spinner-dash {
    0% {
      stroke-dasharray: 1 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes p-progress-spinner-color {
    100%, 0% {
      stroke: #ff5757;
    }

    40% {
      stroke: #3b82f6;
    }

    66% {
      stroke: #1ea97c;
    }

    80%, 90% {
      stroke: #cc8925;
    }
  }

  .p-scrolltop {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    box-shadow: 0 2px 12px #0000001a;
  }

  .p-scrolltop.p-link {
    background: #000000b3;
  }

  .p-scrolltop.p-link:hover {
    background: #000c;
  }

  .p-scrolltop .p-scrolltop-icon {
    color: #f9fafb;
    font-size: 1.5rem;
  }

  .p-scrolltop .p-scrolltop-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .p-skeleton {
    background-color: #e5e7eb;
    border-radius: 6px;
  }

  .p-skeleton:after {
    background: linear-gradient(90deg, #fff0, #fff6, #fff0);
  }

  .p-tag {
    color: #fff;
    background: #10b981;
    border-radius: 6px;
    padding: .25rem .4rem;
    font-size: .75rem;
    font-weight: 700;
  }

  .p-tag.p-tag-success {
    color: #fff;
    background-color: #22c55e;
  }

  .p-tag.p-tag-info {
    color: #fff;
    background-color: #0ea5e9;
  }

  .p-tag.p-tag-warning {
    color: #fff;
    background-color: #f97316;
  }

  .p-tag.p-tag-danger {
    color: #fff;
    background-color: #ef4444;
  }

  .p-tag .p-tag-icon {
    margin-right: .25rem;
    font-size: .75rem;
  }

  .p-tag .p-tag-icon.p-icon {
    width: .75rem;
    height: .75rem;
  }

  .p-terminal {
    color: #4b5563;
    background: #fff;
    border: 1px solid #e5e7eb;
    padding: 1.25rem;
  }

  .p-terminal .p-terminal-input {
    font-family: var(--font-family);
    font-feature-settings: var(--font-feature-settings, normal);
    font-size: 1rem;
  }

  .p-button-label {
    font-weight: 700;
  }

  .p-selectbutton > .p-button, .p-togglebutton.p-button, .p-accordion .p-accordion-header .p-accordion-header-link, .p-tabview .p-tabview-nav li .p-tabview-nav-link, .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    transition: background-color .2s, border-color .2s, box-shadow .2s;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button, .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: #10b981;
  }

  .p-button:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #71f3c8, 0 1px 2px #000;
  }

  .p-button.p-button-secondary:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #b0b9c6, 0 1px 2px #000;
  }

  .p-button.p-button-success:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #88eaac, 0 1px 2px #000;
  }

  .p-button.p-button-info:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #83d3f8, 0 1px 2px #000;
  }

  .p-button.p-button-warning:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #fcb98b, 0 1px 2px #000;
  }

  .p-button.p-button-help:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #d4aafb, 0 1px 2px #000;
  }

  .p-button.p-button-danger:enabled:focus {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #f7a2a2, 0 1px 2px #000;
  }

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px #10b981;
  }

  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px #10b981;
  }

  .p-speeddial-item.p-focus > .p-speeddial-action {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #71f3c8, 0 1px 2px #000;
  }

  .p-toast-message {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .p-inline-message-text {
    font-weight: 500;
  }

  .p-picklist-buttons .p-button, .p-orderlist-controls .p-button {
    transition: opacity .2s, background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  }

  .p-steps .p-steps-item.p-highlight .p-steps-number {
    color: #fff;
    background: #10b981;
  }
}

.grid {
  flex-wrap: wrap;
  margin-top: -.5rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
  display: flex;
}

.grid > .col, .grid > [class*="col"] {
  box-sizing: border-box;
}

.grid-nogutter {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.grid-nogutter > .col, .grid-nogutter > [class*="col-"] {
  padding: 0;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: .5rem;
}

.col-fixed {
  flex: none;
  padding: .5rem;
}

.col-1 {
  flex: none;
  width: 8.3333%;
  padding: .5rem;
}

.col-2 {
  flex: none;
  width: 16.6667%;
  padding: .5rem;
}

.col-3 {
  flex: none;
  width: 25%;
  padding: .5rem;
}

.col-4 {
  flex: none;
  width: 33.3333%;
  padding: .5rem;
}

.col-5 {
  flex: none;
  width: 41.6667%;
  padding: .5rem;
}

.col-6 {
  flex: none;
  width: 50%;
  padding: .5rem;
}

.col-7 {
  flex: none;
  width: 58.3333%;
  padding: .5rem;
}

.col-8 {
  flex: none;
  width: 66.6667%;
  padding: .5rem;
}

.col-9 {
  flex: none;
  width: 75%;
  padding: .5rem;
}

.col-10 {
  flex: none;
  width: 83.3333%;
  padding: .5rem;
}

.col-11 {
  flex: none;
  width: 91.6667%;
  padding: .5rem;
}

.col-12 {
  flex: none;
  width: 100%;
  padding: .5rem;
}

@media screen and (width >= 576px) {
  .sm\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .sm\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .sm\:col-1 {
    flex: none;
    width: 8.3333%;
    padding: .5rem;
  }

  .sm\:col-2 {
    flex: none;
    width: 16.6667%;
    padding: .5rem;
  }

  .sm\:col-3 {
    flex: none;
    width: 25%;
    padding: .5rem;
  }

  .sm\:col-4 {
    flex: none;
    width: 33.3333%;
    padding: .5rem;
  }

  .sm\:col-5 {
    flex: none;
    width: 41.6667%;
    padding: .5rem;
  }

  .sm\:col-6 {
    flex: none;
    width: 50%;
    padding: .5rem;
  }

  .sm\:col-7 {
    flex: none;
    width: 58.3333%;
    padding: .5rem;
  }

  .sm\:col-8 {
    flex: none;
    width: 66.6667%;
    padding: .5rem;
  }

  .sm\:col-9 {
    flex: none;
    width: 75%;
    padding: .5rem;
  }

  .sm\:col-10 {
    flex: none;
    width: 83.3333%;
    padding: .5rem;
  }

  .sm\:col-11 {
    flex: none;
    width: 91.6667%;
    padding: .5rem;
  }

  .sm\:col-12 {
    flex: none;
    width: 100%;
    padding: .5rem;
  }
}

@media screen and (width >= 768px) {
  .md\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .md\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .md\:col-1 {
    flex: none;
    width: 8.3333%;
    padding: .5rem;
  }

  .md\:col-2 {
    flex: none;
    width: 16.6667%;
    padding: .5rem;
  }

  .md\:col-3 {
    flex: none;
    width: 25%;
    padding: .5rem;
  }

  .md\:col-4 {
    flex: none;
    width: 33.3333%;
    padding: .5rem;
  }

  .md\:col-5 {
    flex: none;
    width: 41.6667%;
    padding: .5rem;
  }

  .md\:col-6 {
    flex: none;
    width: 50%;
    padding: .5rem;
  }

  .md\:col-7 {
    flex: none;
    width: 58.3333%;
    padding: .5rem;
  }

  .md\:col-8 {
    flex: none;
    width: 66.6667%;
    padding: .5rem;
  }

  .md\:col-9 {
    flex: none;
    width: 75%;
    padding: .5rem;
  }

  .md\:col-10 {
    flex: none;
    width: 83.3333%;
    padding: .5rem;
  }

  .md\:col-11 {
    flex: none;
    width: 91.6667%;
    padding: .5rem;
  }

  .md\:col-12 {
    flex: none;
    width: 100%;
    padding: .5rem;
  }
}

@media screen and (width >= 992px) {
  .lg\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .lg\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .lg\:col-1 {
    flex: none;
    width: 8.3333%;
    padding: .5rem;
  }

  .lg\:col-2 {
    flex: none;
    width: 16.6667%;
    padding: .5rem;
  }

  .lg\:col-3 {
    flex: none;
    width: 25%;
    padding: .5rem;
  }

  .lg\:col-4 {
    flex: none;
    width: 33.3333%;
    padding: .5rem;
  }

  .lg\:col-5 {
    flex: none;
    width: 41.6667%;
    padding: .5rem;
  }

  .lg\:col-6 {
    flex: none;
    width: 50%;
    padding: .5rem;
  }

  .lg\:col-7 {
    flex: none;
    width: 58.3333%;
    padding: .5rem;
  }

  .lg\:col-8 {
    flex: none;
    width: 66.6667%;
    padding: .5rem;
  }

  .lg\:col-9 {
    flex: none;
    width: 75%;
    padding: .5rem;
  }

  .lg\:col-10 {
    flex: none;
    width: 83.3333%;
    padding: .5rem;
  }

  .lg\:col-11 {
    flex: none;
    width: 91.6667%;
    padding: .5rem;
  }

  .lg\:col-12 {
    flex: none;
    width: 100%;
    padding: .5rem;
  }
}

@media screen and (width >= 1200px) {
  .xl\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: .5rem;
  }

  .xl\:col-fixed {
    flex: none;
    padding: .5rem;
  }

  .xl\:col-1 {
    flex: none;
    width: 8.3333%;
    padding: .5rem;
  }

  .xl\:col-2 {
    flex: none;
    width: 16.6667%;
    padding: .5rem;
  }

  .xl\:col-3 {
    flex: none;
    width: 25%;
    padding: .5rem;
  }

  .xl\:col-4 {
    flex: none;
    width: 33.3333%;
    padding: .5rem;
  }

  .xl\:col-5 {
    flex: none;
    width: 41.6667%;
    padding: .5rem;
  }

  .xl\:col-6 {
    flex: none;
    width: 50%;
    padding: .5rem;
  }

  .xl\:col-7 {
    flex: none;
    width: 58.3333%;
    padding: .5rem;
  }

  .xl\:col-8 {
    flex: none;
    width: 66.6667%;
    padding: .5rem;
  }

  .xl\:col-9 {
    flex: none;
    width: 75%;
    padding: .5rem;
  }

  .xl\:col-10 {
    flex: none;
    width: 83.3333%;
    padding: .5rem;
  }

  .xl\:col-11 {
    flex: none;
    width: 91.6667%;
    padding: .5rem;
  }

  .xl\:col-12 {
    flex: none;
    width: 100%;
    padding: .5rem;
  }
}

.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (width >= 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }

  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }

  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }

  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }

  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }

  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .md\:col-offset-3 {
    margin-left: 25% !important;
  }

  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .md\:col-offset-6 {
    margin-left: 50% !important;
  }

  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .md\:col-offset-9 {
    margin-left: 75% !important;
  }

  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }

  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }

  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }

  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }

  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }

  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }

  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }

  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }

  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }

  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }

  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }

  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }

  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }

  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }

  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }

  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }

  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}

.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus, .hover\:text-0:hover, .active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus, .hover\:text-50:hover, .active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus, .hover\:text-100:hover, .active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus, .hover\:text-200:hover, .active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus, .hover\:text-300:hover, .active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus, .hover\:text-400:hover, .active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus, .hover\:text-500:hover, .active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus, .hover\:text-600:hover, .active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus, .hover\:text-700:hover, .active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus, .hover\:text-800:hover, .active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus, .hover\:text-900:hover, .active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus, .hover\:surface-0:hover, .active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus, .hover\:surface-50:hover, .active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus, .hover\:surface-100:hover, .active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus, .hover\:surface-200:hover, .active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus, .hover\:surface-300:hover, .active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus, .hover\:surface-400:hover, .active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus, .hover\:surface-500:hover, .active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus, .hover\:surface-600:hover, .active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus, .hover\:surface-700:hover, .active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus, .hover\:surface-800:hover, .active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus, .hover\:surface-900:hover, .active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus, .hover\:border-0:hover, .active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus, .hover\:border-50:hover, .active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus, .hover\:border-100:hover, .active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus, .hover\:border-200:hover, .active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus, .hover\:border-300:hover, .active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus, .hover\:border-400:hover, .active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus, .hover\:border-500:hover, .active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus, .hover\:border-600:hover, .active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus, .hover\:border-700:hover, .active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus, .hover\:border-800:hover, .active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus, .hover\:border-900:hover, .active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

@media screen and (width >= 576px) {
  .sm\:bg-transparent {
    background-color: #0000 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:bg-transparent {
    background-color: #0000 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:bg-transparent {
    background-color: #0000 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:bg-transparent {
    background-color: #0000 !important;
  }
}

.border-transparent {
  border-color: #0000 !important;
}

@media screen and (width >= 576px) {
  .sm\:border-transparent {
    border-color: #0000 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:border-transparent {
    border-color: #0000 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:border-transparent {
    border-color: #0000 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:border-transparent {
    border-color: #0000 !important;
  }
}

.text-blue-50 {
  color: var(--blue-50) !important;
}

.text-blue-100 {
  color: var(--blue-100) !important;
}

.text-blue-200 {
  color: var(--blue-200) !important;
}

.text-blue-300 {
  color: var(--blue-300) !important;
}

.text-blue-400 {
  color: var(--blue-400) !important;
}

.text-blue-500 {
  color: var(--blue-500) !important;
}

.text-blue-600 {
  color: var(--blue-600) !important;
}

.text-blue-700 {
  color: var(--blue-700) !important;
}

.text-blue-800 {
  color: var(--blue-800) !important;
}

.text-blue-900 {
  color: var(--blue-900) !important;
}

.focus\:text-blue-50:focus {
  color: var(--blue-50) !important;
}

.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}

.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}

.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}

.focus\:text-blue-400:focus {
  color: var(--blue-400) !important;
}

.focus\:text-blue-500:focus {
  color: var(--blue-500) !important;
}

.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}

.focus\:text-blue-700:focus {
  color: var(--blue-700) !important;
}

.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}

.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-50:hover {
  color: var(--blue-50) !important;
}

.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}

.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}

.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}

.hover\:text-blue-400:hover {
  color: var(--blue-400) !important;
}

.hover\:text-blue-500:hover {
  color: var(--blue-500) !important;
}

.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}

.hover\:text-blue-700:hover {
  color: var(--blue-700) !important;
}

.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}

.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-50:active {
  color: var(--blue-50) !important;
}

.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}

.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}

.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}

.active\:text-blue-400:active {
  color: var(--blue-400) !important;
}

.active\:text-blue-500:active {
  color: var(--blue-500) !important;
}

.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}

.active\:text-blue-700:active {
  color: var(--blue-700) !important;
}

.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}

.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}

.text-green-100 {
  color: var(--green-100) !important;
}

.text-green-200 {
  color: var(--green-200) !important;
}

.text-green-300 {
  color: var(--green-300) !important;
}

.text-green-400 {
  color: var(--green-400) !important;
}

.text-green-500 {
  color: var(--green-500) !important;
}

.text-green-600 {
  color: var(--green-600) !important;
}

.text-green-700 {
  color: var(--green-700) !important;
}

.text-green-800 {
  color: var(--green-800) !important;
}

.text-green-900 {
  color: var(--green-900) !important;
}

.focus\:text-green-50:focus {
  color: var(--green-50) !important;
}

.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}

.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}

.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}

.focus\:text-green-400:focus {
  color: var(--green-400) !important;
}

.focus\:text-green-500:focus {
  color: var(--green-500) !important;
}

.focus\:text-green-600:focus {
  color: var(--green-600) !important;
}

.focus\:text-green-700:focus {
  color: var(--green-700) !important;
}

.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}

.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-50:hover {
  color: var(--green-50) !important;
}

.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}

.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}

.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}

.hover\:text-green-400:hover {
  color: var(--green-400) !important;
}

.hover\:text-green-500:hover {
  color: var(--green-500) !important;
}

.hover\:text-green-600:hover {
  color: var(--green-600) !important;
}

.hover\:text-green-700:hover {
  color: var(--green-700) !important;
}

.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}

.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-50:active {
  color: var(--green-50) !important;
}

.active\:text-green-100:active {
  color: var(--green-100) !important;
}

.active\:text-green-200:active {
  color: var(--green-200) !important;
}

.active\:text-green-300:active {
  color: var(--green-300) !important;
}

.active\:text-green-400:active {
  color: var(--green-400) !important;
}

.active\:text-green-500:active {
  color: var(--green-500) !important;
}

.active\:text-green-600:active {
  color: var(--green-600) !important;
}

.active\:text-green-700:active {
  color: var(--green-700) !important;
}

.active\:text-green-800:active {
  color: var(--green-800) !important;
}

.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}

.text-yellow-100 {
  color: var(--yellow-100) !important;
}

.text-yellow-200 {
  color: var(--yellow-200) !important;
}

.text-yellow-300 {
  color: var(--yellow-300) !important;
}

.text-yellow-400 {
  color: var(--yellow-400) !important;
}

.text-yellow-500 {
  color: var(--yellow-500) !important;
}

.text-yellow-600 {
  color: var(--yellow-600) !important;
}

.text-yellow-700 {
  color: var(--yellow-700) !important;
}

.text-yellow-800 {
  color: var(--yellow-800) !important;
}

.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-50:focus {
  color: var(--yellow-50) !important;
}

.focus\:text-yellow-100:focus {
  color: var(--yellow-100) !important;
}

.focus\:text-yellow-200:focus {
  color: var(--yellow-200) !important;
}

.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}

.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}

.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}

.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}

.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}

.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}

.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-50:hover {
  color: var(--yellow-50) !important;
}

.hover\:text-yellow-100:hover {
  color: var(--yellow-100) !important;
}

.hover\:text-yellow-200:hover {
  color: var(--yellow-200) !important;
}

.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}

.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}

.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}

.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}

.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}

.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}

.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-50:active {
  color: var(--yellow-50) !important;
}

.active\:text-yellow-100:active {
  color: var(--yellow-100) !important;
}

.active\:text-yellow-200:active {
  color: var(--yellow-200) !important;
}

.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}

.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}

.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}

.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}

.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}

.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}

.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}

.text-cyan-100 {
  color: var(--cyan-100) !important;
}

.text-cyan-200 {
  color: var(--cyan-200) !important;
}

.text-cyan-300 {
  color: var(--cyan-300) !important;
}

.text-cyan-400 {
  color: var(--cyan-400) !important;
}

.text-cyan-500 {
  color: var(--cyan-500) !important;
}

.text-cyan-600 {
  color: var(--cyan-600) !important;
}

.text-cyan-700 {
  color: var(--cyan-700) !important;
}

.text-cyan-800 {
  color: var(--cyan-800) !important;
}

.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.focus\:text-cyan-50:focus {
  color: var(--cyan-50) !important;
}

.focus\:text-cyan-100:focus {
  color: var(--cyan-100) !important;
}

.focus\:text-cyan-200:focus {
  color: var(--cyan-200) !important;
}

.focus\:text-cyan-300:focus {
  color: var(--cyan-300) !important;
}

.focus\:text-cyan-400:focus {
  color: var(--cyan-400) !important;
}

.focus\:text-cyan-500:focus {
  color: var(--cyan-500) !important;
}

.focus\:text-cyan-600:focus {
  color: var(--cyan-600) !important;
}

.focus\:text-cyan-700:focus {
  color: var(--cyan-700) !important;
}

.focus\:text-cyan-800:focus {
  color: var(--cyan-800) !important;
}

.focus\:text-cyan-900:focus {
  color: var(--cyan-900) !important;
}

.hover\:text-cyan-50:hover {
  color: var(--cyan-50) !important;
}

.hover\:text-cyan-100:hover {
  color: var(--cyan-100) !important;
}

.hover\:text-cyan-200:hover {
  color: var(--cyan-200) !important;
}

.hover\:text-cyan-300:hover {
  color: var(--cyan-300) !important;
}

.hover\:text-cyan-400:hover {
  color: var(--cyan-400) !important;
}

.hover\:text-cyan-500:hover {
  color: var(--cyan-500) !important;
}

.hover\:text-cyan-600:hover {
  color: var(--cyan-600) !important;
}

.hover\:text-cyan-700:hover {
  color: var(--cyan-700) !important;
}

.hover\:text-cyan-800:hover {
  color: var(--cyan-800) !important;
}

.hover\:text-cyan-900:hover {
  color: var(--cyan-900) !important;
}

.active\:text-cyan-50:active {
  color: var(--cyan-50) !important;
}

.active\:text-cyan-100:active {
  color: var(--cyan-100) !important;
}

.active\:text-cyan-200:active {
  color: var(--cyan-200) !important;
}

.active\:text-cyan-300:active {
  color: var(--cyan-300) !important;
}

.active\:text-cyan-400:active {
  color: var(--cyan-400) !important;
}

.active\:text-cyan-500:active {
  color: var(--cyan-500) !important;
}

.active\:text-cyan-600:active {
  color: var(--cyan-600) !important;
}

.active\:text-cyan-700:active {
  color: var(--cyan-700) !important;
}

.active\:text-cyan-800:active {
  color: var(--cyan-800) !important;
}

.active\:text-cyan-900:active {
  color: var(--cyan-900) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}

.text-pink-100 {
  color: var(--pink-100) !important;
}

.text-pink-200 {
  color: var(--pink-200) !important;
}

.text-pink-300 {
  color: var(--pink-300) !important;
}

.text-pink-400 {
  color: var(--pink-400) !important;
}

.text-pink-500 {
  color: var(--pink-500) !important;
}

.text-pink-600 {
  color: var(--pink-600) !important;
}

.text-pink-700 {
  color: var(--pink-700) !important;
}

.text-pink-800 {
  color: var(--pink-800) !important;
}

.text-pink-900 {
  color: var(--pink-900) !important;
}

.focus\:text-pink-50:focus {
  color: var(--pink-50) !important;
}

.focus\:text-pink-100:focus {
  color: var(--pink-100) !important;
}

.focus\:text-pink-200:focus {
  color: var(--pink-200) !important;
}

.focus\:text-pink-300:focus {
  color: var(--pink-300) !important;
}

.focus\:text-pink-400:focus {
  color: var(--pink-400) !important;
}

.focus\:text-pink-500:focus {
  color: var(--pink-500) !important;
}

.focus\:text-pink-600:focus {
  color: var(--pink-600) !important;
}

.focus\:text-pink-700:focus {
  color: var(--pink-700) !important;
}

.focus\:text-pink-800:focus {
  color: var(--pink-800) !important;
}

.focus\:text-pink-900:focus {
  color: var(--pink-900) !important;
}

.hover\:text-pink-50:hover {
  color: var(--pink-50) !important;
}

.hover\:text-pink-100:hover {
  color: var(--pink-100) !important;
}

.hover\:text-pink-200:hover {
  color: var(--pink-200) !important;
}

.hover\:text-pink-300:hover {
  color: var(--pink-300) !important;
}

.hover\:text-pink-400:hover {
  color: var(--pink-400) !important;
}

.hover\:text-pink-500:hover {
  color: var(--pink-500) !important;
}

.hover\:text-pink-600:hover {
  color: var(--pink-600) !important;
}

.hover\:text-pink-700:hover {
  color: var(--pink-700) !important;
}

.hover\:text-pink-800:hover {
  color: var(--pink-800) !important;
}

.hover\:text-pink-900:hover {
  color: var(--pink-900) !important;
}

.active\:text-pink-50:active {
  color: var(--pink-50) !important;
}

.active\:text-pink-100:active {
  color: var(--pink-100) !important;
}

.active\:text-pink-200:active {
  color: var(--pink-200) !important;
}

.active\:text-pink-300:active {
  color: var(--pink-300) !important;
}

.active\:text-pink-400:active {
  color: var(--pink-400) !important;
}

.active\:text-pink-500:active {
  color: var(--pink-500) !important;
}

.active\:text-pink-600:active {
  color: var(--pink-600) !important;
}

.active\:text-pink-700:active {
  color: var(--pink-700) !important;
}

.active\:text-pink-800:active {
  color: var(--pink-800) !important;
}

.active\:text-pink-900:active {
  color: var(--pink-900) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}

.text-indigo-100 {
  color: var(--indigo-100) !important;
}

.text-indigo-200 {
  color: var(--indigo-200) !important;
}

.text-indigo-300 {
  color: var(--indigo-300) !important;
}

.text-indigo-400 {
  color: var(--indigo-400) !important;
}

.text-indigo-500 {
  color: var(--indigo-500) !important;
}

.text-indigo-600 {
  color: var(--indigo-600) !important;
}

.text-indigo-700 {
  color: var(--indigo-700) !important;
}

.text-indigo-800 {
  color: var(--indigo-800) !important;
}

.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.focus\:text-indigo-50:focus {
  color: var(--indigo-50) !important;
}

.focus\:text-indigo-100:focus {
  color: var(--indigo-100) !important;
}

.focus\:text-indigo-200:focus {
  color: var(--indigo-200) !important;
}

.focus\:text-indigo-300:focus {
  color: var(--indigo-300) !important;
}

.focus\:text-indigo-400:focus {
  color: var(--indigo-400) !important;
}

.focus\:text-indigo-500:focus {
  color: var(--indigo-500) !important;
}

.focus\:text-indigo-600:focus {
  color: var(--indigo-600) !important;
}

.focus\:text-indigo-700:focus {
  color: var(--indigo-700) !important;
}

.focus\:text-indigo-800:focus {
  color: var(--indigo-800) !important;
}

.focus\:text-indigo-900:focus {
  color: var(--indigo-900) !important;
}

.hover\:text-indigo-50:hover {
  color: var(--indigo-50) !important;
}

.hover\:text-indigo-100:hover {
  color: var(--indigo-100) !important;
}

.hover\:text-indigo-200:hover {
  color: var(--indigo-200) !important;
}

.hover\:text-indigo-300:hover {
  color: var(--indigo-300) !important;
}

.hover\:text-indigo-400:hover {
  color: var(--indigo-400) !important;
}

.hover\:text-indigo-500:hover {
  color: var(--indigo-500) !important;
}

.hover\:text-indigo-600:hover {
  color: var(--indigo-600) !important;
}

.hover\:text-indigo-700:hover {
  color: var(--indigo-700) !important;
}

.hover\:text-indigo-800:hover {
  color: var(--indigo-800) !important;
}

.hover\:text-indigo-900:hover {
  color: var(--indigo-900) !important;
}

.active\:text-indigo-50:active {
  color: var(--indigo-50) !important;
}

.active\:text-indigo-100:active {
  color: var(--indigo-100) !important;
}

.active\:text-indigo-200:active {
  color: var(--indigo-200) !important;
}

.active\:text-indigo-300:active {
  color: var(--indigo-300) !important;
}

.active\:text-indigo-400:active {
  color: var(--indigo-400) !important;
}

.active\:text-indigo-500:active {
  color: var(--indigo-500) !important;
}

.active\:text-indigo-600:active {
  color: var(--indigo-600) !important;
}

.active\:text-indigo-700:active {
  color: var(--indigo-700) !important;
}

.active\:text-indigo-800:active {
  color: var(--indigo-800) !important;
}

.active\:text-indigo-900:active {
  color: var(--indigo-900) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}

.text-teal-100 {
  color: var(--teal-100) !important;
}

.text-teal-200 {
  color: var(--teal-200) !important;
}

.text-teal-300 {
  color: var(--teal-300) !important;
}

.text-teal-400 {
  color: var(--teal-400) !important;
}

.text-teal-500 {
  color: var(--teal-500) !important;
}

.text-teal-600 {
  color: var(--teal-600) !important;
}

.text-teal-700 {
  color: var(--teal-700) !important;
}

.text-teal-800 {
  color: var(--teal-800) !important;
}

.text-teal-900 {
  color: var(--teal-900) !important;
}

.focus\:text-teal-50:focus {
  color: var(--teal-50) !important;
}

.focus\:text-teal-100:focus {
  color: var(--teal-100) !important;
}

.focus\:text-teal-200:focus {
  color: var(--teal-200) !important;
}

.focus\:text-teal-300:focus {
  color: var(--teal-300) !important;
}

.focus\:text-teal-400:focus {
  color: var(--teal-400) !important;
}

.focus\:text-teal-500:focus {
  color: var(--teal-500) !important;
}

.focus\:text-teal-600:focus {
  color: var(--teal-600) !important;
}

.focus\:text-teal-700:focus {
  color: var(--teal-700) !important;
}

.focus\:text-teal-800:focus {
  color: var(--teal-800) !important;
}

.focus\:text-teal-900:focus {
  color: var(--teal-900) !important;
}

.hover\:text-teal-50:hover {
  color: var(--teal-50) !important;
}

.hover\:text-teal-100:hover {
  color: var(--teal-100) !important;
}

.hover\:text-teal-200:hover {
  color: var(--teal-200) !important;
}

.hover\:text-teal-300:hover {
  color: var(--teal-300) !important;
}

.hover\:text-teal-400:hover {
  color: var(--teal-400) !important;
}

.hover\:text-teal-500:hover {
  color: var(--teal-500) !important;
}

.hover\:text-teal-600:hover {
  color: var(--teal-600) !important;
}

.hover\:text-teal-700:hover {
  color: var(--teal-700) !important;
}

.hover\:text-teal-800:hover {
  color: var(--teal-800) !important;
}

.hover\:text-teal-900:hover {
  color: var(--teal-900) !important;
}

.active\:text-teal-50:active {
  color: var(--teal-50) !important;
}

.active\:text-teal-100:active {
  color: var(--teal-100) !important;
}

.active\:text-teal-200:active {
  color: var(--teal-200) !important;
}

.active\:text-teal-300:active {
  color: var(--teal-300) !important;
}

.active\:text-teal-400:active {
  color: var(--teal-400) !important;
}

.active\:text-teal-500:active {
  color: var(--teal-500) !important;
}

.active\:text-teal-600:active {
  color: var(--teal-600) !important;
}

.active\:text-teal-700:active {
  color: var(--teal-700) !important;
}

.active\:text-teal-800:active {
  color: var(--teal-800) !important;
}

.active\:text-teal-900:active {
  color: var(--teal-900) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}

.text-orange-100 {
  color: var(--orange-100) !important;
}

.text-orange-200 {
  color: var(--orange-200) !important;
}

.text-orange-300 {
  color: var(--orange-300) !important;
}

.text-orange-400 {
  color: var(--orange-400) !important;
}

.text-orange-500 {
  color: var(--orange-500) !important;
}

.text-orange-600 {
  color: var(--orange-600) !important;
}

.text-orange-700 {
  color: var(--orange-700) !important;
}

.text-orange-800 {
  color: var(--orange-800) !important;
}

.text-orange-900 {
  color: var(--orange-900) !important;
}

.focus\:text-orange-50:focus {
  color: var(--orange-50) !important;
}

.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}

.focus\:text-orange-200:focus {
  color: var(--orange-200) !important;
}

.focus\:text-orange-300:focus {
  color: var(--orange-300) !important;
}

.focus\:text-orange-400:focus {
  color: var(--orange-400) !important;
}

.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}

.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}

.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}

.focus\:text-orange-800:focus {
  color: var(--orange-800) !important;
}

.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-50:hover {
  color: var(--orange-50) !important;
}

.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}

.hover\:text-orange-200:hover {
  color: var(--orange-200) !important;
}

.hover\:text-orange-300:hover {
  color: var(--orange-300) !important;
}

.hover\:text-orange-400:hover {
  color: var(--orange-400) !important;
}

.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}

.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}

.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}

.hover\:text-orange-800:hover {
  color: var(--orange-800) !important;
}

.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-50:active {
  color: var(--orange-50) !important;
}

.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}

.active\:text-orange-200:active {
  color: var(--orange-200) !important;
}

.active\:text-orange-300:active {
  color: var(--orange-300) !important;
}

.active\:text-orange-400:active {
  color: var(--orange-400) !important;
}

.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}

.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}

.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}

.active\:text-orange-800:active {
  color: var(--orange-800) !important;
}

.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.text-bluegray-50 {
  color: var(--bluegray-50) !important;
}

.text-bluegray-100 {
  color: var(--bluegray-100) !important;
}

.text-bluegray-200 {
  color: var(--bluegray-200) !important;
}

.text-bluegray-300 {
  color: var(--bluegray-300) !important;
}

.text-bluegray-400 {
  color: var(--bluegray-400) !important;
}

.text-bluegray-500 {
  color: var(--bluegray-500) !important;
}

.text-bluegray-600 {
  color: var(--bluegray-600) !important;
}

.text-bluegray-700 {
  color: var(--bluegray-700) !important;
}

.text-bluegray-800 {
  color: var(--bluegray-800) !important;
}

.text-bluegray-900 {
  color: var(--bluegray-900) !important;
}

.focus\:text-bluegray-50:focus {
  color: var(--bluegray-50) !important;
}

.focus\:text-bluegray-100:focus {
  color: var(--bluegray-100) !important;
}

.focus\:text-bluegray-200:focus {
  color: var(--bluegray-200) !important;
}

.focus\:text-bluegray-300:focus {
  color: var(--bluegray-300) !important;
}

.focus\:text-bluegray-400:focus {
  color: var(--bluegray-400) !important;
}

.focus\:text-bluegray-500:focus {
  color: var(--bluegray-500) !important;
}

.focus\:text-bluegray-600:focus {
  color: var(--bluegray-600) !important;
}

.focus\:text-bluegray-700:focus {
  color: var(--bluegray-700) !important;
}

.focus\:text-bluegray-800:focus {
  color: var(--bluegray-800) !important;
}

.focus\:text-bluegray-900:focus {
  color: var(--bluegray-900) !important;
}

.hover\:text-bluegray-50:hover {
  color: var(--bluegray-50) !important;
}

.hover\:text-bluegray-100:hover {
  color: var(--bluegray-100) !important;
}

.hover\:text-bluegray-200:hover {
  color: var(--bluegray-200) !important;
}

.hover\:text-bluegray-300:hover {
  color: var(--bluegray-300) !important;
}

.hover\:text-bluegray-400:hover {
  color: var(--bluegray-400) !important;
}

.hover\:text-bluegray-500:hover {
  color: var(--bluegray-500) !important;
}

.hover\:text-bluegray-600:hover {
  color: var(--bluegray-600) !important;
}

.hover\:text-bluegray-700:hover {
  color: var(--bluegray-700) !important;
}

.hover\:text-bluegray-800:hover {
  color: var(--bluegray-800) !important;
}

.hover\:text-bluegray-900:hover {
  color: var(--bluegray-900) !important;
}

.active\:text-bluegray-50:active {
  color: var(--bluegray-50) !important;
}

.active\:text-bluegray-100:active {
  color: var(--bluegray-100) !important;
}

.active\:text-bluegray-200:active {
  color: var(--bluegray-200) !important;
}

.active\:text-bluegray-300:active {
  color: var(--bluegray-300) !important;
}

.active\:text-bluegray-400:active {
  color: var(--bluegray-400) !important;
}

.active\:text-bluegray-500:active {
  color: var(--bluegray-500) !important;
}

.active\:text-bluegray-600:active {
  color: var(--bluegray-600) !important;
}

.active\:text-bluegray-700:active {
  color: var(--bluegray-700) !important;
}

.active\:text-bluegray-800:active {
  color: var(--bluegray-800) !important;
}

.active\:text-bluegray-900:active {
  color: var(--bluegray-900) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}

.text-purple-100 {
  color: var(--purple-100) !important;
}

.text-purple-200 {
  color: var(--purple-200) !important;
}

.text-purple-300 {
  color: var(--purple-300) !important;
}

.text-purple-400 {
  color: var(--purple-400) !important;
}

.text-purple-500 {
  color: var(--purple-500) !important;
}

.text-purple-600 {
  color: var(--purple-600) !important;
}

.text-purple-700 {
  color: var(--purple-700) !important;
}

.text-purple-800 {
  color: var(--purple-800) !important;
}

.text-purple-900 {
  color: var(--purple-900) !important;
}

.focus\:text-purple-50:focus {
  color: var(--purple-50) !important;
}

.focus\:text-purple-100:focus {
  color: var(--purple-100) !important;
}

.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}

.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}

.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}

.focus\:text-purple-500:focus {
  color: var(--purple-500) !important;
}

.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}

.focus\:text-purple-700:focus {
  color: var(--purple-700) !important;
}

.focus\:text-purple-800:focus {
  color: var(--purple-800) !important;
}

.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-50:hover {
  color: var(--purple-50) !important;
}

.hover\:text-purple-100:hover {
  color: var(--purple-100) !important;
}

.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}

.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}

.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}

.hover\:text-purple-500:hover {
  color: var(--purple-500) !important;
}

.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}

.hover\:text-purple-700:hover {
  color: var(--purple-700) !important;
}

.hover\:text-purple-800:hover {
  color: var(--purple-800) !important;
}

.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-50:active {
  color: var(--purple-50) !important;
}

.active\:text-purple-100:active {
  color: var(--purple-100) !important;
}

.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}

.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}

.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}

.active\:text-purple-500:active {
  color: var(--purple-500) !important;
}

.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}

.active\:text-purple-700:active {
  color: var(--purple-700) !important;
}

.active\:text-purple-800:active {
  color: var(--purple-800) !important;
}

.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}

.text-gray-100 {
  color: var(--gray-100) !important;
}

.text-gray-200 {
  color: var(--gray-200) !important;
}

.text-gray-300 {
  color: var(--gray-300) !important;
}

.text-gray-400 {
  color: var(--gray-400) !important;
}

.text-gray-500 {
  color: var(--gray-500) !important;
}

.text-gray-600 {
  color: var(--gray-600) !important;
}

.text-gray-700 {
  color: var(--gray-700) !important;
}

.text-gray-800 {
  color: var(--gray-800) !important;
}

.text-gray-900 {
  color: var(--gray-900) !important;
}

.focus\:text-gray-50:focus {
  color: var(--gray-50) !important;
}

.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}

.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}

.focus\:text-gray-300:focus {
  color: var(--gray-300) !important;
}

.focus\:text-gray-400:focus {
  color: var(--gray-400) !important;
}

.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}

.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}

.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}

.focus\:text-gray-800:focus {
  color: var(--gray-800) !important;
}

.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-50:hover {
  color: var(--gray-50) !important;
}

.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}

.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}

.hover\:text-gray-300:hover {
  color: var(--gray-300) !important;
}

.hover\:text-gray-400:hover {
  color: var(--gray-400) !important;
}

.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}

.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}

.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}

.hover\:text-gray-800:hover {
  color: var(--gray-800) !important;
}

.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-50:active {
  color: var(--gray-50) !important;
}

.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}

.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}

.active\:text-gray-300:active {
  color: var(--gray-300) !important;
}

.active\:text-gray-400:active {
  color: var(--gray-400) !important;
}

.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}

.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}

.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}

.active\:text-gray-800:active {
  color: var(--gray-800) !important;
}

.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}

.text-red-100 {
  color: var(--red-100) !important;
}

.text-red-200 {
  color: var(--red-200) !important;
}

.text-red-300 {
  color: var(--red-300) !important;
}

.text-red-400 {
  color: var(--red-400) !important;
}

.text-red-500 {
  color: var(--red-500) !important;
}

.text-red-600 {
  color: var(--red-600) !important;
}

.text-red-700 {
  color: var(--red-700) !important;
}

.text-red-800 {
  color: var(--red-800) !important;
}

.text-red-900 {
  color: var(--red-900) !important;
}

.focus\:text-red-50:focus {
  color: var(--red-50) !important;
}

.focus\:text-red-100:focus {
  color: var(--red-100) !important;
}

.focus\:text-red-200:focus {
  color: var(--red-200) !important;
}

.focus\:text-red-300:focus {
  color: var(--red-300) !important;
}

.focus\:text-red-400:focus {
  color: var(--red-400) !important;
}

.focus\:text-red-500:focus {
  color: var(--red-500) !important;
}

.focus\:text-red-600:focus {
  color: var(--red-600) !important;
}

.focus\:text-red-700:focus {
  color: var(--red-700) !important;
}

.focus\:text-red-800:focus {
  color: var(--red-800) !important;
}

.focus\:text-red-900:focus {
  color: var(--red-900) !important;
}

.hover\:text-red-50:hover {
  color: var(--red-50) !important;
}

.hover\:text-red-100:hover {
  color: var(--red-100) !important;
}

.hover\:text-red-200:hover {
  color: var(--red-200) !important;
}

.hover\:text-red-300:hover {
  color: var(--red-300) !important;
}

.hover\:text-red-400:hover {
  color: var(--red-400) !important;
}

.hover\:text-red-500:hover {
  color: var(--red-500) !important;
}

.hover\:text-red-600:hover {
  color: var(--red-600) !important;
}

.hover\:text-red-700:hover {
  color: var(--red-700) !important;
}

.hover\:text-red-800:hover {
  color: var(--red-800) !important;
}

.hover\:text-red-900:hover {
  color: var(--red-900) !important;
}

.active\:text-red-50:active {
  color: var(--red-50) !important;
}

.active\:text-red-100:active {
  color: var(--red-100) !important;
}

.active\:text-red-200:active {
  color: var(--red-200) !important;
}

.active\:text-red-300:active {
  color: var(--red-300) !important;
}

.active\:text-red-400:active {
  color: var(--red-400) !important;
}

.active\:text-red-500:active {
  color: var(--red-500) !important;
}

.active\:text-red-600:active {
  color: var(--red-600) !important;
}

.active\:text-red-700:active {
  color: var(--red-700) !important;
}

.active\:text-red-800:active {
  color: var(--red-800) !important;
}

.active\:text-red-900:active {
  color: var(--red-900) !important;
}

.text-primary-50 {
  color: var(--primary-50) !important;
}

.text-primary-100 {
  color: var(--primary-100) !important;
}

.text-primary-200 {
  color: var(--primary-200) !important;
}

.text-primary-300 {
  color: var(--primary-300) !important;
}

.text-primary-400 {
  color: var(--primary-400) !important;
}

.text-primary-500 {
  color: var(--primary-500) !important;
}

.text-primary-600 {
  color: var(--primary-600) !important;
}

.text-primary-700 {
  color: var(--primary-700) !important;
}

.text-primary-800 {
  color: var(--primary-800) !important;
}

.text-primary-900 {
  color: var(--primary-900) !important;
}

.focus\:text-primary-50:focus {
  color: var(--primary-50) !important;
}

.focus\:text-primary-100:focus {
  color: var(--primary-100) !important;
}

.focus\:text-primary-200:focus {
  color: var(--primary-200) !important;
}

.focus\:text-primary-300:focus {
  color: var(--primary-300) !important;
}

.focus\:text-primary-400:focus {
  color: var(--primary-400) !important;
}

.focus\:text-primary-500:focus {
  color: var(--primary-500) !important;
}

.focus\:text-primary-600:focus {
  color: var(--primary-600) !important;
}

.focus\:text-primary-700:focus {
  color: var(--primary-700) !important;
}

.focus\:text-primary-800:focus {
  color: var(--primary-800) !important;
}

.focus\:text-primary-900:focus {
  color: var(--primary-900) !important;
}

.hover\:text-primary-50:hover {
  color: var(--primary-50) !important;
}

.hover\:text-primary-100:hover {
  color: var(--primary-100) !important;
}

.hover\:text-primary-200:hover {
  color: var(--primary-200) !important;
}

.hover\:text-primary-300:hover {
  color: var(--primary-300) !important;
}

.hover\:text-primary-400:hover {
  color: var(--primary-400) !important;
}

.hover\:text-primary-500:hover {
  color: var(--primary-500) !important;
}

.hover\:text-primary-600:hover {
  color: var(--primary-600) !important;
}

.hover\:text-primary-700:hover {
  color: var(--primary-700) !important;
}

.hover\:text-primary-800:hover {
  color: var(--primary-800) !important;
}

.hover\:text-primary-900:hover {
  color: var(--primary-900) !important;
}

.active\:text-primary-50:active {
  color: var(--primary-50) !important;
}

.active\:text-primary-100:active {
  color: var(--primary-100) !important;
}

.active\:text-primary-200:active {
  color: var(--primary-200) !important;
}

.active\:text-primary-300:active {
  color: var(--primary-300) !important;
}

.active\:text-primary-400:active {
  color: var(--primary-400) !important;
}

.active\:text-primary-500:active {
  color: var(--primary-500) !important;
}

.active\:text-primary-600:active {
  color: var(--primary-600) !important;
}

.active\:text-primary-700:active {
  color: var(--primary-700) !important;
}

.active\:text-primary-800:active {
  color: var(--primary-800) !important;
}

.active\:text-primary-900:active {
  color: var(--primary-900) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}

.bg-blue-100 {
  background-color: var(--blue-100) !important;
}

.bg-blue-200 {
  background-color: var(--blue-200) !important;
}

.bg-blue-300 {
  background-color: var(--blue-300) !important;
}

.bg-blue-400 {
  background-color: var(--blue-400) !important;
}

.bg-blue-500 {
  background-color: var(--blue-500) !important;
}

.bg-blue-600 {
  background-color: var(--blue-600) !important;
}

.bg-blue-700 {
  background-color: var(--blue-700) !important;
}

.bg-blue-800 {
  background-color: var(--blue-800) !important;
}

.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-50:focus {
  background-color: var(--blue-50) !important;
}

.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}

.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}

.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}

.focus\:bg-blue-400:focus {
  background-color: var(--blue-400) !important;
}

.focus\:bg-blue-500:focus {
  background-color: var(--blue-500) !important;
}

.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}

.focus\:bg-blue-700:focus {
  background-color: var(--blue-700) !important;
}

.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}

.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-50:hover {
  background-color: var(--blue-50) !important;
}

.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}

.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}

.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}

.hover\:bg-blue-400:hover {
  background-color: var(--blue-400) !important;
}

.hover\:bg-blue-500:hover {
  background-color: var(--blue-500) !important;
}

.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}

.hover\:bg-blue-700:hover {
  background-color: var(--blue-700) !important;
}

.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}

.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-50:active {
  background-color: var(--blue-50) !important;
}

.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}

.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}

.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}

.active\:bg-blue-400:active {
  background-color: var(--blue-400) !important;
}

.active\:bg-blue-500:active {
  background-color: var(--blue-500) !important;
}

.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}

.active\:bg-blue-700:active {
  background-color: var(--blue-700) !important;
}

.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}

.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}

.bg-green-100 {
  background-color: var(--green-100) !important;
}

.bg-green-200 {
  background-color: var(--green-200) !important;
}

.bg-green-300 {
  background-color: var(--green-300) !important;
}

.bg-green-400 {
  background-color: var(--green-400) !important;
}

.bg-green-500 {
  background-color: var(--green-500) !important;
}

.bg-green-600 {
  background-color: var(--green-600) !important;
}

.bg-green-700 {
  background-color: var(--green-700) !important;
}

.bg-green-800 {
  background-color: var(--green-800) !important;
}

.bg-green-900 {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-50:focus {
  background-color: var(--green-50) !important;
}

.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}

.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}

.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}

.focus\:bg-green-400:focus {
  background-color: var(--green-400) !important;
}

.focus\:bg-green-500:focus {
  background-color: var(--green-500) !important;
}

.focus\:bg-green-600:focus {
  background-color: var(--green-600) !important;
}

.focus\:bg-green-700:focus {
  background-color: var(--green-700) !important;
}

.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}

.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-50:hover {
  background-color: var(--green-50) !important;
}

.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}

.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}

.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}

.hover\:bg-green-400:hover {
  background-color: var(--green-400) !important;
}

.hover\:bg-green-500:hover {
  background-color: var(--green-500) !important;
}

.hover\:bg-green-600:hover {
  background-color: var(--green-600) !important;
}

.hover\:bg-green-700:hover {
  background-color: var(--green-700) !important;
}

.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}

.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-50:active {
  background-color: var(--green-50) !important;
}

.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}

.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}

.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}

.active\:bg-green-400:active {
  background-color: var(--green-400) !important;
}

.active\:bg-green-500:active {
  background-color: var(--green-500) !important;
}

.active\:bg-green-600:active {
  background-color: var(--green-600) !important;
}

.active\:bg-green-700:active {
  background-color: var(--green-700) !important;
}

.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}

.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}

.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}

.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}

.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}

.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}

.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}

.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}

.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}

.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}

.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-50:focus {
  background-color: var(--yellow-50) !important;
}

.focus\:bg-yellow-100:focus {
  background-color: var(--yellow-100) !important;
}

.focus\:bg-yellow-200:focus {
  background-color: var(--yellow-200) !important;
}

.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}

.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}

.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}

.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}

.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}

.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}

.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-50:hover {
  background-color: var(--yellow-50) !important;
}

.hover\:bg-yellow-100:hover {
  background-color: var(--yellow-100) !important;
}

.hover\:bg-yellow-200:hover {
  background-color: var(--yellow-200) !important;
}

.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}

.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}

.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}

.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}

.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}

.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}

.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-50:active {
  background-color: var(--yellow-50) !important;
}

.active\:bg-yellow-100:active {
  background-color: var(--yellow-100) !important;
}

.active\:bg-yellow-200:active {
  background-color: var(--yellow-200) !important;
}

.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}

.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}

.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}

.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}

.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}

.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}

.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.bg-cyan-50 {
  background-color: var(--cyan-50) !important;
}

.bg-cyan-100 {
  background-color: var(--cyan-100) !important;
}

.bg-cyan-200 {
  background-color: var(--cyan-200) !important;
}

.bg-cyan-300 {
  background-color: var(--cyan-300) !important;
}

.bg-cyan-400 {
  background-color: var(--cyan-400) !important;
}

.bg-cyan-500 {
  background-color: var(--cyan-500) !important;
}

.bg-cyan-600 {
  background-color: var(--cyan-600) !important;
}

.bg-cyan-700 {
  background-color: var(--cyan-700) !important;
}

.bg-cyan-800 {
  background-color: var(--cyan-800) !important;
}

.bg-cyan-900 {
  background-color: var(--cyan-900) !important;
}

.focus\:bg-cyan-50:focus {
  background-color: var(--cyan-50) !important;
}

.focus\:bg-cyan-100:focus {
  background-color: var(--cyan-100) !important;
}

.focus\:bg-cyan-200:focus {
  background-color: var(--cyan-200) !important;
}

.focus\:bg-cyan-300:focus {
  background-color: var(--cyan-300) !important;
}

.focus\:bg-cyan-400:focus {
  background-color: var(--cyan-400) !important;
}

.focus\:bg-cyan-500:focus {
  background-color: var(--cyan-500) !important;
}

.focus\:bg-cyan-600:focus {
  background-color: var(--cyan-600) !important;
}

.focus\:bg-cyan-700:focus {
  background-color: var(--cyan-700) !important;
}

.focus\:bg-cyan-800:focus {
  background-color: var(--cyan-800) !important;
}

.focus\:bg-cyan-900:focus {
  background-color: var(--cyan-900) !important;
}

.hover\:bg-cyan-50:hover {
  background-color: var(--cyan-50) !important;
}

.hover\:bg-cyan-100:hover {
  background-color: var(--cyan-100) !important;
}

.hover\:bg-cyan-200:hover {
  background-color: var(--cyan-200) !important;
}

.hover\:bg-cyan-300:hover {
  background-color: var(--cyan-300) !important;
}

.hover\:bg-cyan-400:hover {
  background-color: var(--cyan-400) !important;
}

.hover\:bg-cyan-500:hover {
  background-color: var(--cyan-500) !important;
}

.hover\:bg-cyan-600:hover {
  background-color: var(--cyan-600) !important;
}

.hover\:bg-cyan-700:hover {
  background-color: var(--cyan-700) !important;
}

.hover\:bg-cyan-800:hover {
  background-color: var(--cyan-800) !important;
}

.hover\:bg-cyan-900:hover {
  background-color: var(--cyan-900) !important;
}

.active\:bg-cyan-50:active {
  background-color: var(--cyan-50) !important;
}

.active\:bg-cyan-100:active {
  background-color: var(--cyan-100) !important;
}

.active\:bg-cyan-200:active {
  background-color: var(--cyan-200) !important;
}

.active\:bg-cyan-300:active {
  background-color: var(--cyan-300) !important;
}

.active\:bg-cyan-400:active {
  background-color: var(--cyan-400) !important;
}

.active\:bg-cyan-500:active {
  background-color: var(--cyan-500) !important;
}

.active\:bg-cyan-600:active {
  background-color: var(--cyan-600) !important;
}

.active\:bg-cyan-700:active {
  background-color: var(--cyan-700) !important;
}

.active\:bg-cyan-800:active {
  background-color: var(--cyan-800) !important;
}

.active\:bg-cyan-900:active {
  background-color: var(--cyan-900) !important;
}

.bg-pink-50 {
  background-color: var(--pink-50) !important;
}

.bg-pink-100 {
  background-color: var(--pink-100) !important;
}

.bg-pink-200 {
  background-color: var(--pink-200) !important;
}

.bg-pink-300 {
  background-color: var(--pink-300) !important;
}

.bg-pink-400 {
  background-color: var(--pink-400) !important;
}

.bg-pink-500 {
  background-color: var(--pink-500) !important;
}

.bg-pink-600 {
  background-color: var(--pink-600) !important;
}

.bg-pink-700 {
  background-color: var(--pink-700) !important;
}

.bg-pink-800 {
  background-color: var(--pink-800) !important;
}

.bg-pink-900 {
  background-color: var(--pink-900) !important;
}

.focus\:bg-pink-50:focus {
  background-color: var(--pink-50) !important;
}

.focus\:bg-pink-100:focus {
  background-color: var(--pink-100) !important;
}

.focus\:bg-pink-200:focus {
  background-color: var(--pink-200) !important;
}

.focus\:bg-pink-300:focus {
  background-color: var(--pink-300) !important;
}

.focus\:bg-pink-400:focus {
  background-color: var(--pink-400) !important;
}

.focus\:bg-pink-500:focus {
  background-color: var(--pink-500) !important;
}

.focus\:bg-pink-600:focus {
  background-color: var(--pink-600) !important;
}

.focus\:bg-pink-700:focus {
  background-color: var(--pink-700) !important;
}

.focus\:bg-pink-800:focus {
  background-color: var(--pink-800) !important;
}

.focus\:bg-pink-900:focus {
  background-color: var(--pink-900) !important;
}

.hover\:bg-pink-50:hover {
  background-color: var(--pink-50) !important;
}

.hover\:bg-pink-100:hover {
  background-color: var(--pink-100) !important;
}

.hover\:bg-pink-200:hover {
  background-color: var(--pink-200) !important;
}

.hover\:bg-pink-300:hover {
  background-color: var(--pink-300) !important;
}

.hover\:bg-pink-400:hover {
  background-color: var(--pink-400) !important;
}

.hover\:bg-pink-500:hover {
  background-color: var(--pink-500) !important;
}

.hover\:bg-pink-600:hover {
  background-color: var(--pink-600) !important;
}

.hover\:bg-pink-700:hover {
  background-color: var(--pink-700) !important;
}

.hover\:bg-pink-800:hover {
  background-color: var(--pink-800) !important;
}

.hover\:bg-pink-900:hover {
  background-color: var(--pink-900) !important;
}

.active\:bg-pink-50:active {
  background-color: var(--pink-50) !important;
}

.active\:bg-pink-100:active {
  background-color: var(--pink-100) !important;
}

.active\:bg-pink-200:active {
  background-color: var(--pink-200) !important;
}

.active\:bg-pink-300:active {
  background-color: var(--pink-300) !important;
}

.active\:bg-pink-400:active {
  background-color: var(--pink-400) !important;
}

.active\:bg-pink-500:active {
  background-color: var(--pink-500) !important;
}

.active\:bg-pink-600:active {
  background-color: var(--pink-600) !important;
}

.active\:bg-pink-700:active {
  background-color: var(--pink-700) !important;
}

.active\:bg-pink-800:active {
  background-color: var(--pink-800) !important;
}

.active\:bg-pink-900:active {
  background-color: var(--pink-900) !important;
}

.bg-indigo-50 {
  background-color: var(--indigo-50) !important;
}

.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}

.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}

.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}

.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}

.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}

.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}

.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}

.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}

.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

.focus\:bg-indigo-50:focus {
  background-color: var(--indigo-50) !important;
}

.focus\:bg-indigo-100:focus {
  background-color: var(--indigo-100) !important;
}

.focus\:bg-indigo-200:focus {
  background-color: var(--indigo-200) !important;
}

.focus\:bg-indigo-300:focus {
  background-color: var(--indigo-300) !important;
}

.focus\:bg-indigo-400:focus {
  background-color: var(--indigo-400) !important;
}

.focus\:bg-indigo-500:focus {
  background-color: var(--indigo-500) !important;
}

.focus\:bg-indigo-600:focus {
  background-color: var(--indigo-600) !important;
}

.focus\:bg-indigo-700:focus {
  background-color: var(--indigo-700) !important;
}

.focus\:bg-indigo-800:focus {
  background-color: var(--indigo-800) !important;
}

.focus\:bg-indigo-900:focus {
  background-color: var(--indigo-900) !important;
}

.hover\:bg-indigo-50:hover {
  background-color: var(--indigo-50) !important;
}

.hover\:bg-indigo-100:hover {
  background-color: var(--indigo-100) !important;
}

.hover\:bg-indigo-200:hover {
  background-color: var(--indigo-200) !important;
}

.hover\:bg-indigo-300:hover {
  background-color: var(--indigo-300) !important;
}

.hover\:bg-indigo-400:hover {
  background-color: var(--indigo-400) !important;
}

.hover\:bg-indigo-500:hover {
  background-color: var(--indigo-500) !important;
}

.hover\:bg-indigo-600:hover {
  background-color: var(--indigo-600) !important;
}

.hover\:bg-indigo-700:hover {
  background-color: var(--indigo-700) !important;
}

.hover\:bg-indigo-800:hover {
  background-color: var(--indigo-800) !important;
}

.hover\:bg-indigo-900:hover {
  background-color: var(--indigo-900) !important;
}

.active\:bg-indigo-50:active {
  background-color: var(--indigo-50) !important;
}

.active\:bg-indigo-100:active {
  background-color: var(--indigo-100) !important;
}

.active\:bg-indigo-200:active {
  background-color: var(--indigo-200) !important;
}

.active\:bg-indigo-300:active {
  background-color: var(--indigo-300) !important;
}

.active\:bg-indigo-400:active {
  background-color: var(--indigo-400) !important;
}

.active\:bg-indigo-500:active {
  background-color: var(--indigo-500) !important;
}

.active\:bg-indigo-600:active {
  background-color: var(--indigo-600) !important;
}

.active\:bg-indigo-700:active {
  background-color: var(--indigo-700) !important;
}

.active\:bg-indigo-800:active {
  background-color: var(--indigo-800) !important;
}

.active\:bg-indigo-900:active {
  background-color: var(--indigo-900) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}

.bg-teal-100 {
  background-color: var(--teal-100) !important;
}

.bg-teal-200 {
  background-color: var(--teal-200) !important;
}

.bg-teal-300 {
  background-color: var(--teal-300) !important;
}

.bg-teal-400 {
  background-color: var(--teal-400) !important;
}

.bg-teal-500 {
  background-color: var(--teal-500) !important;
}

.bg-teal-600 {
  background-color: var(--teal-600) !important;
}

.bg-teal-700 {
  background-color: var(--teal-700) !important;
}

.bg-teal-800 {
  background-color: var(--teal-800) !important;
}

.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.focus\:bg-teal-50:focus {
  background-color: var(--teal-50) !important;
}

.focus\:bg-teal-100:focus {
  background-color: var(--teal-100) !important;
}

.focus\:bg-teal-200:focus {
  background-color: var(--teal-200) !important;
}

.focus\:bg-teal-300:focus {
  background-color: var(--teal-300) !important;
}

.focus\:bg-teal-400:focus {
  background-color: var(--teal-400) !important;
}

.focus\:bg-teal-500:focus {
  background-color: var(--teal-500) !important;
}

.focus\:bg-teal-600:focus {
  background-color: var(--teal-600) !important;
}

.focus\:bg-teal-700:focus {
  background-color: var(--teal-700) !important;
}

.focus\:bg-teal-800:focus {
  background-color: var(--teal-800) !important;
}

.focus\:bg-teal-900:focus {
  background-color: var(--teal-900) !important;
}

.hover\:bg-teal-50:hover {
  background-color: var(--teal-50) !important;
}

.hover\:bg-teal-100:hover {
  background-color: var(--teal-100) !important;
}

.hover\:bg-teal-200:hover {
  background-color: var(--teal-200) !important;
}

.hover\:bg-teal-300:hover {
  background-color: var(--teal-300) !important;
}

.hover\:bg-teal-400:hover {
  background-color: var(--teal-400) !important;
}

.hover\:bg-teal-500:hover {
  background-color: var(--teal-500) !important;
}

.hover\:bg-teal-600:hover {
  background-color: var(--teal-600) !important;
}

.hover\:bg-teal-700:hover {
  background-color: var(--teal-700) !important;
}

.hover\:bg-teal-800:hover {
  background-color: var(--teal-800) !important;
}

.hover\:bg-teal-900:hover {
  background-color: var(--teal-900) !important;
}

.active\:bg-teal-50:active {
  background-color: var(--teal-50) !important;
}

.active\:bg-teal-100:active {
  background-color: var(--teal-100) !important;
}

.active\:bg-teal-200:active {
  background-color: var(--teal-200) !important;
}

.active\:bg-teal-300:active {
  background-color: var(--teal-300) !important;
}

.active\:bg-teal-400:active {
  background-color: var(--teal-400) !important;
}

.active\:bg-teal-500:active {
  background-color: var(--teal-500) !important;
}

.active\:bg-teal-600:active {
  background-color: var(--teal-600) !important;
}

.active\:bg-teal-700:active {
  background-color: var(--teal-700) !important;
}

.active\:bg-teal-800:active {
  background-color: var(--teal-800) !important;
}

.active\:bg-teal-900:active {
  background-color: var(--teal-900) !important;
}

.bg-orange-50 {
  background-color: var(--orange-50) !important;
}

.bg-orange-100 {
  background-color: var(--orange-100) !important;
}

.bg-orange-200 {
  background-color: var(--orange-200) !important;
}

.bg-orange-300 {
  background-color: var(--orange-300) !important;
}

.bg-orange-400 {
  background-color: var(--orange-400) !important;
}

.bg-orange-500 {
  background-color: var(--orange-500) !important;
}

.bg-orange-600 {
  background-color: var(--orange-600) !important;
}

.bg-orange-700 {
  background-color: var(--orange-700) !important;
}

.bg-orange-800 {
  background-color: var(--orange-800) !important;
}

.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-50:focus {
  background-color: var(--orange-50) !important;
}

.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}

.focus\:bg-orange-200:focus {
  background-color: var(--orange-200) !important;
}

.focus\:bg-orange-300:focus {
  background-color: var(--orange-300) !important;
}

.focus\:bg-orange-400:focus {
  background-color: var(--orange-400) !important;
}

.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}

.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}

.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}

.focus\:bg-orange-800:focus {
  background-color: var(--orange-800) !important;
}

.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-50:hover {
  background-color: var(--orange-50) !important;
}

.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}

.hover\:bg-orange-200:hover {
  background-color: var(--orange-200) !important;
}

.hover\:bg-orange-300:hover {
  background-color: var(--orange-300) !important;
}

.hover\:bg-orange-400:hover {
  background-color: var(--orange-400) !important;
}

.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}

.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}

.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}

.hover\:bg-orange-800:hover {
  background-color: var(--orange-800) !important;
}

.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-50:active {
  background-color: var(--orange-50) !important;
}

.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}

.active\:bg-orange-200:active {
  background-color: var(--orange-200) !important;
}

.active\:bg-orange-300:active {
  background-color: var(--orange-300) !important;
}

.active\:bg-orange-400:active {
  background-color: var(--orange-400) !important;
}

.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}

.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}

.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}

.active\:bg-orange-800:active {
  background-color: var(--orange-800) !important;
}

.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.bg-bluegray-50 {
  background-color: var(--bluegray-50) !important;
}

.bg-bluegray-100 {
  background-color: var(--bluegray-100) !important;
}

.bg-bluegray-200 {
  background-color: var(--bluegray-200) !important;
}

.bg-bluegray-300 {
  background-color: var(--bluegray-300) !important;
}

.bg-bluegray-400 {
  background-color: var(--bluegray-400) !important;
}

.bg-bluegray-500 {
  background-color: var(--bluegray-500) !important;
}

.bg-bluegray-600 {
  background-color: var(--bluegray-600) !important;
}

.bg-bluegray-700 {
  background-color: var(--bluegray-700) !important;
}

.bg-bluegray-800 {
  background-color: var(--bluegray-800) !important;
}

.bg-bluegray-900 {
  background-color: var(--bluegray-900) !important;
}

.focus\:bg-bluegray-50:focus {
  background-color: var(--bluegray-50) !important;
}

.focus\:bg-bluegray-100:focus {
  background-color: var(--bluegray-100) !important;
}

.focus\:bg-bluegray-200:focus {
  background-color: var(--bluegray-200) !important;
}

.focus\:bg-bluegray-300:focus {
  background-color: var(--bluegray-300) !important;
}

.focus\:bg-bluegray-400:focus {
  background-color: var(--bluegray-400) !important;
}

.focus\:bg-bluegray-500:focus {
  background-color: var(--bluegray-500) !important;
}

.focus\:bg-bluegray-600:focus {
  background-color: var(--bluegray-600) !important;
}

.focus\:bg-bluegray-700:focus {
  background-color: var(--bluegray-700) !important;
}

.focus\:bg-bluegray-800:focus {
  background-color: var(--bluegray-800) !important;
}

.focus\:bg-bluegray-900:focus {
  background-color: var(--bluegray-900) !important;
}

.hover\:bg-bluegray-50:hover {
  background-color: var(--bluegray-50) !important;
}

.hover\:bg-bluegray-100:hover {
  background-color: var(--bluegray-100) !important;
}

.hover\:bg-bluegray-200:hover {
  background-color: var(--bluegray-200) !important;
}

.hover\:bg-bluegray-300:hover {
  background-color: var(--bluegray-300) !important;
}

.hover\:bg-bluegray-400:hover {
  background-color: var(--bluegray-400) !important;
}

.hover\:bg-bluegray-500:hover {
  background-color: var(--bluegray-500) !important;
}

.hover\:bg-bluegray-600:hover {
  background-color: var(--bluegray-600) !important;
}

.hover\:bg-bluegray-700:hover {
  background-color: var(--bluegray-700) !important;
}

.hover\:bg-bluegray-800:hover {
  background-color: var(--bluegray-800) !important;
}

.hover\:bg-bluegray-900:hover {
  background-color: var(--bluegray-900) !important;
}

.active\:bg-bluegray-50:active {
  background-color: var(--bluegray-50) !important;
}

.active\:bg-bluegray-100:active {
  background-color: var(--bluegray-100) !important;
}

.active\:bg-bluegray-200:active {
  background-color: var(--bluegray-200) !important;
}

.active\:bg-bluegray-300:active {
  background-color: var(--bluegray-300) !important;
}

.active\:bg-bluegray-400:active {
  background-color: var(--bluegray-400) !important;
}

.active\:bg-bluegray-500:active {
  background-color: var(--bluegray-500) !important;
}

.active\:bg-bluegray-600:active {
  background-color: var(--bluegray-600) !important;
}

.active\:bg-bluegray-700:active {
  background-color: var(--bluegray-700) !important;
}

.active\:bg-bluegray-800:active {
  background-color: var(--bluegray-800) !important;
}

.active\:bg-bluegray-900:active {
  background-color: var(--bluegray-900) !important;
}

.bg-purple-50 {
  background-color: var(--purple-50) !important;
}

.bg-purple-100 {
  background-color: var(--purple-100) !important;
}

.bg-purple-200 {
  background-color: var(--purple-200) !important;
}

.bg-purple-300 {
  background-color: var(--purple-300) !important;
}

.bg-purple-400 {
  background-color: var(--purple-400) !important;
}

.bg-purple-500 {
  background-color: var(--purple-500) !important;
}

.bg-purple-600 {
  background-color: var(--purple-600) !important;
}

.bg-purple-700 {
  background-color: var(--purple-700) !important;
}

.bg-purple-800 {
  background-color: var(--purple-800) !important;
}

.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-50:focus {
  background-color: var(--purple-50) !important;
}

.focus\:bg-purple-100:focus {
  background-color: var(--purple-100) !important;
}

.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}

.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}

.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}

.focus\:bg-purple-500:focus {
  background-color: var(--purple-500) !important;
}

.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}

.focus\:bg-purple-700:focus {
  background-color: var(--purple-700) !important;
}

.focus\:bg-purple-800:focus {
  background-color: var(--purple-800) !important;
}

.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-50:hover {
  background-color: var(--purple-50) !important;
}

.hover\:bg-purple-100:hover {
  background-color: var(--purple-100) !important;
}

.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}

.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}

.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}

.hover\:bg-purple-500:hover {
  background-color: var(--purple-500) !important;
}

.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}

.hover\:bg-purple-700:hover {
  background-color: var(--purple-700) !important;
}

.hover\:bg-purple-800:hover {
  background-color: var(--purple-800) !important;
}

.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-50:active {
  background-color: var(--purple-50) !important;
}

.active\:bg-purple-100:active {
  background-color: var(--purple-100) !important;
}

.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}

.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}

.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}

.active\:bg-purple-500:active {
  background-color: var(--purple-500) !important;
}

.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}

.active\:bg-purple-700:active {
  background-color: var(--purple-700) !important;
}

.active\:bg-purple-800:active {
  background-color: var(--purple-800) !important;
}

.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.bg-gray-50 {
  background-color: var(--gray-50) !important;
}

.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--gray-800) !important;
}

.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-50:focus {
  background-color: var(--gray-50) !important;
}

.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}

.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}

.focus\:bg-gray-300:focus {
  background-color: var(--gray-300) !important;
}

.focus\:bg-gray-400:focus {
  background-color: var(--gray-400) !important;
}

.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}

.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}

.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}

.focus\:bg-gray-800:focus {
  background-color: var(--gray-800) !important;
}

.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-50:hover {
  background-color: var(--gray-50) !important;
}

.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}

.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}

.hover\:bg-gray-300:hover {
  background-color: var(--gray-300) !important;
}

.hover\:bg-gray-400:hover {
  background-color: var(--gray-400) !important;
}

.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}

.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}

.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}

.hover\:bg-gray-800:hover {
  background-color: var(--gray-800) !important;
}

.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-50:active {
  background-color: var(--gray-50) !important;
}

.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}

.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}

.active\:bg-gray-300:active {
  background-color: var(--gray-300) !important;
}

.active\:bg-gray-400:active {
  background-color: var(--gray-400) !important;
}

.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}

.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}

.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}

.active\:bg-gray-800:active {
  background-color: var(--gray-800) !important;
}

.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.bg-red-50 {
  background-color: var(--red-50) !important;
}

.bg-red-100 {
  background-color: var(--red-100) !important;
}

.bg-red-200 {
  background-color: var(--red-200) !important;
}

.bg-red-300 {
  background-color: var(--red-300) !important;
}

.bg-red-400 {
  background-color: var(--red-400) !important;
}

.bg-red-500 {
  background-color: var(--red-500) !important;
}

.bg-red-600 {
  background-color: var(--red-600) !important;
}

.bg-red-700 {
  background-color: var(--red-700) !important;
}

.bg-red-800 {
  background-color: var(--red-800) !important;
}

.bg-red-900 {
  background-color: var(--red-900) !important;
}

.focus\:bg-red-50:focus {
  background-color: var(--red-50) !important;
}

.focus\:bg-red-100:focus {
  background-color: var(--red-100) !important;
}

.focus\:bg-red-200:focus {
  background-color: var(--red-200) !important;
}

.focus\:bg-red-300:focus {
  background-color: var(--red-300) !important;
}

.focus\:bg-red-400:focus {
  background-color: var(--red-400) !important;
}

.focus\:bg-red-500:focus {
  background-color: var(--red-500) !important;
}

.focus\:bg-red-600:focus {
  background-color: var(--red-600) !important;
}

.focus\:bg-red-700:focus {
  background-color: var(--red-700) !important;
}

.focus\:bg-red-800:focus {
  background-color: var(--red-800) !important;
}

.focus\:bg-red-900:focus {
  background-color: var(--red-900) !important;
}

.hover\:bg-red-50:hover {
  background-color: var(--red-50) !important;
}

.hover\:bg-red-100:hover {
  background-color: var(--red-100) !important;
}

.hover\:bg-red-200:hover {
  background-color: var(--red-200) !important;
}

.hover\:bg-red-300:hover {
  background-color: var(--red-300) !important;
}

.hover\:bg-red-400:hover {
  background-color: var(--red-400) !important;
}

.hover\:bg-red-500:hover {
  background-color: var(--red-500) !important;
}

.hover\:bg-red-600:hover {
  background-color: var(--red-600) !important;
}

.hover\:bg-red-700:hover {
  background-color: var(--red-700) !important;
}

.hover\:bg-red-800:hover {
  background-color: var(--red-800) !important;
}

.hover\:bg-red-900:hover {
  background-color: var(--red-900) !important;
}

.active\:bg-red-50:active {
  background-color: var(--red-50) !important;
}

.active\:bg-red-100:active {
  background-color: var(--red-100) !important;
}

.active\:bg-red-200:active {
  background-color: var(--red-200) !important;
}

.active\:bg-red-300:active {
  background-color: var(--red-300) !important;
}

.active\:bg-red-400:active {
  background-color: var(--red-400) !important;
}

.active\:bg-red-500:active {
  background-color: var(--red-500) !important;
}

.active\:bg-red-600:active {
  background-color: var(--red-600) !important;
}

.active\:bg-red-700:active {
  background-color: var(--red-700) !important;
}

.active\:bg-red-800:active {
  background-color: var(--red-800) !important;
}

.active\:bg-red-900:active {
  background-color: var(--red-900) !important;
}

.bg-primary-50 {
  background-color: var(--primary-50) !important;
}

.bg-primary-100 {
  background-color: var(--primary-100) !important;
}

.bg-primary-200 {
  background-color: var(--primary-200) !important;
}

.bg-primary-300 {
  background-color: var(--primary-300) !important;
}

.bg-primary-400 {
  background-color: var(--primary-400) !important;
}

.bg-primary-500 {
  background-color: var(--primary-500) !important;
}

.bg-primary-600 {
  background-color: var(--primary-600) !important;
}

.bg-primary-700 {
  background-color: var(--primary-700) !important;
}

.bg-primary-800 {
  background-color: var(--primary-800) !important;
}

.bg-primary-900 {
  background-color: var(--primary-900) !important;
}

.focus\:bg-primary-50:focus {
  background-color: var(--primary-50) !important;
}

.focus\:bg-primary-100:focus {
  background-color: var(--primary-100) !important;
}

.focus\:bg-primary-200:focus {
  background-color: var(--primary-200) !important;
}

.focus\:bg-primary-300:focus {
  background-color: var(--primary-300) !important;
}

.focus\:bg-primary-400:focus {
  background-color: var(--primary-400) !important;
}

.focus\:bg-primary-500:focus {
  background-color: var(--primary-500) !important;
}

.focus\:bg-primary-600:focus {
  background-color: var(--primary-600) !important;
}

.focus\:bg-primary-700:focus {
  background-color: var(--primary-700) !important;
}

.focus\:bg-primary-800:focus {
  background-color: var(--primary-800) !important;
}

.focus\:bg-primary-900:focus {
  background-color: var(--primary-900) !important;
}

.hover\:bg-primary-50:hover {
  background-color: var(--primary-50) !important;
}

.hover\:bg-primary-100:hover {
  background-color: var(--primary-100) !important;
}

.hover\:bg-primary-200:hover {
  background-color: var(--primary-200) !important;
}

.hover\:bg-primary-300:hover {
  background-color: var(--primary-300) !important;
}

.hover\:bg-primary-400:hover {
  background-color: var(--primary-400) !important;
}

.hover\:bg-primary-500:hover {
  background-color: var(--primary-500) !important;
}

.hover\:bg-primary-600:hover {
  background-color: var(--primary-600) !important;
}

.hover\:bg-primary-700:hover {
  background-color: var(--primary-700) !important;
}

.hover\:bg-primary-800:hover {
  background-color: var(--primary-800) !important;
}

.hover\:bg-primary-900:hover {
  background-color: var(--primary-900) !important;
}

.active\:bg-primary-50:active {
  background-color: var(--primary-50) !important;
}

.active\:bg-primary-100:active {
  background-color: var(--primary-100) !important;
}

.active\:bg-primary-200:active {
  background-color: var(--primary-200) !important;
}

.active\:bg-primary-300:active {
  background-color: var(--primary-300) !important;
}

.active\:bg-primary-400:active {
  background-color: var(--primary-400) !important;
}

.active\:bg-primary-500:active {
  background-color: var(--primary-500) !important;
}

.active\:bg-primary-600:active {
  background-color: var(--primary-600) !important;
}

.active\:bg-primary-700:active {
  background-color: var(--primary-700) !important;
}

.active\:bg-primary-800:active {
  background-color: var(--primary-800) !important;
}

.active\:bg-primary-900:active {
  background-color: var(--primary-900) !important;
}

.border-blue-50 {
  border-color: var(--blue-50) !important;
}

.border-blue-100 {
  border-color: var(--blue-100) !important;
}

.border-blue-200 {
  border-color: var(--blue-200) !important;
}

.border-blue-300 {
  border-color: var(--blue-300) !important;
}

.border-blue-400 {
  border-color: var(--blue-400) !important;
}

.border-blue-500 {
  border-color: var(--blue-500) !important;
}

.border-blue-600 {
  border-color: var(--blue-600) !important;
}

.border-blue-700 {
  border-color: var(--blue-700) !important;
}

.border-blue-800 {
  border-color: var(--blue-800) !important;
}

.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-50:focus {
  border-color: var(--blue-50) !important;
}

.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}

.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}

.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}

.focus\:border-blue-400:focus {
  border-color: var(--blue-400) !important;
}

.focus\:border-blue-500:focus {
  border-color: var(--blue-500) !important;
}

.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}

.focus\:border-blue-700:focus {
  border-color: var(--blue-700) !important;
}

.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}

.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-50:hover {
  border-color: var(--blue-50) !important;
}

.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}

.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}

.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}

.hover\:border-blue-400:hover {
  border-color: var(--blue-400) !important;
}

.hover\:border-blue-500:hover {
  border-color: var(--blue-500) !important;
}

.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}

.hover\:border-blue-700:hover {
  border-color: var(--blue-700) !important;
}

.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}

.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-50:active {
  border-color: var(--blue-50) !important;
}

.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}

.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}

.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}

.active\:border-blue-400:active {
  border-color: var(--blue-400) !important;
}

.active\:border-blue-500:active {
  border-color: var(--blue-500) !important;
}

.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}

.active\:border-blue-700:active {
  border-color: var(--blue-700) !important;
}

.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}

.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.border-green-50 {
  border-color: var(--green-50) !important;
}

.border-green-100 {
  border-color: var(--green-100) !important;
}

.border-green-200 {
  border-color: var(--green-200) !important;
}

.border-green-300 {
  border-color: var(--green-300) !important;
}

.border-green-400 {
  border-color: var(--green-400) !important;
}

.border-green-500 {
  border-color: var(--green-500) !important;
}

.border-green-600 {
  border-color: var(--green-600) !important;
}

.border-green-700 {
  border-color: var(--green-700) !important;
}

.border-green-800 {
  border-color: var(--green-800) !important;
}

.border-green-900 {
  border-color: var(--green-900) !important;
}

.focus\:border-green-50:focus {
  border-color: var(--green-50) !important;
}

.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}

.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}

.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}

.focus\:border-green-400:focus {
  border-color: var(--green-400) !important;
}

.focus\:border-green-500:focus {
  border-color: var(--green-500) !important;
}

.focus\:border-green-600:focus {
  border-color: var(--green-600) !important;
}

.focus\:border-green-700:focus {
  border-color: var(--green-700) !important;
}

.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}

.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-50:hover {
  border-color: var(--green-50) !important;
}

.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}

.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}

.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}

.hover\:border-green-400:hover {
  border-color: var(--green-400) !important;
}

.hover\:border-green-500:hover {
  border-color: var(--green-500) !important;
}

.hover\:border-green-600:hover {
  border-color: var(--green-600) !important;
}

.hover\:border-green-700:hover {
  border-color: var(--green-700) !important;
}

.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}

.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-50:active {
  border-color: var(--green-50) !important;
}

.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}

.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}

.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}

.active\:border-green-400:active {
  border-color: var(--green-400) !important;
}

.active\:border-green-500:active {
  border-color: var(--green-500) !important;
}

.active\:border-green-600:active {
  border-color: var(--green-600) !important;
}

.active\:border-green-700:active {
  border-color: var(--green-700) !important;
}

.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}

.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}

.border-yellow-100 {
  border-color: var(--yellow-100) !important;
}

.border-yellow-200 {
  border-color: var(--yellow-200) !important;
}

.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}

.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}

.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}

.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}

.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}

.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}

.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-50:focus {
  border-color: var(--yellow-50) !important;
}

.focus\:border-yellow-100:focus {
  border-color: var(--yellow-100) !important;
}

.focus\:border-yellow-200:focus {
  border-color: var(--yellow-200) !important;
}

.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}

.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}

.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}

.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}

.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}

.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}

.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-50:hover {
  border-color: var(--yellow-50) !important;
}

.hover\:border-yellow-100:hover {
  border-color: var(--yellow-100) !important;
}

.hover\:border-yellow-200:hover {
  border-color: var(--yellow-200) !important;
}

.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}

.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}

.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}

.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}

.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}

.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}

.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-50:active {
  border-color: var(--yellow-50) !important;
}

.active\:border-yellow-100:active {
  border-color: var(--yellow-100) !important;
}

.active\:border-yellow-200:active {
  border-color: var(--yellow-200) !important;
}

.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}

.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}

.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}

.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}

.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}

.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}

.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}

.border-cyan-100 {
  border-color: var(--cyan-100) !important;
}

.border-cyan-200 {
  border-color: var(--cyan-200) !important;
}

.border-cyan-300 {
  border-color: var(--cyan-300) !important;
}

.border-cyan-400 {
  border-color: var(--cyan-400) !important;
}

.border-cyan-500 {
  border-color: var(--cyan-500) !important;
}

.border-cyan-600 {
  border-color: var(--cyan-600) !important;
}

.border-cyan-700 {
  border-color: var(--cyan-700) !important;
}

.border-cyan-800 {
  border-color: var(--cyan-800) !important;
}

.border-cyan-900 {
  border-color: var(--cyan-900) !important;
}

.focus\:border-cyan-50:focus {
  border-color: var(--cyan-50) !important;
}

.focus\:border-cyan-100:focus {
  border-color: var(--cyan-100) !important;
}

.focus\:border-cyan-200:focus {
  border-color: var(--cyan-200) !important;
}

.focus\:border-cyan-300:focus {
  border-color: var(--cyan-300) !important;
}

.focus\:border-cyan-400:focus {
  border-color: var(--cyan-400) !important;
}

.focus\:border-cyan-500:focus {
  border-color: var(--cyan-500) !important;
}

.focus\:border-cyan-600:focus {
  border-color: var(--cyan-600) !important;
}

.focus\:border-cyan-700:focus {
  border-color: var(--cyan-700) !important;
}

.focus\:border-cyan-800:focus {
  border-color: var(--cyan-800) !important;
}

.focus\:border-cyan-900:focus {
  border-color: var(--cyan-900) !important;
}

.hover\:border-cyan-50:hover {
  border-color: var(--cyan-50) !important;
}

.hover\:border-cyan-100:hover {
  border-color: var(--cyan-100) !important;
}

.hover\:border-cyan-200:hover {
  border-color: var(--cyan-200) !important;
}

.hover\:border-cyan-300:hover {
  border-color: var(--cyan-300) !important;
}

.hover\:border-cyan-400:hover {
  border-color: var(--cyan-400) !important;
}

.hover\:border-cyan-500:hover {
  border-color: var(--cyan-500) !important;
}

.hover\:border-cyan-600:hover {
  border-color: var(--cyan-600) !important;
}

.hover\:border-cyan-700:hover {
  border-color: var(--cyan-700) !important;
}

.hover\:border-cyan-800:hover {
  border-color: var(--cyan-800) !important;
}

.hover\:border-cyan-900:hover {
  border-color: var(--cyan-900) !important;
}

.active\:border-cyan-50:active {
  border-color: var(--cyan-50) !important;
}

.active\:border-cyan-100:active {
  border-color: var(--cyan-100) !important;
}

.active\:border-cyan-200:active {
  border-color: var(--cyan-200) !important;
}

.active\:border-cyan-300:active {
  border-color: var(--cyan-300) !important;
}

.active\:border-cyan-400:active {
  border-color: var(--cyan-400) !important;
}

.active\:border-cyan-500:active {
  border-color: var(--cyan-500) !important;
}

.active\:border-cyan-600:active {
  border-color: var(--cyan-600) !important;
}

.active\:border-cyan-700:active {
  border-color: var(--cyan-700) !important;
}

.active\:border-cyan-800:active {
  border-color: var(--cyan-800) !important;
}

.active\:border-cyan-900:active {
  border-color: var(--cyan-900) !important;
}

.border-pink-50 {
  border-color: var(--pink-50) !important;
}

.border-pink-100 {
  border-color: var(--pink-100) !important;
}

.border-pink-200 {
  border-color: var(--pink-200) !important;
}

.border-pink-300 {
  border-color: var(--pink-300) !important;
}

.border-pink-400 {
  border-color: var(--pink-400) !important;
}

.border-pink-500 {
  border-color: var(--pink-500) !important;
}

.border-pink-600 {
  border-color: var(--pink-600) !important;
}

.border-pink-700 {
  border-color: var(--pink-700) !important;
}

.border-pink-800 {
  border-color: var(--pink-800) !important;
}

.border-pink-900 {
  border-color: var(--pink-900) !important;
}

.focus\:border-pink-50:focus {
  border-color: var(--pink-50) !important;
}

.focus\:border-pink-100:focus {
  border-color: var(--pink-100) !important;
}

.focus\:border-pink-200:focus {
  border-color: var(--pink-200) !important;
}

.focus\:border-pink-300:focus {
  border-color: var(--pink-300) !important;
}

.focus\:border-pink-400:focus {
  border-color: var(--pink-400) !important;
}

.focus\:border-pink-500:focus {
  border-color: var(--pink-500) !important;
}

.focus\:border-pink-600:focus {
  border-color: var(--pink-600) !important;
}

.focus\:border-pink-700:focus {
  border-color: var(--pink-700) !important;
}

.focus\:border-pink-800:focus {
  border-color: var(--pink-800) !important;
}

.focus\:border-pink-900:focus {
  border-color: var(--pink-900) !important;
}

.hover\:border-pink-50:hover {
  border-color: var(--pink-50) !important;
}

.hover\:border-pink-100:hover {
  border-color: var(--pink-100) !important;
}

.hover\:border-pink-200:hover {
  border-color: var(--pink-200) !important;
}

.hover\:border-pink-300:hover {
  border-color: var(--pink-300) !important;
}

.hover\:border-pink-400:hover {
  border-color: var(--pink-400) !important;
}

.hover\:border-pink-500:hover {
  border-color: var(--pink-500) !important;
}

.hover\:border-pink-600:hover {
  border-color: var(--pink-600) !important;
}

.hover\:border-pink-700:hover {
  border-color: var(--pink-700) !important;
}

.hover\:border-pink-800:hover {
  border-color: var(--pink-800) !important;
}

.hover\:border-pink-900:hover {
  border-color: var(--pink-900) !important;
}

.active\:border-pink-50:active {
  border-color: var(--pink-50) !important;
}

.active\:border-pink-100:active {
  border-color: var(--pink-100) !important;
}

.active\:border-pink-200:active {
  border-color: var(--pink-200) !important;
}

.active\:border-pink-300:active {
  border-color: var(--pink-300) !important;
}

.active\:border-pink-400:active {
  border-color: var(--pink-400) !important;
}

.active\:border-pink-500:active {
  border-color: var(--pink-500) !important;
}

.active\:border-pink-600:active {
  border-color: var(--pink-600) !important;
}

.active\:border-pink-700:active {
  border-color: var(--pink-700) !important;
}

.active\:border-pink-800:active {
  border-color: var(--pink-800) !important;
}

.active\:border-pink-900:active {
  border-color: var(--pink-900) !important;
}

.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}

.border-indigo-100 {
  border-color: var(--indigo-100) !important;
}

.border-indigo-200 {
  border-color: var(--indigo-200) !important;
}

.border-indigo-300 {
  border-color: var(--indigo-300) !important;
}

.border-indigo-400 {
  border-color: var(--indigo-400) !important;
}

.border-indigo-500 {
  border-color: var(--indigo-500) !important;
}

.border-indigo-600 {
  border-color: var(--indigo-600) !important;
}

.border-indigo-700 {
  border-color: var(--indigo-700) !important;
}

.border-indigo-800 {
  border-color: var(--indigo-800) !important;
}

.border-indigo-900 {
  border-color: var(--indigo-900) !important;
}

.focus\:border-indigo-50:focus {
  border-color: var(--indigo-50) !important;
}

.focus\:border-indigo-100:focus {
  border-color: var(--indigo-100) !important;
}

.focus\:border-indigo-200:focus {
  border-color: var(--indigo-200) !important;
}

.focus\:border-indigo-300:focus {
  border-color: var(--indigo-300) !important;
}

.focus\:border-indigo-400:focus {
  border-color: var(--indigo-400) !important;
}

.focus\:border-indigo-500:focus {
  border-color: var(--indigo-500) !important;
}

.focus\:border-indigo-600:focus {
  border-color: var(--indigo-600) !important;
}

.focus\:border-indigo-700:focus {
  border-color: var(--indigo-700) !important;
}

.focus\:border-indigo-800:focus {
  border-color: var(--indigo-800) !important;
}

.focus\:border-indigo-900:focus {
  border-color: var(--indigo-900) !important;
}

.hover\:border-indigo-50:hover {
  border-color: var(--indigo-50) !important;
}

.hover\:border-indigo-100:hover {
  border-color: var(--indigo-100) !important;
}

.hover\:border-indigo-200:hover {
  border-color: var(--indigo-200) !important;
}

.hover\:border-indigo-300:hover {
  border-color: var(--indigo-300) !important;
}

.hover\:border-indigo-400:hover {
  border-color: var(--indigo-400) !important;
}

.hover\:border-indigo-500:hover {
  border-color: var(--indigo-500) !important;
}

.hover\:border-indigo-600:hover {
  border-color: var(--indigo-600) !important;
}

.hover\:border-indigo-700:hover {
  border-color: var(--indigo-700) !important;
}

.hover\:border-indigo-800:hover {
  border-color: var(--indigo-800) !important;
}

.hover\:border-indigo-900:hover {
  border-color: var(--indigo-900) !important;
}

.active\:border-indigo-50:active {
  border-color: var(--indigo-50) !important;
}

.active\:border-indigo-100:active {
  border-color: var(--indigo-100) !important;
}

.active\:border-indigo-200:active {
  border-color: var(--indigo-200) !important;
}

.active\:border-indigo-300:active {
  border-color: var(--indigo-300) !important;
}

.active\:border-indigo-400:active {
  border-color: var(--indigo-400) !important;
}

.active\:border-indigo-500:active {
  border-color: var(--indigo-500) !important;
}

.active\:border-indigo-600:active {
  border-color: var(--indigo-600) !important;
}

.active\:border-indigo-700:active {
  border-color: var(--indigo-700) !important;
}

.active\:border-indigo-800:active {
  border-color: var(--indigo-800) !important;
}

.active\:border-indigo-900:active {
  border-color: var(--indigo-900) !important;
}

.border-teal-50 {
  border-color: var(--teal-50) !important;
}

.border-teal-100 {
  border-color: var(--teal-100) !important;
}

.border-teal-200 {
  border-color: var(--teal-200) !important;
}

.border-teal-300 {
  border-color: var(--teal-300) !important;
}

.border-teal-400 {
  border-color: var(--teal-400) !important;
}

.border-teal-500 {
  border-color: var(--teal-500) !important;
}

.border-teal-600 {
  border-color: var(--teal-600) !important;
}

.border-teal-700 {
  border-color: var(--teal-700) !important;
}

.border-teal-800 {
  border-color: var(--teal-800) !important;
}

.border-teal-900 {
  border-color: var(--teal-900) !important;
}

.focus\:border-teal-50:focus {
  border-color: var(--teal-50) !important;
}

.focus\:border-teal-100:focus {
  border-color: var(--teal-100) !important;
}

.focus\:border-teal-200:focus {
  border-color: var(--teal-200) !important;
}

.focus\:border-teal-300:focus {
  border-color: var(--teal-300) !important;
}

.focus\:border-teal-400:focus {
  border-color: var(--teal-400) !important;
}

.focus\:border-teal-500:focus {
  border-color: var(--teal-500) !important;
}

.focus\:border-teal-600:focus {
  border-color: var(--teal-600) !important;
}

.focus\:border-teal-700:focus {
  border-color: var(--teal-700) !important;
}

.focus\:border-teal-800:focus {
  border-color: var(--teal-800) !important;
}

.focus\:border-teal-900:focus {
  border-color: var(--teal-900) !important;
}

.hover\:border-teal-50:hover {
  border-color: var(--teal-50) !important;
}

.hover\:border-teal-100:hover {
  border-color: var(--teal-100) !important;
}

.hover\:border-teal-200:hover {
  border-color: var(--teal-200) !important;
}

.hover\:border-teal-300:hover {
  border-color: var(--teal-300) !important;
}

.hover\:border-teal-400:hover {
  border-color: var(--teal-400) !important;
}

.hover\:border-teal-500:hover {
  border-color: var(--teal-500) !important;
}

.hover\:border-teal-600:hover {
  border-color: var(--teal-600) !important;
}

.hover\:border-teal-700:hover {
  border-color: var(--teal-700) !important;
}

.hover\:border-teal-800:hover {
  border-color: var(--teal-800) !important;
}

.hover\:border-teal-900:hover {
  border-color: var(--teal-900) !important;
}

.active\:border-teal-50:active {
  border-color: var(--teal-50) !important;
}

.active\:border-teal-100:active {
  border-color: var(--teal-100) !important;
}

.active\:border-teal-200:active {
  border-color: var(--teal-200) !important;
}

.active\:border-teal-300:active {
  border-color: var(--teal-300) !important;
}

.active\:border-teal-400:active {
  border-color: var(--teal-400) !important;
}

.active\:border-teal-500:active {
  border-color: var(--teal-500) !important;
}

.active\:border-teal-600:active {
  border-color: var(--teal-600) !important;
}

.active\:border-teal-700:active {
  border-color: var(--teal-700) !important;
}

.active\:border-teal-800:active {
  border-color: var(--teal-800) !important;
}

.active\:border-teal-900:active {
  border-color: var(--teal-900) !important;
}

.border-orange-50 {
  border-color: var(--orange-50) !important;
}

.border-orange-100 {
  border-color: var(--orange-100) !important;
}

.border-orange-200 {
  border-color: var(--orange-200) !important;
}

.border-orange-300 {
  border-color: var(--orange-300) !important;
}

.border-orange-400 {
  border-color: var(--orange-400) !important;
}

.border-orange-500 {
  border-color: var(--orange-500) !important;
}

.border-orange-600 {
  border-color: var(--orange-600) !important;
}

.border-orange-700 {
  border-color: var(--orange-700) !important;
}

.border-orange-800 {
  border-color: var(--orange-800) !important;
}

.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-50:focus {
  border-color: var(--orange-50) !important;
}

.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}

.focus\:border-orange-200:focus {
  border-color: var(--orange-200) !important;
}

.focus\:border-orange-300:focus {
  border-color: var(--orange-300) !important;
}

.focus\:border-orange-400:focus {
  border-color: var(--orange-400) !important;
}

.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}

.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}

.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}

.focus\:border-orange-800:focus {
  border-color: var(--orange-800) !important;
}

.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-50:hover {
  border-color: var(--orange-50) !important;
}

.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}

.hover\:border-orange-200:hover {
  border-color: var(--orange-200) !important;
}

.hover\:border-orange-300:hover {
  border-color: var(--orange-300) !important;
}

.hover\:border-orange-400:hover {
  border-color: var(--orange-400) !important;
}

.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}

.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}

.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}

.hover\:border-orange-800:hover {
  border-color: var(--orange-800) !important;
}

.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-50:active {
  border-color: var(--orange-50) !important;
}

.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}

.active\:border-orange-200:active {
  border-color: var(--orange-200) !important;
}

.active\:border-orange-300:active {
  border-color: var(--orange-300) !important;
}

.active\:border-orange-400:active {
  border-color: var(--orange-400) !important;
}

.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}

.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}

.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}

.active\:border-orange-800:active {
  border-color: var(--orange-800) !important;
}

.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.border-bluegray-50 {
  border-color: var(--bluegray-50) !important;
}

.border-bluegray-100 {
  border-color: var(--bluegray-100) !important;
}

.border-bluegray-200 {
  border-color: var(--bluegray-200) !important;
}

.border-bluegray-300 {
  border-color: var(--bluegray-300) !important;
}

.border-bluegray-400 {
  border-color: var(--bluegray-400) !important;
}

.border-bluegray-500 {
  border-color: var(--bluegray-500) !important;
}

.border-bluegray-600 {
  border-color: var(--bluegray-600) !important;
}

.border-bluegray-700 {
  border-color: var(--bluegray-700) !important;
}

.border-bluegray-800 {
  border-color: var(--bluegray-800) !important;
}

.border-bluegray-900 {
  border-color: var(--bluegray-900) !important;
}

.focus\:border-bluegray-50:focus {
  border-color: var(--bluegray-50) !important;
}

.focus\:border-bluegray-100:focus {
  border-color: var(--bluegray-100) !important;
}

.focus\:border-bluegray-200:focus {
  border-color: var(--bluegray-200) !important;
}

.focus\:border-bluegray-300:focus {
  border-color: var(--bluegray-300) !important;
}

.focus\:border-bluegray-400:focus {
  border-color: var(--bluegray-400) !important;
}

.focus\:border-bluegray-500:focus {
  border-color: var(--bluegray-500) !important;
}

.focus\:border-bluegray-600:focus {
  border-color: var(--bluegray-600) !important;
}

.focus\:border-bluegray-700:focus {
  border-color: var(--bluegray-700) !important;
}

.focus\:border-bluegray-800:focus {
  border-color: var(--bluegray-800) !important;
}

.focus\:border-bluegray-900:focus {
  border-color: var(--bluegray-900) !important;
}

.hover\:border-bluegray-50:hover {
  border-color: var(--bluegray-50) !important;
}

.hover\:border-bluegray-100:hover {
  border-color: var(--bluegray-100) !important;
}

.hover\:border-bluegray-200:hover {
  border-color: var(--bluegray-200) !important;
}

.hover\:border-bluegray-300:hover {
  border-color: var(--bluegray-300) !important;
}

.hover\:border-bluegray-400:hover {
  border-color: var(--bluegray-400) !important;
}

.hover\:border-bluegray-500:hover {
  border-color: var(--bluegray-500) !important;
}

.hover\:border-bluegray-600:hover {
  border-color: var(--bluegray-600) !important;
}

.hover\:border-bluegray-700:hover {
  border-color: var(--bluegray-700) !important;
}

.hover\:border-bluegray-800:hover {
  border-color: var(--bluegray-800) !important;
}

.hover\:border-bluegray-900:hover {
  border-color: var(--bluegray-900) !important;
}

.active\:border-bluegray-50:active {
  border-color: var(--bluegray-50) !important;
}

.active\:border-bluegray-100:active {
  border-color: var(--bluegray-100) !important;
}

.active\:border-bluegray-200:active {
  border-color: var(--bluegray-200) !important;
}

.active\:border-bluegray-300:active {
  border-color: var(--bluegray-300) !important;
}

.active\:border-bluegray-400:active {
  border-color: var(--bluegray-400) !important;
}

.active\:border-bluegray-500:active {
  border-color: var(--bluegray-500) !important;
}

.active\:border-bluegray-600:active {
  border-color: var(--bluegray-600) !important;
}

.active\:border-bluegray-700:active {
  border-color: var(--bluegray-700) !important;
}

.active\:border-bluegray-800:active {
  border-color: var(--bluegray-800) !important;
}

.active\:border-bluegray-900:active {
  border-color: var(--bluegray-900) !important;
}

.border-purple-50 {
  border-color: var(--purple-50) !important;
}

.border-purple-100 {
  border-color: var(--purple-100) !important;
}

.border-purple-200 {
  border-color: var(--purple-200) !important;
}

.border-purple-300 {
  border-color: var(--purple-300) !important;
}

.border-purple-400 {
  border-color: var(--purple-400) !important;
}

.border-purple-500 {
  border-color: var(--purple-500) !important;
}

.border-purple-600 {
  border-color: var(--purple-600) !important;
}

.border-purple-700 {
  border-color: var(--purple-700) !important;
}

.border-purple-800 {
  border-color: var(--purple-800) !important;
}

.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-50:focus {
  border-color: var(--purple-50) !important;
}

.focus\:border-purple-100:focus {
  border-color: var(--purple-100) !important;
}

.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}

.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}

.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}

.focus\:border-purple-500:focus {
  border-color: var(--purple-500) !important;
}

.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}

.focus\:border-purple-700:focus {
  border-color: var(--purple-700) !important;
}

.focus\:border-purple-800:focus {
  border-color: var(--purple-800) !important;
}

.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-50:hover {
  border-color: var(--purple-50) !important;
}

.hover\:border-purple-100:hover {
  border-color: var(--purple-100) !important;
}

.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}

.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}

.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}

.hover\:border-purple-500:hover {
  border-color: var(--purple-500) !important;
}

.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}

.hover\:border-purple-700:hover {
  border-color: var(--purple-700) !important;
}

.hover\:border-purple-800:hover {
  border-color: var(--purple-800) !important;
}

.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-50:active {
  border-color: var(--purple-50) !important;
}

.active\:border-purple-100:active {
  border-color: var(--purple-100) !important;
}

.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}

.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}

.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}

.active\:border-purple-500:active {
  border-color: var(--purple-500) !important;
}

.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}

.active\:border-purple-700:active {
  border-color: var(--purple-700) !important;
}

.active\:border-purple-800:active {
  border-color: var(--purple-800) !important;
}

.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.border-gray-50 {
  border-color: var(--gray-50) !important;
}

.border-gray-100 {
  border-color: var(--gray-100) !important;
}

.border-gray-200 {
  border-color: var(--gray-200) !important;
}

.border-gray-300 {
  border-color: var(--gray-300) !important;
}

.border-gray-400 {
  border-color: var(--gray-400) !important;
}

.border-gray-500 {
  border-color: var(--gray-500) !important;
}

.border-gray-600 {
  border-color: var(--gray-600) !important;
}

.border-gray-700 {
  border-color: var(--gray-700) !important;
}

.border-gray-800 {
  border-color: var(--gray-800) !important;
}

.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-50:focus {
  border-color: var(--gray-50) !important;
}

.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}

.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}

.focus\:border-gray-300:focus {
  border-color: var(--gray-300) !important;
}

.focus\:border-gray-400:focus {
  border-color: var(--gray-400) !important;
}

.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}

.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}

.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}

.focus\:border-gray-800:focus {
  border-color: var(--gray-800) !important;
}

.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-50:hover {
  border-color: var(--gray-50) !important;
}

.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}

.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}

.hover\:border-gray-300:hover {
  border-color: var(--gray-300) !important;
}

.hover\:border-gray-400:hover {
  border-color: var(--gray-400) !important;
}

.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}

.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}

.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}

.hover\:border-gray-800:hover {
  border-color: var(--gray-800) !important;
}

.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-50:active {
  border-color: var(--gray-50) !important;
}

.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}

.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}

.active\:border-gray-300:active {
  border-color: var(--gray-300) !important;
}

.active\:border-gray-400:active {
  border-color: var(--gray-400) !important;
}

.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}

.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}

.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}

.active\:border-gray-800:active {
  border-color: var(--gray-800) !important;
}

.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.border-red-50 {
  border-color: var(--red-50) !important;
}

.border-red-100 {
  border-color: var(--red-100) !important;
}

.border-red-200 {
  border-color: var(--red-200) !important;
}

.border-red-300 {
  border-color: var(--red-300) !important;
}

.border-red-400 {
  border-color: var(--red-400) !important;
}

.border-red-500 {
  border-color: var(--red-500) !important;
}

.border-red-600 {
  border-color: var(--red-600) !important;
}

.border-red-700 {
  border-color: var(--red-700) !important;
}

.border-red-800 {
  border-color: var(--red-800) !important;
}

.border-red-900 {
  border-color: var(--red-900) !important;
}

.focus\:border-red-50:focus {
  border-color: var(--red-50) !important;
}

.focus\:border-red-100:focus {
  border-color: var(--red-100) !important;
}

.focus\:border-red-200:focus {
  border-color: var(--red-200) !important;
}

.focus\:border-red-300:focus {
  border-color: var(--red-300) !important;
}

.focus\:border-red-400:focus {
  border-color: var(--red-400) !important;
}

.focus\:border-red-500:focus {
  border-color: var(--red-500) !important;
}

.focus\:border-red-600:focus {
  border-color: var(--red-600) !important;
}

.focus\:border-red-700:focus {
  border-color: var(--red-700) !important;
}

.focus\:border-red-800:focus {
  border-color: var(--red-800) !important;
}

.focus\:border-red-900:focus {
  border-color: var(--red-900) !important;
}

.hover\:border-red-50:hover {
  border-color: var(--red-50) !important;
}

.hover\:border-red-100:hover {
  border-color: var(--red-100) !important;
}

.hover\:border-red-200:hover {
  border-color: var(--red-200) !important;
}

.hover\:border-red-300:hover {
  border-color: var(--red-300) !important;
}

.hover\:border-red-400:hover {
  border-color: var(--red-400) !important;
}

.hover\:border-red-500:hover {
  border-color: var(--red-500) !important;
}

.hover\:border-red-600:hover {
  border-color: var(--red-600) !important;
}

.hover\:border-red-700:hover {
  border-color: var(--red-700) !important;
}

.hover\:border-red-800:hover {
  border-color: var(--red-800) !important;
}

.hover\:border-red-900:hover {
  border-color: var(--red-900) !important;
}

.active\:border-red-50:active {
  border-color: var(--red-50) !important;
}

.active\:border-red-100:active {
  border-color: var(--red-100) !important;
}

.active\:border-red-200:active {
  border-color: var(--red-200) !important;
}

.active\:border-red-300:active {
  border-color: var(--red-300) !important;
}

.active\:border-red-400:active {
  border-color: var(--red-400) !important;
}

.active\:border-red-500:active {
  border-color: var(--red-500) !important;
}

.active\:border-red-600:active {
  border-color: var(--red-600) !important;
}

.active\:border-red-700:active {
  border-color: var(--red-700) !important;
}

.active\:border-red-800:active {
  border-color: var(--red-800) !important;
}

.active\:border-red-900:active {
  border-color: var(--red-900) !important;
}

.border-primary-50 {
  border-color: var(--primary-50) !important;
}

.border-primary-100 {
  border-color: var(--primary-100) !important;
}

.border-primary-200 {
  border-color: var(--primary-200) !important;
}

.border-primary-300 {
  border-color: var(--primary-300) !important;
}

.border-primary-400 {
  border-color: var(--primary-400) !important;
}

.border-primary-500 {
  border-color: var(--primary-500) !important;
}

.border-primary-600 {
  border-color: var(--primary-600) !important;
}

.border-primary-700 {
  border-color: var(--primary-700) !important;
}

.border-primary-800 {
  border-color: var(--primary-800) !important;
}

.border-primary-900 {
  border-color: var(--primary-900) !important;
}

.focus\:border-primary-50:focus {
  border-color: var(--primary-50) !important;
}

.focus\:border-primary-100:focus {
  border-color: var(--primary-100) !important;
}

.focus\:border-primary-200:focus {
  border-color: var(--primary-200) !important;
}

.focus\:border-primary-300:focus {
  border-color: var(--primary-300) !important;
}

.focus\:border-primary-400:focus {
  border-color: var(--primary-400) !important;
}

.focus\:border-primary-500:focus {
  border-color: var(--primary-500) !important;
}

.focus\:border-primary-600:focus {
  border-color: var(--primary-600) !important;
}

.focus\:border-primary-700:focus {
  border-color: var(--primary-700) !important;
}

.focus\:border-primary-800:focus {
  border-color: var(--primary-800) !important;
}

.focus\:border-primary-900:focus {
  border-color: var(--primary-900) !important;
}

.hover\:border-primary-50:hover {
  border-color: var(--primary-50) !important;
}

.hover\:border-primary-100:hover {
  border-color: var(--primary-100) !important;
}

.hover\:border-primary-200:hover {
  border-color: var(--primary-200) !important;
}

.hover\:border-primary-300:hover {
  border-color: var(--primary-300) !important;
}

.hover\:border-primary-400:hover {
  border-color: var(--primary-400) !important;
}

.hover\:border-primary-500:hover {
  border-color: var(--primary-500) !important;
}

.hover\:border-primary-600:hover {
  border-color: var(--primary-600) !important;
}

.hover\:border-primary-700:hover {
  border-color: var(--primary-700) !important;
}

.hover\:border-primary-800:hover {
  border-color: var(--primary-800) !important;
}

.hover\:border-primary-900:hover {
  border-color: var(--primary-900) !important;
}

.active\:border-primary-50:active {
  border-color: var(--primary-50) !important;
}

.active\:border-primary-100:active {
  border-color: var(--primary-100) !important;
}

.active\:border-primary-200:active {
  border-color: var(--primary-200) !important;
}

.active\:border-primary-300:active {
  border-color: var(--primary-300) !important;
}

.active\:border-primary-400:active {
  border-color: var(--primary-400) !important;
}

.active\:border-primary-500:active {
  border-color: var(--primary-500) !important;
}

.active\:border-primary-600:active {
  border-color: var(--primary-600) !important;
}

.active\:border-primary-700:active {
  border-color: var(--primary-700) !important;
}

.active\:border-primary-800:active {
  border-color: var(--primary-800) !important;
}

.active\:border-primary-900:active {
  border-color: var(--primary-900) !important;
}

.bg-white-alpha-10 {
  background-color: #ffffff1a !important;
}

.bg-white-alpha-20 {
  background-color: #fff3 !important;
}

.bg-white-alpha-30 {
  background-color: #ffffff4d !important;
}

.bg-white-alpha-40 {
  background-color: #fff6 !important;
}

.bg-white-alpha-50 {
  background-color: #ffffff80 !important;
}

.bg-white-alpha-60 {
  background-color: #fff9 !important;
}

.bg-white-alpha-70 {
  background-color: #ffffffb3 !important;
}

.bg-white-alpha-80 {
  background-color: #fffc !important;
}

.bg-white-alpha-90 {
  background-color: #ffffffe6 !important;
}

.hover\:bg-white-alpha-10:hover {
  background-color: #ffffff1a !important;
}

.hover\:bg-white-alpha-20:hover {
  background-color: #fff3 !important;
}

.hover\:bg-white-alpha-30:hover {
  background-color: #ffffff4d !important;
}

.hover\:bg-white-alpha-40:hover {
  background-color: #fff6 !important;
}

.hover\:bg-white-alpha-50:hover {
  background-color: #ffffff80 !important;
}

.hover\:bg-white-alpha-60:hover {
  background-color: #fff9 !important;
}

.hover\:bg-white-alpha-70:hover {
  background-color: #ffffffb3 !important;
}

.hover\:bg-white-alpha-80:hover {
  background-color: #fffc !important;
}

.hover\:bg-white-alpha-90:hover {
  background-color: #ffffffe6 !important;
}

.focus\:bg-white-alpha-10:focus {
  background-color: #ffffff1a !important;
}

.focus\:bg-white-alpha-20:focus {
  background-color: #fff3 !important;
}

.focus\:bg-white-alpha-30:focus {
  background-color: #ffffff4d !important;
}

.focus\:bg-white-alpha-40:focus {
  background-color: #fff6 !important;
}

.focus\:bg-white-alpha-50:focus {
  background-color: #ffffff80 !important;
}

.focus\:bg-white-alpha-60:focus {
  background-color: #fff9 !important;
}

.focus\:bg-white-alpha-70:focus {
  background-color: #ffffffb3 !important;
}

.focus\:bg-white-alpha-80:focus {
  background-color: #fffc !important;
}

.focus\:bg-white-alpha-90:focus {
  background-color: #ffffffe6 !important;
}

.active\:bg-white-alpha-10:active {
  background-color: #ffffff1a !important;
}

.active\:bg-white-alpha-20:active {
  background-color: #fff3 !important;
}

.active\:bg-white-alpha-30:active {
  background-color: #ffffff4d !important;
}

.active\:bg-white-alpha-40:active {
  background-color: #fff6 !important;
}

.active\:bg-white-alpha-50:active {
  background-color: #ffffff80 !important;
}

.active\:bg-white-alpha-60:active {
  background-color: #fff9 !important;
}

.active\:bg-white-alpha-70:active {
  background-color: #ffffffb3 !important;
}

.active\:bg-white-alpha-80:active {
  background-color: #fffc !important;
}

.active\:bg-white-alpha-90:active {
  background-color: #ffffffe6 !important;
}

.bg-black-alpha-10 {
  background-color: #0000001a !important;
}

.bg-black-alpha-20 {
  background-color: #0003 !important;
}

.bg-black-alpha-30 {
  background-color: #0000004d !important;
}

.bg-black-alpha-40 {
  background-color: #0006 !important;
}

.bg-black-alpha-50 {
  background-color: #00000080 !important;
}

.bg-black-alpha-60 {
  background-color: #0009 !important;
}

.bg-black-alpha-70 {
  background-color: #000000b3 !important;
}

.bg-black-alpha-80 {
  background-color: #000c !important;
}

.bg-black-alpha-90 {
  background-color: #000000e6 !important;
}

.hover\:bg-black-alpha-10:hover {
  background-color: #0000001a !important;
}

.hover\:bg-black-alpha-20:hover {
  background-color: #0003 !important;
}

.hover\:bg-black-alpha-30:hover {
  background-color: #0000004d !important;
}

.hover\:bg-black-alpha-40:hover {
  background-color: #0006 !important;
}

.hover\:bg-black-alpha-50:hover {
  background-color: #00000080 !important;
}

.hover\:bg-black-alpha-60:hover {
  background-color: #0009 !important;
}

.hover\:bg-black-alpha-70:hover {
  background-color: #000000b3 !important;
}

.hover\:bg-black-alpha-80:hover {
  background-color: #000c !important;
}

.hover\:bg-black-alpha-90:hover {
  background-color: #000000e6 !important;
}

.focus\:bg-black-alpha-10:focus {
  background-color: #0000001a !important;
}

.focus\:bg-black-alpha-20:focus {
  background-color: #0003 !important;
}

.focus\:bg-black-alpha-30:focus {
  background-color: #0000004d !important;
}

.focus\:bg-black-alpha-40:focus {
  background-color: #0006 !important;
}

.focus\:bg-black-alpha-50:focus {
  background-color: #00000080 !important;
}

.focus\:bg-black-alpha-60:focus {
  background-color: #0009 !important;
}

.focus\:bg-black-alpha-70:focus {
  background-color: #000000b3 !important;
}

.focus\:bg-black-alpha-80:focus {
  background-color: #000c !important;
}

.focus\:bg-black-alpha-90:focus {
  background-color: #000000e6 !important;
}

.active\:bg-black-alpha-10:active {
  background-color: #0000001a !important;
}

.active\:bg-black-alpha-20:active {
  background-color: #0003 !important;
}

.active\:bg-black-alpha-30:active {
  background-color: #0000004d !important;
}

.active\:bg-black-alpha-40:active {
  background-color: #0006 !important;
}

.active\:bg-black-alpha-50:active {
  background-color: #00000080 !important;
}

.active\:bg-black-alpha-60:active {
  background-color: #0009 !important;
}

.active\:bg-black-alpha-70:active {
  background-color: #000000b3 !important;
}

.active\:bg-black-alpha-80:active {
  background-color: #000c !important;
}

.active\:bg-black-alpha-90:active {
  background-color: #000000e6 !important;
}

.border-white-alpha-10 {
  border-color: #ffffff1a !important;
}

.border-white-alpha-20 {
  border-color: #fff3 !important;
}

.border-white-alpha-30 {
  border-color: #ffffff4d !important;
}

.border-white-alpha-40 {
  border-color: #fff6 !important;
}

.border-white-alpha-50 {
  border-color: #ffffff80 !important;
}

.border-white-alpha-60 {
  border-color: #fff9 !important;
}

.border-white-alpha-70 {
  border-color: #ffffffb3 !important;
}

.border-white-alpha-80 {
  border-color: #fffc !important;
}

.border-white-alpha-90 {
  border-color: #ffffffe6 !important;
}

.hover\:border-white-alpha-10:hover {
  border-color: #ffffff1a !important;
}

.hover\:border-white-alpha-20:hover {
  border-color: #fff3 !important;
}

.hover\:border-white-alpha-30:hover {
  border-color: #ffffff4d !important;
}

.hover\:border-white-alpha-40:hover {
  border-color: #fff6 !important;
}

.hover\:border-white-alpha-50:hover {
  border-color: #ffffff80 !important;
}

.hover\:border-white-alpha-60:hover {
  border-color: #fff9 !important;
}

.hover\:border-white-alpha-70:hover {
  border-color: #ffffffb3 !important;
}

.hover\:border-white-alpha-80:hover {
  border-color: #fffc !important;
}

.hover\:border-white-alpha-90:hover {
  border-color: #ffffffe6 !important;
}

.focus\:border-white-alpha-10:focus {
  border-color: #ffffff1a !important;
}

.focus\:border-white-alpha-20:focus {
  border-color: #fff3 !important;
}

.focus\:border-white-alpha-30:focus {
  border-color: #ffffff4d !important;
}

.focus\:border-white-alpha-40:focus {
  border-color: #fff6 !important;
}

.focus\:border-white-alpha-50:focus {
  border-color: #ffffff80 !important;
}

.focus\:border-white-alpha-60:focus {
  border-color: #fff9 !important;
}

.focus\:border-white-alpha-70:focus {
  border-color: #ffffffb3 !important;
}

.focus\:border-white-alpha-80:focus {
  border-color: #fffc !important;
}

.focus\:border-white-alpha-90:focus {
  border-color: #ffffffe6 !important;
}

.active\:border-white-alpha-10:active {
  border-color: #ffffff1a !important;
}

.active\:border-white-alpha-20:active {
  border-color: #fff3 !important;
}

.active\:border-white-alpha-30:active {
  border-color: #ffffff4d !important;
}

.active\:border-white-alpha-40:active {
  border-color: #fff6 !important;
}

.active\:border-white-alpha-50:active {
  border-color: #ffffff80 !important;
}

.active\:border-white-alpha-60:active {
  border-color: #fff9 !important;
}

.active\:border-white-alpha-70:active {
  border-color: #ffffffb3 !important;
}

.active\:border-white-alpha-80:active {
  border-color: #fffc !important;
}

.active\:border-white-alpha-90:active {
  border-color: #ffffffe6 !important;
}

.border-black-alpha-10 {
  border-color: #0000001a !important;
}

.border-black-alpha-20 {
  border-color: #0003 !important;
}

.border-black-alpha-30 {
  border-color: #0000004d !important;
}

.border-black-alpha-40 {
  border-color: #0006 !important;
}

.border-black-alpha-50 {
  border-color: #00000080 !important;
}

.border-black-alpha-60 {
  border-color: #0009 !important;
}

.border-black-alpha-70 {
  border-color: #000000b3 !important;
}

.border-black-alpha-80 {
  border-color: #000c !important;
}

.border-black-alpha-90 {
  border-color: #000000e6 !important;
}

.hover\:border-black-alpha-10:hover {
  border-color: #0000001a !important;
}

.hover\:border-black-alpha-20:hover {
  border-color: #0003 !important;
}

.hover\:border-black-alpha-30:hover {
  border-color: #0000004d !important;
}

.hover\:border-black-alpha-40:hover {
  border-color: #0006 !important;
}

.hover\:border-black-alpha-50:hover {
  border-color: #00000080 !important;
}

.hover\:border-black-alpha-60:hover {
  border-color: #0009 !important;
}

.hover\:border-black-alpha-70:hover {
  border-color: #000000b3 !important;
}

.hover\:border-black-alpha-80:hover {
  border-color: #000c !important;
}

.hover\:border-black-alpha-90:hover {
  border-color: #000000e6 !important;
}

.focus\:border-black-alpha-10:focus {
  border-color: #0000001a !important;
}

.focus\:border-black-alpha-20:focus {
  border-color: #0003 !important;
}

.focus\:border-black-alpha-30:focus {
  border-color: #0000004d !important;
}

.focus\:border-black-alpha-40:focus {
  border-color: #0006 !important;
}

.focus\:border-black-alpha-50:focus {
  border-color: #00000080 !important;
}

.focus\:border-black-alpha-60:focus {
  border-color: #0009 !important;
}

.focus\:border-black-alpha-70:focus {
  border-color: #000000b3 !important;
}

.focus\:border-black-alpha-80:focus {
  border-color: #000c !important;
}

.focus\:border-black-alpha-90:focus {
  border-color: #000000e6 !important;
}

.active\:border-black-alpha-10:active {
  border-color: #0000001a !important;
}

.active\:border-black-alpha-20:active {
  border-color: #0003 !important;
}

.active\:border-black-alpha-30:active {
  border-color: #0000004d !important;
}

.active\:border-black-alpha-40:active {
  border-color: #0006 !important;
}

.active\:border-black-alpha-50:active {
  border-color: #00000080 !important;
}

.active\:border-black-alpha-60:active {
  border-color: #0009 !important;
}

.active\:border-black-alpha-70:active {
  border-color: #000000b3 !important;
}

.active\:border-black-alpha-80:active {
  border-color: #000c !important;
}

.active\:border-black-alpha-90:active {
  border-color: #000000e6 !important;
}

.text-white-alpha-10 {
  color: #ffffff1a !important;
}

.text-white-alpha-20 {
  color: #fff3 !important;
}

.text-white-alpha-30 {
  color: #ffffff4d !important;
}

.text-white-alpha-40 {
  color: #fff6 !important;
}

.text-white-alpha-50 {
  color: #ffffff80 !important;
}

.text-white-alpha-60 {
  color: #fff9 !important;
}

.text-white-alpha-70 {
  color: #ffffffb3 !important;
}

.text-white-alpha-80 {
  color: #fffc !important;
}

.text-white-alpha-90 {
  color: #ffffffe6 !important;
}

.hover\:text-white-alpha-10:hover {
  color: #ffffff1a !important;
}

.hover\:text-white-alpha-20:hover {
  color: #fff3 !important;
}

.hover\:text-white-alpha-30:hover {
  color: #ffffff4d !important;
}

.hover\:text-white-alpha-40:hover {
  color: #fff6 !important;
}

.hover\:text-white-alpha-50:hover {
  color: #ffffff80 !important;
}

.hover\:text-white-alpha-60:hover {
  color: #fff9 !important;
}

.hover\:text-white-alpha-70:hover {
  color: #ffffffb3 !important;
}

.hover\:text-white-alpha-80:hover {
  color: #fffc !important;
}

.hover\:text-white-alpha-90:hover {
  color: #ffffffe6 !important;
}

.focus\:text-white-alpha-10:focus {
  color: #ffffff1a !important;
}

.focus\:text-white-alpha-20:focus {
  color: #fff3 !important;
}

.focus\:text-white-alpha-30:focus {
  color: #ffffff4d !important;
}

.focus\:text-white-alpha-40:focus {
  color: #fff6 !important;
}

.focus\:text-white-alpha-50:focus {
  color: #ffffff80 !important;
}

.focus\:text-white-alpha-60:focus {
  color: #fff9 !important;
}

.focus\:text-white-alpha-70:focus {
  color: #ffffffb3 !important;
}

.focus\:text-white-alpha-80:focus {
  color: #fffc !important;
}

.focus\:text-white-alpha-90:focus {
  color: #ffffffe6 !important;
}

.active\:text-white-alpha-10:active {
  color: #ffffff1a !important;
}

.active\:text-white-alpha-20:active {
  color: #fff3 !important;
}

.active\:text-white-alpha-30:active {
  color: #ffffff4d !important;
}

.active\:text-white-alpha-40:active {
  color: #fff6 !important;
}

.active\:text-white-alpha-50:active {
  color: #ffffff80 !important;
}

.active\:text-white-alpha-60:active {
  color: #fff9 !important;
}

.active\:text-white-alpha-70:active {
  color: #ffffffb3 !important;
}

.active\:text-white-alpha-80:active {
  color: #fffc !important;
}

.active\:text-white-alpha-90:active {
  color: #ffffffe6 !important;
}

.text-black-alpha-10 {
  color: #0000001a !important;
}

.text-black-alpha-20 {
  color: #0003 !important;
}

.text-black-alpha-30 {
  color: #0000004d !important;
}

.text-black-alpha-40 {
  color: #0006 !important;
}

.text-black-alpha-50 {
  color: #00000080 !important;
}

.text-black-alpha-60 {
  color: #0009 !important;
}

.text-black-alpha-70 {
  color: #000000b3 !important;
}

.text-black-alpha-80 {
  color: #000c !important;
}

.text-black-alpha-90 {
  color: #000000e6 !important;
}

.hover\:text-black-alpha-10:hover {
  color: #0000001a !important;
}

.hover\:text-black-alpha-20:hover {
  color: #0003 !important;
}

.hover\:text-black-alpha-30:hover {
  color: #0000004d !important;
}

.hover\:text-black-alpha-40:hover {
  color: #0006 !important;
}

.hover\:text-black-alpha-50:hover {
  color: #00000080 !important;
}

.hover\:text-black-alpha-60:hover {
  color: #0009 !important;
}

.hover\:text-black-alpha-70:hover {
  color: #000000b3 !important;
}

.hover\:text-black-alpha-80:hover {
  color: #000c !important;
}

.hover\:text-black-alpha-90:hover {
  color: #000000e6 !important;
}

.focus\:text-black-alpha-10:focus {
  color: #0000001a !important;
}

.focus\:text-black-alpha-20:focus {
  color: #0003 !important;
}

.focus\:text-black-alpha-30:focus {
  color: #0000004d !important;
}

.focus\:text-black-alpha-40:focus {
  color: #0006 !important;
}

.focus\:text-black-alpha-50:focus {
  color: #00000080 !important;
}

.focus\:text-black-alpha-60:focus {
  color: #0009 !important;
}

.focus\:text-black-alpha-70:focus {
  color: #000000b3 !important;
}

.focus\:text-black-alpha-80:focus {
  color: #000c !important;
}

.focus\:text-black-alpha-90:focus {
  color: #000000e6 !important;
}

.active\:text-black-alpha-10:active {
  color: #0000001a !important;
}

.active\:text-black-alpha-20:active {
  color: #0003 !important;
}

.active\:text-black-alpha-30:active {
  color: #0000004d !important;
}

.active\:text-black-alpha-40:active {
  color: #0006 !important;
}

.active\:text-black-alpha-50:active {
  color: #00000080 !important;
}

.active\:text-black-alpha-60:active {
  color: #0009 !important;
}

.active\:text-black-alpha-70:active {
  color: #000000b3 !important;
}

.active\:text-black-alpha-80:active {
  color: #000c !important;
}

.active\:text-black-alpha-90:active {
  color: #000000e6 !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.text-white {
  color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.focus\:text-primary:focus, .hover\:text-primary:hover, .active\:text-primary:active {
  color: var(--primary-color) !important;
}

.focus\:bg-primary:focus, .hover\:bg-primary:hover, .active\:bg-primary:active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.focus\:bg-primary-reverse:focus, .hover\:bg-primary-reverse:hover, .active\:bg-primary-reverse:active {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.focus\:bg-white:focus, .hover\:bg-white:hover, .active\:bg-white:active {
  background-color: #fff !important;
}

.focus\:border-primary:focus, .hover\:border-primary:hover, .active\:border-primary:active {
  border-color: var(--primary-color) !important;
}

.focus\:text-white:focus, .hover\:text-white:hover, .active\:text-white:active {
  color: #fff !important;
}

.focus\:border-white:focus, .hover\:border-white:hover, .active\:border-white:active {
  border-color: #fff !important;
}

.focus\:text-color:focus, .hover\:text-color:hover, .active\:text-color:active {
  color: var(--text-color) !important;
}

.focus\:text-color-secondary:focus, .hover\:text-color-secondary:hover, .active\:text-color-secondary:active {
  color: var(--text-color-secondary) !important;
}

.focus\:surface-ground:focus, .hover\:surface-ground:hover, .active\:surface-ground:active {
  background-color: var(--surface-ground) !important;
}

.focus\:surface-section:focus, .hover\:surface-section:hover, .active\:surface-section:active {
  background-color: var(--surface-section) !important;
}

.focus\:surface-card:focus, .hover\:surface-card:hover, .active\:surface-card:active {
  background-color: var(--surface-card) !important;
}

.focus\:surface-overlay:focus, .hover\:surface-overlay:hover, .active\:surface-overlay:active {
  background-color: var(--surface-overlay) !important;
}

.focus\:surface-hover:focus, .hover\:surface-hover:hover, .active\:surface-hover:active {
  background-color: var(--surface-hover) !important;
}

.focus\:surface-border:focus, .hover\:surface-border:hover, .active\:surface-border:active {
  border-color: var(--surface-border) !important;
}

@media screen and (width >= 576px) {
  .sm\:text-primary {
    color: var(--primary-color) !important;
  }

  .sm\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .sm\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .sm\:bg-white {
    background-color: #fff !important;
  }

  .sm\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .sm\:text-white {
    color: #fff !important;
  }

  .sm\:border-white {
    border-color: #fff !important;
  }

  .sm\:text-color {
    color: var(--text-color) !important;
  }

  .sm\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .sm\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .sm\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .sm\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .sm\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .sm\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .sm\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .sm\:focus\:text-primary:focus, .sm\:hover\:text-primary:hover, .sm\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .sm\:focus\:bg-primary:focus, .sm\:hover\:bg-primary:hover, .sm\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .sm\:focus\:bg-primary-reverse:focus, .sm\:hover\:bg-primary-reverse:hover, .sm\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .sm\:focus\:bg-white:focus, .sm\:hover\:bg-white:hover, .sm\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .sm\:focus\:border-primary:focus, .sm\:hover\:border-primary:hover, .sm\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .sm\:focus\:text-white:focus, .sm\:hover\:text-white:hover, .sm\:active\:text-white:active {
    color: #fff !important;
  }

  .sm\:focus\:border-white:focus, .sm\:hover\:border-white:hover, .sm\:active\:border-white:active {
    border-color: #fff !important;
  }

  .sm\:focus\:text-color:focus, .sm\:hover\:text-color:hover, .sm\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .sm\:focus\:text-color-secondary:focus, .sm\:hover\:text-color-secondary:hover, .sm\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .sm\:focus\:surface-ground:focus, .sm\:hover\:surface-ground:hover, .sm\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .sm\:focus\:surface-section:focus, .sm\:hover\:surface-section:hover, .sm\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .sm\:focus\:surface-card:focus, .sm\:hover\:surface-card:hover, .sm\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .sm\:focus\:surface-overlay:focus, .sm\:hover\:surface-overlay:hover, .sm\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .sm\:focus\:surface-hover:focus, .sm\:hover\:surface-hover:hover, .sm\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .sm\:focus\:surface-border:focus, .sm\:hover\:surface-border:hover, .sm\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

@media screen and (width >= 768px) {
  .md\:text-primary {
    color: var(--primary-color) !important;
  }

  .md\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .md\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .md\:bg-white {
    background-color: #fff !important;
  }

  .md\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .md\:text-white {
    color: #fff !important;
  }

  .md\:border-white {
    border-color: #fff !important;
  }

  .md\:text-color {
    color: var(--text-color) !important;
  }

  .md\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .md\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .md\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .md\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .md\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .md\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .md\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .md\:focus\:text-primary:focus, .md\:hover\:text-primary:hover, .md\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .md\:focus\:bg-primary:focus, .md\:hover\:bg-primary:hover, .md\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .md\:focus\:bg-primary-reverse:focus, .md\:hover\:bg-primary-reverse:hover, .md\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .md\:focus\:bg-white:focus, .md\:hover\:bg-white:hover, .md\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .md\:focus\:border-primary:focus, .md\:hover\:border-primary:hover, .md\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .md\:focus\:text-white:focus, .md\:hover\:text-white:hover, .md\:active\:text-white:active {
    color: #fff !important;
  }

  .md\:focus\:border-white:focus, .md\:hover\:border-white:hover, .md\:active\:border-white:active {
    border-color: #fff !important;
  }

  .md\:focus\:text-color:focus, .md\:hover\:text-color:hover, .md\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .md\:focus\:text-color-secondary:focus, .md\:hover\:text-color-secondary:hover, .md\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .md\:focus\:surface-ground:focus, .md\:hover\:surface-ground:hover, .md\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .md\:focus\:surface-section:focus, .md\:hover\:surface-section:hover, .md\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .md\:focus\:surface-card:focus, .md\:hover\:surface-card:hover, .md\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .md\:focus\:surface-overlay:focus, .md\:hover\:surface-overlay:hover, .md\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .md\:focus\:surface-hover:focus, .md\:hover\:surface-hover:hover, .md\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .md\:focus\:surface-border:focus, .md\:hover\:surface-border:hover, .md\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:text-primary {
    color: var(--primary-color) !important;
  }

  .lg\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .lg\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .lg\:bg-white {
    background-color: #fff !important;
  }

  .lg\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .lg\:text-white {
    color: #fff !important;
  }

  .lg\:border-white {
    border-color: #fff !important;
  }

  .lg\:text-color {
    color: var(--text-color) !important;
  }

  .lg\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .lg\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .lg\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .lg\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .lg\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .lg\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .lg\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .lg\:focus\:text-primary:focus, .lg\:hover\:text-primary:hover, .lg\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .lg\:focus\:bg-primary:focus, .lg\:hover\:bg-primary:hover, .lg\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .lg\:focus\:bg-primary-reverse:focus, .lg\:hover\:bg-primary-reverse:hover, .lg\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .lg\:focus\:bg-white:focus, .lg\:hover\:bg-white:hover, .lg\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .lg\:focus\:border-primary:focus, .lg\:hover\:border-primary:hover, .lg\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .lg\:focus\:text-white:focus, .lg\:hover\:text-white:hover, .lg\:active\:text-white:active {
    color: #fff !important;
  }

  .lg\:focus\:border-white:focus, .lg\:hover\:border-white:hover, .lg\:active\:border-white:active {
    border-color: #fff !important;
  }

  .lg\:focus\:text-color:focus, .lg\:hover\:text-color:hover, .lg\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .lg\:focus\:text-color-secondary:focus, .lg\:hover\:text-color-secondary:hover, .lg\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .lg\:focus\:surface-ground:focus, .lg\:hover\:surface-ground:hover, .lg\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .lg\:focus\:surface-section:focus, .lg\:hover\:surface-section:hover, .lg\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .lg\:focus\:surface-card:focus, .lg\:hover\:surface-card:hover, .lg\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .lg\:focus\:surface-overlay:focus, .lg\:hover\:surface-overlay:hover, .lg\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .lg\:focus\:surface-hover:focus, .lg\:hover\:surface-hover:hover, .lg\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .lg\:focus\:surface-border:focus, .lg\:hover\:surface-border:hover, .lg\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:text-primary {
    color: var(--primary-color) !important;
  }

  .xl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .xl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .xl\:bg-white {
    background-color: #fff !important;
  }

  .xl\:border-primary {
    border-color: var(--primary-color) !important;
  }

  .xl\:text-white {
    color: #fff !important;
  }

  .xl\:border-white {
    border-color: #fff !important;
  }

  .xl\:text-color {
    color: var(--text-color) !important;
  }

  .xl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }

  .xl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }

  .xl\:surface-section {
    background-color: var(--surface-section) !important;
  }

  .xl\:surface-card {
    background-color: var(--surface-card) !important;
  }

  .xl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }

  .xl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }

  .xl\:surface-border {
    border-color: var(--surface-border) !important;
  }

  .xl\:focus\:text-primary:focus, .xl\:hover\:text-primary:hover, .xl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }

  .xl\:focus\:bg-primary:focus, .xl\:hover\:bg-primary:hover, .xl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }

  .xl\:focus\:bg-primary-reverse:focus, .xl\:hover\:bg-primary-reverse:hover, .xl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }

  .xl\:focus\:bg-white:focus, .xl\:hover\:bg-white:hover, .xl\:active\:bg-white:active {
    background-color: #fff !important;
  }

  .xl\:focus\:border-primary:focus, .xl\:hover\:border-primary:hover, .xl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }

  .xl\:focus\:text-white:focus, .xl\:hover\:text-white:hover, .xl\:active\:text-white:active {
    color: #fff !important;
  }

  .xl\:focus\:border-white:focus, .xl\:hover\:border-white:hover, .xl\:active\:border-white:active {
    border-color: #fff !important;
  }

  .xl\:focus\:text-color:focus, .xl\:hover\:text-color:hover, .xl\:active\:text-color:active {
    color: var(--text-color) !important;
  }

  .xl\:focus\:text-color-secondary:focus, .xl\:hover\:text-color-secondary:hover, .xl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }

  .xl\:focus\:surface-ground:focus, .xl\:hover\:surface-ground:hover, .xl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }

  .xl\:focus\:surface-section:focus, .xl\:hover\:surface-section:hover, .xl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }

  .xl\:focus\:surface-card:focus, .xl\:hover\:surface-card:hover, .xl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }

  .xl\:focus\:surface-overlay:focus, .xl\:hover\:surface-overlay:hover, .xl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }

  .xl\:focus\:surface-hover:focus, .xl\:hover\:surface-hover:hover, .xl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }

  .xl\:focus\:surface-border:focus, .xl\:hover\:surface-border:hover, .xl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}

.field {
  margin-bottom: 1rem;
}

.field > label {
  margin-bottom: .5rem;
  display: inline-block;
}

.field.grid > label {
  align-items: center;
  display: flex;
}

.field > small {
  margin-top: .25rem;
}

.field.grid, .formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed, .formgrid.grid .col-fixed, .field.grid .col, .formgrid.grid .col, .field.grid .col-1, .formgrid.grid .col-1, .field.grid .col-2, .formgrid.grid .col-2, .field.grid .col-3, .formgrid.grid .col-3, .field.grid .col-4, .formgrid.grid .col-4, .field.grid .col-5, .formgrid.grid .col-5, .field.grid .col-6, .formgrid.grid .col-6, .field.grid .col-7, .formgrid.grid .col-7, .field.grid .col-8, .formgrid.grid .col-8, .field.grid .col-9, .formgrid.grid .col-9, .field.grid .col-10, .formgrid.grid .col-10, .field.grid .col-11, .formgrid.grid .col-11, .field.grid .col-12, .formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
}

.formgroup-inline .field, .formgroup-inline .field-checkbox, .formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label, .formgroup-inline .field-checkbox > label, .formgroup-inline .field-radiobutton > label {
  margin-bottom: 0;
  margin-right: .5rem;
}

.field-checkbox, .field-radiobutton {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.field-checkbox > label, .field-radiobutton > label {
  margin-left: .5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (width >= 576px) {
  .sm\:hidden {
    display: none !important;
  }

  .sm\:block {
    display: block !important;
  }

  .sm\:inline {
    display: inline !important;
  }

  .sm\:inline-block {
    display: inline-block !important;
  }

  .sm\:flex {
    display: flex !important;
  }

  .sm\:inline-flex {
    display: inline-flex !important;
  }
}

@media screen and (width >= 768px) {
  .md\:hidden {
    display: none !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:inline {
    display: inline !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:inline-flex {
    display: inline-flex !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:hidden {
    display: none !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:inline {
    display: inline !important;
  }

  .lg\:inline-block {
    display: inline-block !important;
  }

  .lg\:flex {
    display: flex !important;
  }

  .lg\:inline-flex {
    display: inline-flex !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:hidden {
    display: none !important;
  }

  .xl\:block {
    display: block !important;
  }

  .xl\:inline {
    display: inline !important;
  }

  .xl\:inline-block {
    display: inline-block !important;
  }

  .xl\:flex {
    display: flex !important;
  }

  .xl\:inline-flex {
    display: inline-flex !important;
  }
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (width >= 576px) {
  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-justify {
    text-align: justify !important;
  }

  .sm\:text-left {
    text-align: left !important;
  }

  .sm\:text-right {
    text-align: right !important;
  }
}

@media screen and (width >= 768px) {
  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-justify {
    text-align: justify !important;
  }

  .md\:text-left {
    text-align: left !important;
  }

  .md\:text-right {
    text-align: right !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:text-justify {
    text-align: justify !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-right {
    text-align: right !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-justify {
    text-align: justify !important;
  }

  .xl\:text-left {
    text-align: left !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.focus\:underline:focus, .hover\:underline:hover, .active\:underline:active {
  text-decoration: underline !important;
}

.focus\:line-through:focus, .hover\:line-through:hover, .active\:line-through:active {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus, .hover\:no-underline:hover, .active\:no-underline:active {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (width >= 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

@media screen and (width >= 768px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }

  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (width >= 576px) {
  .sm\:font-light {
    font-weight: 300 !important;
  }

  .sm\:font-normal {
    font-weight: 400 !important;
  }

  .sm\:font-medium {
    font-weight: 500 !important;
  }

  .sm\:font-semibold {
    font-weight: 600 !important;
  }

  .sm\:font-bold {
    font-weight: 700 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:font-light {
    font-weight: 300 !important;
  }

  .md\:font-normal {
    font-weight: 400 !important;
  }

  .md\:font-medium {
    font-weight: 500 !important;
  }

  .md\:font-semibold {
    font-weight: 600 !important;
  }

  .md\:font-bold {
    font-weight: 700 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:font-light {
    font-weight: 300 !important;
  }

  .lg\:font-normal {
    font-weight: 400 !important;
  }

  .lg\:font-medium {
    font-weight: 500 !important;
  }

  .lg\:font-semibold {
    font-weight: 600 !important;
  }

  .lg\:font-bold {
    font-weight: 700 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:font-light {
    font-weight: 300 !important;
  }

  .xl\:font-normal {
    font-weight: 400 !important;
  }

  .xl\:font-medium {
    font-weight: 500 !important;
  }

  .xl\:font-semibold {
    font-weight: 600 !important;
  }

  .xl\:font-bold {
    font-weight: 700 !important;
  }
}

.font-italic {
  font-style: italic !important;
}

.text-xs {
  font-size: .75rem !important;
}

.text-sm {
  font-size: .875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.5rem !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.text-7xl {
  font-size: 4rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

@media screen and (width >= 576px) {
  .sm\:text-xs {
    font-size: .75rem !important;
  }

  .sm\:text-sm {
    font-size: .875rem !important;
  }

  .sm\:text-base {
    font-size: 1rem !important;
  }

  .sm\:text-lg {
    font-size: 1.125rem !important;
  }

  .sm\:text-xl {
    font-size: 1.25rem !important;
  }

  .sm\:text-2xl {
    font-size: 1.5rem !important;
  }

  .sm\:text-3xl {
    font-size: 1.75rem !important;
  }

  .sm\:text-4xl {
    font-size: 2rem !important;
  }

  .sm\:text-5xl {
    font-size: 2.5rem !important;
  }

  .sm\:text-6xl {
    font-size: 3rem !important;
  }

  .sm\:text-7xl {
    font-size: 4rem !important;
  }

  .sm\:text-8xl {
    font-size: 6rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:text-xs {
    font-size: .75rem !important;
  }

  .md\:text-sm {
    font-size: .875rem !important;
  }

  .md\:text-base {
    font-size: 1rem !important;
  }

  .md\:text-lg {
    font-size: 1.125rem !important;
  }

  .md\:text-xl {
    font-size: 1.25rem !important;
  }

  .md\:text-2xl {
    font-size: 1.5rem !important;
  }

  .md\:text-3xl {
    font-size: 1.75rem !important;
  }

  .md\:text-4xl {
    font-size: 2rem !important;
  }

  .md\:text-5xl {
    font-size: 2.5rem !important;
  }

  .md\:text-6xl {
    font-size: 3rem !important;
  }

  .md\:text-7xl {
    font-size: 4rem !important;
  }

  .md\:text-8xl {
    font-size: 6rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:text-xs {
    font-size: .75rem !important;
  }

  .lg\:text-sm {
    font-size: .875rem !important;
  }

  .lg\:text-base {
    font-size: 1rem !important;
  }

  .lg\:text-lg {
    font-size: 1.125rem !important;
  }

  .lg\:text-xl {
    font-size: 1.25rem !important;
  }

  .lg\:text-2xl {
    font-size: 1.5rem !important;
  }

  .lg\:text-3xl {
    font-size: 1.75rem !important;
  }

  .lg\:text-4xl {
    font-size: 2rem !important;
  }

  .lg\:text-5xl {
    font-size: 2.5rem !important;
  }

  .lg\:text-6xl {
    font-size: 3rem !important;
  }

  .lg\:text-7xl {
    font-size: 4rem !important;
  }

  .lg\:text-8xl {
    font-size: 6rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:text-xs {
    font-size: .75rem !important;
  }

  .xl\:text-sm {
    font-size: .875rem !important;
  }

  .xl\:text-base {
    font-size: 1rem !important;
  }

  .xl\:text-lg {
    font-size: 1.125rem !important;
  }

  .xl\:text-xl {
    font-size: 1.25rem !important;
  }

  .xl\:text-2xl {
    font-size: 1.5rem !important;
  }

  .xl\:text-3xl {
    font-size: 1.75rem !important;
  }

  .xl\:text-4xl {
    font-size: 2rem !important;
  }

  .xl\:text-5xl {
    font-size: 2.5rem !important;
  }

  .xl\:text-6xl {
    font-size: 3rem !important;
  }

  .xl\:text-7xl {
    font-size: 4rem !important;
  }

  .xl\:text-8xl {
    font-size: 6rem !important;
  }
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-text-top {
  vertical-align: text-top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-super {
  vertical-align: super !important;
}

@media screen and (width >= 576px) {
  .sm\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .sm\:vertical-align-top {
    vertical-align: top !important;
  }

  .sm\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .sm\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .sm\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .sm\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .sm\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .sm\:vertical-align-super {
    vertical-align: super !important;
  }
}

@media screen and (width >= 768px) {
  .md\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .md\:vertical-align-top {
    vertical-align: top !important;
  }

  .md\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .md\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .md\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .md\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .md\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .md\:vertical-align-super {
    vertical-align: super !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .lg\:vertical-align-top {
    vertical-align: top !important;
  }

  .lg\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .lg\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .lg\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .lg\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lg\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .lg\:vertical-align-super {
    vertical-align: super !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }

  .xl\:vertical-align-top {
    vertical-align: top !important;
  }

  .xl\:vertical-align-middle {
    vertical-align: middle !important;
  }

  .xl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }

  .xl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }

  .xl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xl\:vertical-align-sub {
    vertical-align: sub !important;
  }

  .xl\:vertical-align-super {
    vertical-align: super !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (width >= 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sm\:flex-column {
    flex-direction: column !important;
  }

  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (width >= 768px) {
  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .md\:flex-column {
    flex-direction: column !important;
  }

  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lg\:flex-column {
    flex-direction: column !important;
  }

  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xl\:flex-column {
    flex-direction: column !important;
  }

  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (width >= 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media screen and (width >= 768px) {
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media screen and (width >= 576px) {
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }

  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }

  .sm\:justify-content-center {
    justify-content: center !important;
  }

  .sm\:justify-content-between {
    justify-content: space-between !important;
  }

  .sm\:justify-content-around {
    justify-content: space-around !important;
  }

  .sm\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

@media screen and (width >= 768px) {
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }

  .md\:justify-content-end {
    justify-content: flex-end !important;
  }

  .md\:justify-content-center {
    justify-content: center !important;
  }

  .md\:justify-content-between {
    justify-content: space-between !important;
  }

  .md\:justify-content-around {
    justify-content: space-around !important;
  }

  .md\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }

  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }

  .lg\:justify-content-center {
    justify-content: center !important;
  }

  .lg\:justify-content-between {
    justify-content: space-between !important;
  }

  .lg\:justify-content-around {
    justify-content: space-around !important;
  }

  .lg\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }

  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }

  .xl\:justify-content-center {
    justify-content: center !important;
  }

  .xl\:justify-content-between {
    justify-content: space-between !important;
  }

  .xl\:justify-content-around {
    justify-content: space-around !important;
  }

  .xl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (width >= 576px) {
  .sm\:align-content-start {
    align-content: flex-start !important;
  }

  .sm\:align-content-end {
    align-content: flex-end !important;
  }

  .sm\:align-content-center {
    align-content: center !important;
  }

  .sm\:align-content-between {
    align-content: space-between !important;
  }

  .sm\:align-content-around {
    align-content: space-around !important;
  }

  .sm\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

@media screen and (width >= 768px) {
  .md\:align-content-start {
    align-content: flex-start !important;
  }

  .md\:align-content-end {
    align-content: flex-end !important;
  }

  .md\:align-content-center {
    align-content: center !important;
  }

  .md\:align-content-between {
    align-content: space-between !important;
  }

  .md\:align-content-around {
    align-content: space-around !important;
  }

  .md\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:align-content-start {
    align-content: flex-start !important;
  }

  .lg\:align-content-end {
    align-content: flex-end !important;
  }

  .lg\:align-content-center {
    align-content: center !important;
  }

  .lg\:align-content-between {
    align-content: space-between !important;
  }

  .lg\:align-content-around {
    align-content: space-around !important;
  }

  .lg\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:align-content-start {
    align-content: flex-start !important;
  }

  .xl\:align-content-end {
    align-content: flex-end !important;
  }

  .xl\:align-content-center {
    align-content: center !important;
  }

  .xl\:align-content-between {
    align-content: space-between !important;
  }

  .xl\:align-content-around {
    align-content: space-around !important;
  }

  .xl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media screen and (width >= 576px) {
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }

  .sm\:align-items-start {
    align-items: flex-start !important;
  }

  .sm\:align-items-center {
    align-items: center !important;
  }

  .sm\:align-items-end {
    align-items: flex-end !important;
  }

  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
}

@media screen and (width >= 768px) {
  .md\:align-items-stretch {
    align-items: stretch !important;
  }

  .md\:align-items-start {
    align-items: flex-start !important;
  }

  .md\:align-items-center {
    align-items: center !important;
  }

  .md\:align-items-end {
    align-items: flex-end !important;
  }

  .md\:align-items-baseline {
    align-items: baseline !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }

  .lg\:align-items-start {
    align-items: flex-start !important;
  }

  .lg\:align-items-center {
    align-items: center !important;
  }

  .lg\:align-items-end {
    align-items: flex-end !important;
  }

  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }

  .xl\:align-items-start {
    align-items: flex-start !important;
  }

  .xl\:align-items-center {
    align-items: center !important;
  }

  .xl\:align-items-end {
    align-items: flex-end !important;
  }

  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media screen and (width >= 576px) {
  .sm\:align-self-auto {
    align-self: auto !important;
  }

  .sm\:align-self-start {
    align-self: flex-start !important;
  }

  .sm\:align-self-end {
    align-self: flex-end !important;
  }

  .sm\:align-self-center {
    align-self: center !important;
  }

  .sm\:align-self-stretch {
    align-self: stretch !important;
  }

  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
}

@media screen and (width >= 768px) {
  .md\:align-self-auto {
    align-self: auto !important;
  }

  .md\:align-self-start {
    align-self: flex-start !important;
  }

  .md\:align-self-end {
    align-self: flex-end !important;
  }

  .md\:align-self-center {
    align-self: center !important;
  }

  .md\:align-self-stretch {
    align-self: stretch !important;
  }

  .md\:align-self-baseline {
    align-self: baseline !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:align-self-auto {
    align-self: auto !important;
  }

  .lg\:align-self-start {
    align-self: flex-start !important;
  }

  .lg\:align-self-end {
    align-self: flex-end !important;
  }

  .lg\:align-self-center {
    align-self: center !important;
  }

  .lg\:align-self-stretch {
    align-self: stretch !important;
  }

  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:align-self-auto {
    align-self: auto !important;
  }

  .xl\:align-self-start {
    align-self: flex-start !important;
  }

  .xl\:align-self-end {
    align-self: flex-end !important;
  }

  .xl\:align-self-center {
    align-self: center !important;
  }

  .xl\:align-self-stretch {
    align-self: stretch !important;
  }

  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}

.flex-order-0 {
  order: 0 !important;
}

.flex-order-1 {
  order: 1 !important;
}

.flex-order-2 {
  order: 2 !important;
}

.flex-order-3 {
  order: 3 !important;
}

.flex-order-4 {
  order: 4 !important;
}

.flex-order-5 {
  order: 5 !important;
}

.flex-order-6 {
  order: 6 !important;
}

@media screen and (width >= 576px) {
  .sm\:flex-order-0 {
    order: 0 !important;
  }

  .sm\:flex-order-1 {
    order: 1 !important;
  }

  .sm\:flex-order-2 {
    order: 2 !important;
  }

  .sm\:flex-order-3 {
    order: 3 !important;
  }

  .sm\:flex-order-4 {
    order: 4 !important;
  }

  .sm\:flex-order-5 {
    order: 5 !important;
  }

  .sm\:flex-order-6 {
    order: 6 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:flex-order-0 {
    order: 0 !important;
  }

  .md\:flex-order-1 {
    order: 1 !important;
  }

  .md\:flex-order-2 {
    order: 2 !important;
  }

  .md\:flex-order-3 {
    order: 3 !important;
  }

  .md\:flex-order-4 {
    order: 4 !important;
  }

  .md\:flex-order-5 {
    order: 5 !important;
  }

  .md\:flex-order-6 {
    order: 6 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:flex-order-0 {
    order: 0 !important;
  }

  .lg\:flex-order-1 {
    order: 1 !important;
  }

  .lg\:flex-order-2 {
    order: 2 !important;
  }

  .lg\:flex-order-3 {
    order: 3 !important;
  }

  .lg\:flex-order-4 {
    order: 4 !important;
  }

  .lg\:flex-order-5 {
    order: 5 !important;
  }

  .lg\:flex-order-6 {
    order: 6 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:flex-order-0 {
    order: 0 !important;
  }

  .xl\:flex-order-1 {
    order: 1 !important;
  }

  .xl\:flex-order-2 {
    order: 2 !important;
  }

  .xl\:flex-order-3 {
    order: 3 !important;
  }

  .xl\:flex-order-4 {
    order: 4 !important;
  }

  .xl\:flex-order-5 {
    order: 5 !important;
  }

  .xl\:flex-order-6 {
    order: 6 !important;
  }
}

.flex-1 {
  flex: 1 !important;
}

.flex-auto {
  flex: auto !important;
}

.flex-initial {
  flex: 0 auto !important;
}

.flex-none {
  flex: none !important;
}

@media screen and (width >= 576px) {
  .sm\:flex-1 {
    flex: 1 !important;
  }

  .sm\:flex-auto {
    flex: auto !important;
  }

  .sm\:flex-initial {
    flex: 0 auto !important;
  }

  .sm\:flex-none {
    flex: none !important;
  }
}

@media screen and (width >= 768px) {
  .md\:flex-1 {
    flex: 1 !important;
  }

  .md\:flex-auto {
    flex: auto !important;
  }

  .md\:flex-initial {
    flex: 0 auto !important;
  }

  .md\:flex-none {
    flex: none !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:flex-1 {
    flex: 1 !important;
  }

  .lg\:flex-auto {
    flex: auto !important;
  }

  .lg\:flex-initial {
    flex: 0 auto !important;
  }

  .lg\:flex-none {
    flex: none !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:flex-1 {
    flex: 1 !important;
  }

  .xl\:flex-auto {
    flex: auto !important;
  }

  .xl\:flex-initial {
    flex: 0 auto !important;
  }

  .xl\:flex-none {
    flex: none !important;
  }
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (width >= 576px) {
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (width >= 576px) {
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 3rem !important;
}

.gap-7 {
  gap: 4rem !important;
}

.gap-8 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 2rem !important;
}

.row-gap-6 {
  row-gap: 3rem !important;
}

.row-gap-7 {
  row-gap: 4rem !important;
}

.row-gap-8 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 2rem !important;
}

.column-gap-6 {
  column-gap: 3rem !important;
}

.column-gap-7 {
  column-gap: 4rem !important;
}

.column-gap-8 {
  column-gap: 5rem !important;
}

@media screen and (width >= 576px) {
  .sm\:gap-0 {
    gap: 0 !important;
  }

  .sm\:gap-1 {
    gap: .25rem !important;
  }

  .sm\:gap-2 {
    gap: .5rem !important;
  }

  .sm\:gap-3 {
    gap: 1rem !important;
  }

  .sm\:gap-4 {
    gap: 1.5rem !important;
  }

  .sm\:gap-5 {
    gap: 2rem !important;
  }

  .sm\:gap-6 {
    gap: 3rem !important;
  }

  .sm\:gap-7 {
    gap: 4rem !important;
  }

  .sm\:gap-8 {
    gap: 5rem !important;
  }

  .sm\:row-gap-0 {
    row-gap: 0 !important;
  }

  .sm\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .sm\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .sm\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .sm\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .sm\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .sm\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .sm\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .sm\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .sm\:column-gap-0 {
    column-gap: 0 !important;
  }

  .sm\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .sm\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .sm\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .sm\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .sm\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .sm\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .sm\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .sm\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:gap-0 {
    gap: 0 !important;
  }

  .md\:gap-1 {
    gap: .25rem !important;
  }

  .md\:gap-2 {
    gap: .5rem !important;
  }

  .md\:gap-3 {
    gap: 1rem !important;
  }

  .md\:gap-4 {
    gap: 1.5rem !important;
  }

  .md\:gap-5 {
    gap: 2rem !important;
  }

  .md\:gap-6 {
    gap: 3rem !important;
  }

  .md\:gap-7 {
    gap: 4rem !important;
  }

  .md\:gap-8 {
    gap: 5rem !important;
  }

  .md\:row-gap-0 {
    row-gap: 0 !important;
  }

  .md\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .md\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .md\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .md\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .md\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .md\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .md\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .md\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .md\:column-gap-0 {
    column-gap: 0 !important;
  }

  .md\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .md\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .md\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .md\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .md\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .md\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .md\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .md\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:gap-0 {
    gap: 0 !important;
  }

  .lg\:gap-1 {
    gap: .25rem !important;
  }

  .lg\:gap-2 {
    gap: .5rem !important;
  }

  .lg\:gap-3 {
    gap: 1rem !important;
  }

  .lg\:gap-4 {
    gap: 1.5rem !important;
  }

  .lg\:gap-5 {
    gap: 2rem !important;
  }

  .lg\:gap-6 {
    gap: 3rem !important;
  }

  .lg\:gap-7 {
    gap: 4rem !important;
  }

  .lg\:gap-8 {
    gap: 5rem !important;
  }

  .lg\:row-gap-0 {
    row-gap: 0 !important;
  }

  .lg\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .lg\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .lg\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .lg\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .lg\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .lg\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .lg\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .lg\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .lg\:column-gap-0 {
    column-gap: 0 !important;
  }

  .lg\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .lg\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .lg\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .lg\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .lg\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .lg\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .lg\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .lg\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:gap-0 {
    gap: 0 !important;
  }

  .xl\:gap-1 {
    gap: .25rem !important;
  }

  .xl\:gap-2 {
    gap: .5rem !important;
  }

  .xl\:gap-3 {
    gap: 1rem !important;
  }

  .xl\:gap-4 {
    gap: 1.5rem !important;
  }

  .xl\:gap-5 {
    gap: 2rem !important;
  }

  .xl\:gap-6 {
    gap: 3rem !important;
  }

  .xl\:gap-7 {
    gap: 4rem !important;
  }

  .xl\:gap-8 {
    gap: 5rem !important;
  }

  .xl\:row-gap-0 {
    row-gap: 0 !important;
  }

  .xl\:row-gap-1 {
    row-gap: .25rem !important;
  }

  .xl\:row-gap-2 {
    row-gap: .5rem !important;
  }

  .xl\:row-gap-3 {
    row-gap: 1rem !important;
  }

  .xl\:row-gap-4 {
    row-gap: 1.5rem !important;
  }

  .xl\:row-gap-5 {
    row-gap: 2rem !important;
  }

  .xl\:row-gap-6 {
    row-gap: 3rem !important;
  }

  .xl\:row-gap-7 {
    row-gap: 4rem !important;
  }

  .xl\:row-gap-8 {
    row-gap: 5rem !important;
  }

  .xl\:column-gap-0 {
    column-gap: 0 !important;
  }

  .xl\:column-gap-1 {
    column-gap: .25rem !important;
  }

  .xl\:column-gap-2 {
    column-gap: .5rem !important;
  }

  .xl\:column-gap-3 {
    column-gap: 1rem !important;
  }

  .xl\:column-gap-4 {
    column-gap: 1.5rem !important;
  }

  .xl\:column-gap-5 {
    column-gap: 2rem !important;
  }

  .xl\:column-gap-6 {
    column-gap: 3rem !important;
  }

  .xl\:column-gap-7 {
    column-gap: 4rem !important;
  }

  .xl\:column-gap-8 {
    column-gap: 5rem !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pr-7 {
  padding-right: 4rem !important;
}

.pr-8 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: .25rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (width >= 576px) {
  .sm\:p-0 {
    padding: 0 !important;
  }

  .sm\:p-1 {
    padding: .25rem !important;
  }

  .sm\:p-2 {
    padding: .5rem !important;
  }

  .sm\:p-3 {
    padding: 1rem !important;
  }

  .sm\:p-4 {
    padding: 1.5rem !important;
  }

  .sm\:p-5 {
    padding: 2rem !important;
  }

  .sm\:p-6 {
    padding: 3rem !important;
  }

  .sm\:p-7 {
    padding: 4rem !important;
  }

  .sm\:p-8 {
    padding: 5rem !important;
  }

  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pt-1 {
    padding-top: .25rem !important;
  }

  .sm\:pt-2 {
    padding-top: .5rem !important;
  }

  .sm\:pt-3 {
    padding-top: 1rem !important;
  }

  .sm\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .sm\:pt-5 {
    padding-top: 2rem !important;
  }

  .sm\:pt-6 {
    padding-top: 3rem !important;
  }

  .sm\:pt-7 {
    padding-top: 4rem !important;
  }

  .sm\:pt-8 {
    padding-top: 5rem !important;
  }

  .sm\:pr-0 {
    padding-right: 0 !important;
  }

  .sm\:pr-1 {
    padding-right: .25rem !important;
  }

  .sm\:pr-2 {
    padding-right: .5rem !important;
  }

  .sm\:pr-3 {
    padding-right: 1rem !important;
  }

  .sm\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .sm\:pr-5 {
    padding-right: 2rem !important;
  }

  .sm\:pr-6 {
    padding-right: 3rem !important;
  }

  .sm\:pr-7 {
    padding-right: 4rem !important;
  }

  .sm\:pr-8 {
    padding-right: 5rem !important;
  }

  .sm\:pl-0 {
    padding-left: 0 !important;
  }

  .sm\:pl-1 {
    padding-left: .25rem !important;
  }

  .sm\:pl-2 {
    padding-left: .5rem !important;
  }

  .sm\:pl-3 {
    padding-left: 1rem !important;
  }

  .sm\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .sm\:pl-5 {
    padding-left: 2rem !important;
  }

  .sm\:pl-6 {
    padding-left: 3rem !important;
  }

  .sm\:pl-7 {
    padding-left: 4rem !important;
  }

  .sm\:pl-8 {
    padding-left: 5rem !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important;
  }

  .sm\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .sm\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .sm\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .sm\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .sm\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .sm\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .sm\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .sm\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .sm\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .sm\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sm\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .sm\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .sm\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:p-0 {
    padding: 0 !important;
  }

  .md\:p-1 {
    padding: .25rem !important;
  }

  .md\:p-2 {
    padding: .5rem !important;
  }

  .md\:p-3 {
    padding: 1rem !important;
  }

  .md\:p-4 {
    padding: 1.5rem !important;
  }

  .md\:p-5 {
    padding: 2rem !important;
  }

  .md\:p-6 {
    padding: 3rem !important;
  }

  .md\:p-7 {
    padding: 4rem !important;
  }

  .md\:p-8 {
    padding: 5rem !important;
  }

  .md\:pt-0 {
    padding-top: 0 !important;
  }

  .md\:pt-1 {
    padding-top: .25rem !important;
  }

  .md\:pt-2 {
    padding-top: .5rem !important;
  }

  .md\:pt-3 {
    padding-top: 1rem !important;
  }

  .md\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .md\:pt-5 {
    padding-top: 2rem !important;
  }

  .md\:pt-6 {
    padding-top: 3rem !important;
  }

  .md\:pt-7 {
    padding-top: 4rem !important;
  }

  .md\:pt-8 {
    padding-top: 5rem !important;
  }

  .md\:pr-0 {
    padding-right: 0 !important;
  }

  .md\:pr-1 {
    padding-right: .25rem !important;
  }

  .md\:pr-2 {
    padding-right: .5rem !important;
  }

  .md\:pr-3 {
    padding-right: 1rem !important;
  }

  .md\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .md\:pr-5 {
    padding-right: 2rem !important;
  }

  .md\:pr-6 {
    padding-right: 3rem !important;
  }

  .md\:pr-7 {
    padding-right: 4rem !important;
  }

  .md\:pr-8 {
    padding-right: 5rem !important;
  }

  .md\:pl-0 {
    padding-left: 0 !important;
  }

  .md\:pl-1 {
    padding-left: .25rem !important;
  }

  .md\:pl-2 {
    padding-left: .5rem !important;
  }

  .md\:pl-3 {
    padding-left: 1rem !important;
  }

  .md\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .md\:pl-5 {
    padding-left: 2rem !important;
  }

  .md\:pl-6 {
    padding-left: 3rem !important;
  }

  .md\:pl-7 {
    padding-left: 4rem !important;
  }

  .md\:pl-8 {
    padding-left: 5rem !important;
  }

  .md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .md\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .md\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .md\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .md\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .md\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .md\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .md\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .md\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .md\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .md\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .md\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .md\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .md\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:p-0 {
    padding: 0 !important;
  }

  .lg\:p-1 {
    padding: .25rem !important;
  }

  .lg\:p-2 {
    padding: .5rem !important;
  }

  .lg\:p-3 {
    padding: 1rem !important;
  }

  .lg\:p-4 {
    padding: 1.5rem !important;
  }

  .lg\:p-5 {
    padding: 2rem !important;
  }

  .lg\:p-6 {
    padding: 3rem !important;
  }

  .lg\:p-7 {
    padding: 4rem !important;
  }

  .lg\:p-8 {
    padding: 5rem !important;
  }

  .lg\:pt-0 {
    padding-top: 0 !important;
  }

  .lg\:pt-1 {
    padding-top: .25rem !important;
  }

  .lg\:pt-2 {
    padding-top: .5rem !important;
  }

  .lg\:pt-3 {
    padding-top: 1rem !important;
  }

  .lg\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .lg\:pt-5 {
    padding-top: 2rem !important;
  }

  .lg\:pt-6 {
    padding-top: 3rem !important;
  }

  .lg\:pt-7 {
    padding-top: 4rem !important;
  }

  .lg\:pt-8 {
    padding-top: 5rem !important;
  }

  .lg\:pr-0 {
    padding-right: 0 !important;
  }

  .lg\:pr-1 {
    padding-right: .25rem !important;
  }

  .lg\:pr-2 {
    padding-right: .5rem !important;
  }

  .lg\:pr-3 {
    padding-right: 1rem !important;
  }

  .lg\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .lg\:pr-5 {
    padding-right: 2rem !important;
  }

  .lg\:pr-6 {
    padding-right: 3rem !important;
  }

  .lg\:pr-7 {
    padding-right: 4rem !important;
  }

  .lg\:pr-8 {
    padding-right: 5rem !important;
  }

  .lg\:pl-0 {
    padding-left: 0 !important;
  }

  .lg\:pl-1 {
    padding-left: .25rem !important;
  }

  .lg\:pl-2 {
    padding-left: .5rem !important;
  }

  .lg\:pl-3 {
    padding-left: 1rem !important;
  }

  .lg\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .lg\:pl-5 {
    padding-left: 2rem !important;
  }

  .lg\:pl-6 {
    padding-left: 3rem !important;
  }

  .lg\:pl-7 {
    padding-left: 4rem !important;
  }

  .lg\:pl-8 {
    padding-left: 5rem !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .lg\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .lg\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .lg\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .lg\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .lg\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .lg\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .lg\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .lg\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .lg\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:p-0 {
    padding: 0 !important;
  }

  .xl\:p-1 {
    padding: .25rem !important;
  }

  .xl\:p-2 {
    padding: .5rem !important;
  }

  .xl\:p-3 {
    padding: 1rem !important;
  }

  .xl\:p-4 {
    padding: 1.5rem !important;
  }

  .xl\:p-5 {
    padding: 2rem !important;
  }

  .xl\:p-6 {
    padding: 3rem !important;
  }

  .xl\:p-7 {
    padding: 4rem !important;
  }

  .xl\:p-8 {
    padding: 5rem !important;
  }

  .xl\:pt-0 {
    padding-top: 0 !important;
  }

  .xl\:pt-1 {
    padding-top: .25rem !important;
  }

  .xl\:pt-2 {
    padding-top: .5rem !important;
  }

  .xl\:pt-3 {
    padding-top: 1rem !important;
  }

  .xl\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .xl\:pt-5 {
    padding-top: 2rem !important;
  }

  .xl\:pt-6 {
    padding-top: 3rem !important;
  }

  .xl\:pt-7 {
    padding-top: 4rem !important;
  }

  .xl\:pt-8 {
    padding-top: 5rem !important;
  }

  .xl\:pr-0 {
    padding-right: 0 !important;
  }

  .xl\:pr-1 {
    padding-right: .25rem !important;
  }

  .xl\:pr-2 {
    padding-right: .5rem !important;
  }

  .xl\:pr-3 {
    padding-right: 1rem !important;
  }

  .xl\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .xl\:pr-5 {
    padding-right: 2rem !important;
  }

  .xl\:pr-6 {
    padding-right: 3rem !important;
  }

  .xl\:pr-7 {
    padding-right: 4rem !important;
  }

  .xl\:pr-8 {
    padding-right: 5rem !important;
  }

  .xl\:pl-0 {
    padding-left: 0 !important;
  }

  .xl\:pl-1 {
    padding-left: .25rem !important;
  }

  .xl\:pl-2 {
    padding-left: .5rem !important;
  }

  .xl\:pl-3 {
    padding-left: 1rem !important;
  }

  .xl\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .xl\:pl-5 {
    padding-left: 2rem !important;
  }

  .xl\:pl-6 {
    padding-left: 3rem !important;
  }

  .xl\:pl-7 {
    padding-left: 4rem !important;
  }

  .xl\:pl-8 {
    padding-left: 5rem !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xl\:pb-1 {
    padding-bottom: .25rem !important;
  }

  .xl\:pb-2 {
    padding-bottom: .5rem !important;
  }

  .xl\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .xl\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .xl\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .xl\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .xl\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .xl\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xl\:px-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .xl\:px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .xl\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xl\:py-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .xl\:py-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .xl\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.-m-1 {
  margin: -.25rem !important;
}

.-m-2 {
  margin: -.5rem !important;
}

.-m-3 {
  margin: -1rem !important;
}

.-m-4 {
  margin: -1.5rem !important;
}

.-m-5 {
  margin: -2rem !important;
}

.-m-6 {
  margin: -3rem !important;
}

.-m-7 {
  margin: -4rem !important;
}

.-m-8 {
  margin: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.-mt-1 {
  margin-top: -.25rem !important;
}

.-mt-2 {
  margin-top: -.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}

.-mt-6 {
  margin-top: -3rem !important;
}

.-mt-7 {
  margin-top: -4rem !important;
}

.-mt-8 {
  margin-top: -5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-7 {
  margin-right: 4rem !important;
}

.mr-8 {
  margin-right: 5rem !important;
}

.-mr-1 {
  margin-right: -.25rem !important;
}

.-mr-2 {
  margin-right: -.5rem !important;
}

.-mr-3 {
  margin-right: -1rem !important;
}

.-mr-4 {
  margin-right: -1.5rem !important;
}

.-mr-5 {
  margin-right: -2rem !important;
}

.-mr-6 {
  margin-right: -3rem !important;
}

.-mr-7 {
  margin-right: -4rem !important;
}

.-mr-8 {
  margin-right: -5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 5rem !important;
}

.-ml-1 {
  margin-left: -.25rem !important;
}

.-ml-2 {
  margin-left: -.5rem !important;
}

.-ml-3 {
  margin-left: -1rem !important;
}

.-ml-4 {
  margin-left: -1.5rem !important;
}

.-ml-5 {
  margin-left: -2rem !important;
}

.-ml-6 {
  margin-left: -3rem !important;
}

.-ml-7 {
  margin-left: -4rem !important;
}

.-ml-8 {
  margin-left: -5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.-mb-1 {
  margin-bottom: -.25rem !important;
}

.-mb-2 {
  margin-bottom: -.5rem !important;
}

.-mb-3 {
  margin-bottom: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1.5rem !important;
}

.-mb-5 {
  margin-bottom: -2rem !important;
}

.-mb-6 {
  margin-bottom: -3rem !important;
}

.-mb-7 {
  margin-bottom: -4rem !important;
}

.-mb-8 {
  margin-bottom: -5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.-mx-1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.-mx-2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.-mx-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-7 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-8 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.-my-1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.-my-2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.-my-3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (width >= 576px) {
  .sm\:m-0 {
    margin: 0 !important;
  }

  .sm\:m-1 {
    margin: .25rem !important;
  }

  .sm\:m-2 {
    margin: .5rem !important;
  }

  .sm\:m-3 {
    margin: 1rem !important;
  }

  .sm\:m-4 {
    margin: 1.5rem !important;
  }

  .sm\:m-5 {
    margin: 2rem !important;
  }

  .sm\:m-6 {
    margin: 3rem !important;
  }

  .sm\:m-7 {
    margin: 4rem !important;
  }

  .sm\:m-8 {
    margin: 5rem !important;
  }

  .sm\:-m-1 {
    margin: -.25rem !important;
  }

  .sm\:-m-2 {
    margin: -.5rem !important;
  }

  .sm\:-m-3 {
    margin: -1rem !important;
  }

  .sm\:-m-4 {
    margin: -1.5rem !important;
  }

  .sm\:-m-5 {
    margin: -2rem !important;
  }

  .sm\:-m-6 {
    margin: -3rem !important;
  }

  .sm\:-m-7 {
    margin: -4rem !important;
  }

  .sm\:-m-8 {
    margin: -5rem !important;
  }

  .sm\:m-auto {
    margin: auto !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mt-1 {
    margin-top: .25rem !important;
  }

  .sm\:mt-2 {
    margin-top: .5rem !important;
  }

  .sm\:mt-3 {
    margin-top: 1rem !important;
  }

  .sm\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .sm\:mt-5 {
    margin-top: 2rem !important;
  }

  .sm\:mt-6 {
    margin-top: 3rem !important;
  }

  .sm\:mt-7 {
    margin-top: 4rem !important;
  }

  .sm\:mt-8 {
    margin-top: 5rem !important;
  }

  .sm\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .sm\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .sm\:-mt-3 {
    margin-top: -1rem !important;
  }

  .sm\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -2rem !important;
  }

  .sm\:-mt-6 {
    margin-top: -3rem !important;
  }

  .sm\:-mt-7 {
    margin-top: -4rem !important;
  }

  .sm\:-mt-8 {
    margin-top: -5rem !important;
  }

  .sm\:mt-auto {
    margin-top: auto !important;
  }

  .sm\:mr-0 {
    margin-right: 0 !important;
  }

  .sm\:mr-1 {
    margin-right: .25rem !important;
  }

  .sm\:mr-2 {
    margin-right: .5rem !important;
  }

  .sm\:mr-3 {
    margin-right: 1rem !important;
  }

  .sm\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .sm\:mr-5 {
    margin-right: 2rem !important;
  }

  .sm\:mr-6 {
    margin-right: 3rem !important;
  }

  .sm\:mr-7 {
    margin-right: 4rem !important;
  }

  .sm\:mr-8 {
    margin-right: 5rem !important;
  }

  .sm\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .sm\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .sm\:-mr-3 {
    margin-right: -1rem !important;
  }

  .sm\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .sm\:-mr-5 {
    margin-right: -2rem !important;
  }

  .sm\:-mr-6 {
    margin-right: -3rem !important;
  }

  .sm\:-mr-7 {
    margin-right: -4rem !important;
  }

  .sm\:-mr-8 {
    margin-right: -5rem !important;
  }

  .sm\:mr-auto {
    margin-right: auto !important;
  }

  .sm\:ml-0 {
    margin-left: 0 !important;
  }

  .sm\:ml-1 {
    margin-left: .25rem !important;
  }

  .sm\:ml-2 {
    margin-left: .5rem !important;
  }

  .sm\:ml-3 {
    margin-left: 1rem !important;
  }

  .sm\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .sm\:ml-5 {
    margin-left: 2rem !important;
  }

  .sm\:ml-6 {
    margin-left: 3rem !important;
  }

  .sm\:ml-7 {
    margin-left: 4rem !important;
  }

  .sm\:ml-8 {
    margin-left: 5rem !important;
  }

  .sm\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .sm\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .sm\:-ml-3 {
    margin-left: -1rem !important;
  }

  .sm\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .sm\:-ml-5 {
    margin-left: -2rem !important;
  }

  .sm\:-ml-6 {
    margin-left: -3rem !important;
  }

  .sm\:-ml-7 {
    margin-left: -4rem !important;
  }

  .sm\:-ml-8 {
    margin-left: -5rem !important;
  }

  .sm\:ml-auto {
    margin-left: auto !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .sm\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .sm\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .sm\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .sm\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .sm\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .sm\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .sm\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .sm\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .sm\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .sm\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .sm\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .sm\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .sm\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sm\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .sm\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .sm\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .sm\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sm\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .sm\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .sm\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .sm\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .sm\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (width >= 768px) {
  .md\:m-0 {
    margin: 0 !important;
  }

  .md\:m-1 {
    margin: .25rem !important;
  }

  .md\:m-2 {
    margin: .5rem !important;
  }

  .md\:m-3 {
    margin: 1rem !important;
  }

  .md\:m-4 {
    margin: 1.5rem !important;
  }

  .md\:m-5 {
    margin: 2rem !important;
  }

  .md\:m-6 {
    margin: 3rem !important;
  }

  .md\:m-7 {
    margin: 4rem !important;
  }

  .md\:m-8 {
    margin: 5rem !important;
  }

  .md\:-m-1 {
    margin: -.25rem !important;
  }

  .md\:-m-2 {
    margin: -.5rem !important;
  }

  .md\:-m-3 {
    margin: -1rem !important;
  }

  .md\:-m-4 {
    margin: -1.5rem !important;
  }

  .md\:-m-5 {
    margin: -2rem !important;
  }

  .md\:-m-6 {
    margin: -3rem !important;
  }

  .md\:-m-7 {
    margin: -4rem !important;
  }

  .md\:-m-8 {
    margin: -5rem !important;
  }

  .md\:m-auto {
    margin: auto !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:mt-1 {
    margin-top: .25rem !important;
  }

  .md\:mt-2 {
    margin-top: .5rem !important;
  }

  .md\:mt-3 {
    margin-top: 1rem !important;
  }

  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .md\:mt-5 {
    margin-top: 2rem !important;
  }

  .md\:mt-6 {
    margin-top: 3rem !important;
  }

  .md\:mt-7 {
    margin-top: 4rem !important;
  }

  .md\:mt-8 {
    margin-top: 5rem !important;
  }

  .md\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .md\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .md\:-mt-3 {
    margin-top: -1rem !important;
  }

  .md\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .md\:-mt-5 {
    margin-top: -2rem !important;
  }

  .md\:-mt-6 {
    margin-top: -3rem !important;
  }

  .md\:-mt-7 {
    margin-top: -4rem !important;
  }

  .md\:-mt-8 {
    margin-top: -5rem !important;
  }

  .md\:mt-auto {
    margin-top: auto !important;
  }

  .md\:mr-0 {
    margin-right: 0 !important;
  }

  .md\:mr-1 {
    margin-right: .25rem !important;
  }

  .md\:mr-2 {
    margin-right: .5rem !important;
  }

  .md\:mr-3 {
    margin-right: 1rem !important;
  }

  .md\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .md\:mr-5 {
    margin-right: 2rem !important;
  }

  .md\:mr-6 {
    margin-right: 3rem !important;
  }

  .md\:mr-7 {
    margin-right: 4rem !important;
  }

  .md\:mr-8 {
    margin-right: 5rem !important;
  }

  .md\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .md\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .md\:-mr-3 {
    margin-right: -1rem !important;
  }

  .md\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .md\:-mr-5 {
    margin-right: -2rem !important;
  }

  .md\:-mr-6 {
    margin-right: -3rem !important;
  }

  .md\:-mr-7 {
    margin-right: -4rem !important;
  }

  .md\:-mr-8 {
    margin-right: -5rem !important;
  }

  .md\:mr-auto {
    margin-right: auto !important;
  }

  .md\:ml-0 {
    margin-left: 0 !important;
  }

  .md\:ml-1 {
    margin-left: .25rem !important;
  }

  .md\:ml-2 {
    margin-left: .5rem !important;
  }

  .md\:ml-3 {
    margin-left: 1rem !important;
  }

  .md\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .md\:ml-5 {
    margin-left: 2rem !important;
  }

  .md\:ml-6 {
    margin-left: 3rem !important;
  }

  .md\:ml-7 {
    margin-left: 4rem !important;
  }

  .md\:ml-8 {
    margin-left: 5rem !important;
  }

  .md\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .md\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .md\:-ml-3 {
    margin-left: -1rem !important;
  }

  .md\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .md\:-ml-5 {
    margin-left: -2rem !important;
  }

  .md\:-ml-6 {
    margin-left: -3rem !important;
  }

  .md\:-ml-7 {
    margin-left: -4rem !important;
  }

  .md\:-ml-8 {
    margin-left: -5rem !important;
  }

  .md\:ml-auto {
    margin-left: auto !important;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .md\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .md\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .md\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .md\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .md\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .md\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .md\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .md\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .md\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .md\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .md\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .md\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .md\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .md\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .md\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .md\:mb-auto {
    margin-bottom: auto !important;
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .md\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .md\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .md\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .md\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .md\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .md\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .md\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .md\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .md\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .md\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:m-0 {
    margin: 0 !important;
  }

  .lg\:m-1 {
    margin: .25rem !important;
  }

  .lg\:m-2 {
    margin: .5rem !important;
  }

  .lg\:m-3 {
    margin: 1rem !important;
  }

  .lg\:m-4 {
    margin: 1.5rem !important;
  }

  .lg\:m-5 {
    margin: 2rem !important;
  }

  .lg\:m-6 {
    margin: 3rem !important;
  }

  .lg\:m-7 {
    margin: 4rem !important;
  }

  .lg\:m-8 {
    margin: 5rem !important;
  }

  .lg\:-m-1 {
    margin: -.25rem !important;
  }

  .lg\:-m-2 {
    margin: -.5rem !important;
  }

  .lg\:-m-3 {
    margin: -1rem !important;
  }

  .lg\:-m-4 {
    margin: -1.5rem !important;
  }

  .lg\:-m-5 {
    margin: -2rem !important;
  }

  .lg\:-m-6 {
    margin: -3rem !important;
  }

  .lg\:-m-7 {
    margin: -4rem !important;
  }

  .lg\:-m-8 {
    margin: -5rem !important;
  }

  .lg\:m-auto {
    margin: auto !important;
  }

  .lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lg\:mt-1 {
    margin-top: .25rem !important;
  }

  .lg\:mt-2 {
    margin-top: .5rem !important;
  }

  .lg\:mt-3 {
    margin-top: 1rem !important;
  }

  .lg\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .lg\:mt-5 {
    margin-top: 2rem !important;
  }

  .lg\:mt-6 {
    margin-top: 3rem !important;
  }

  .lg\:mt-7 {
    margin-top: 4rem !important;
  }

  .lg\:mt-8 {
    margin-top: 5rem !important;
  }

  .lg\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .lg\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .lg\:-mt-3 {
    margin-top: -1rem !important;
  }

  .lg\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .lg\:-mt-5 {
    margin-top: -2rem !important;
  }

  .lg\:-mt-6 {
    margin-top: -3rem !important;
  }

  .lg\:-mt-7 {
    margin-top: -4rem !important;
  }

  .lg\:-mt-8 {
    margin-top: -5rem !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:mr-0 {
    margin-right: 0 !important;
  }

  .lg\:mr-1 {
    margin-right: .25rem !important;
  }

  .lg\:mr-2 {
    margin-right: .5rem !important;
  }

  .lg\:mr-3 {
    margin-right: 1rem !important;
  }

  .lg\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .lg\:mr-5 {
    margin-right: 2rem !important;
  }

  .lg\:mr-6 {
    margin-right: 3rem !important;
  }

  .lg\:mr-7 {
    margin-right: 4rem !important;
  }

  .lg\:mr-8 {
    margin-right: 5rem !important;
  }

  .lg\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .lg\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .lg\:-mr-3 {
    margin-right: -1rem !important;
  }

  .lg\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .lg\:-mr-5 {
    margin-right: -2rem !important;
  }

  .lg\:-mr-6 {
    margin-right: -3rem !important;
  }

  .lg\:-mr-7 {
    margin-right: -4rem !important;
  }

  .lg\:-mr-8 {
    margin-right: -5rem !important;
  }

  .lg\:mr-auto {
    margin-right: auto !important;
  }

  .lg\:ml-0 {
    margin-left: 0 !important;
  }

  .lg\:ml-1 {
    margin-left: .25rem !important;
  }

  .lg\:ml-2 {
    margin-left: .5rem !important;
  }

  .lg\:ml-3 {
    margin-left: 1rem !important;
  }

  .lg\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .lg\:ml-5 {
    margin-left: 2rem !important;
  }

  .lg\:ml-6 {
    margin-left: 3rem !important;
  }

  .lg\:ml-7 {
    margin-left: 4rem !important;
  }

  .lg\:ml-8 {
    margin-left: 5rem !important;
  }

  .lg\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .lg\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .lg\:-ml-3 {
    margin-left: -1rem !important;
  }

  .lg\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .lg\:-ml-5 {
    margin-left: -2rem !important;
  }

  .lg\:-ml-6 {
    margin-left: -3rem !important;
  }

  .lg\:-ml-7 {
    margin-left: -4rem !important;
  }

  .lg\:-ml-8 {
    margin-left: -5rem !important;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .lg\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .lg\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .lg\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .lg\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .lg\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .lg\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .lg\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .lg\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .lg\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .lg\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .lg\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .lg\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .lg\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lg\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .lg\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .lg\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .lg\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .lg\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lg\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .lg\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .lg\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .lg\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .lg\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:m-0 {
    margin: 0 !important;
  }

  .xl\:m-1 {
    margin: .25rem !important;
  }

  .xl\:m-2 {
    margin: .5rem !important;
  }

  .xl\:m-3 {
    margin: 1rem !important;
  }

  .xl\:m-4 {
    margin: 1.5rem !important;
  }

  .xl\:m-5 {
    margin: 2rem !important;
  }

  .xl\:m-6 {
    margin: 3rem !important;
  }

  .xl\:m-7 {
    margin: 4rem !important;
  }

  .xl\:m-8 {
    margin: 5rem !important;
  }

  .xl\:-m-1 {
    margin: -.25rem !important;
  }

  .xl\:-m-2 {
    margin: -.5rem !important;
  }

  .xl\:-m-3 {
    margin: -1rem !important;
  }

  .xl\:-m-4 {
    margin: -1.5rem !important;
  }

  .xl\:-m-5 {
    margin: -2rem !important;
  }

  .xl\:-m-6 {
    margin: -3rem !important;
  }

  .xl\:-m-7 {
    margin: -4rem !important;
  }

  .xl\:-m-8 {
    margin: -5rem !important;
  }

  .xl\:m-auto {
    margin: auto !important;
  }

  .xl\:mt-0 {
    margin-top: 0 !important;
  }

  .xl\:mt-1 {
    margin-top: .25rem !important;
  }

  .xl\:mt-2 {
    margin-top: .5rem !important;
  }

  .xl\:mt-3 {
    margin-top: 1rem !important;
  }

  .xl\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .xl\:mt-5 {
    margin-top: 2rem !important;
  }

  .xl\:mt-6 {
    margin-top: 3rem !important;
  }

  .xl\:mt-7 {
    margin-top: 4rem !important;
  }

  .xl\:mt-8 {
    margin-top: 5rem !important;
  }

  .xl\:-mt-1 {
    margin-top: -.25rem !important;
  }

  .xl\:-mt-2 {
    margin-top: -.5rem !important;
  }

  .xl\:-mt-3 {
    margin-top: -1rem !important;
  }

  .xl\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .xl\:-mt-5 {
    margin-top: -2rem !important;
  }

  .xl\:-mt-6 {
    margin-top: -3rem !important;
  }

  .xl\:-mt-7 {
    margin-top: -4rem !important;
  }

  .xl\:-mt-8 {
    margin-top: -5rem !important;
  }

  .xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:mr-0 {
    margin-right: 0 !important;
  }

  .xl\:mr-1 {
    margin-right: .25rem !important;
  }

  .xl\:mr-2 {
    margin-right: .5rem !important;
  }

  .xl\:mr-3 {
    margin-right: 1rem !important;
  }

  .xl\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .xl\:mr-5 {
    margin-right: 2rem !important;
  }

  .xl\:mr-6 {
    margin-right: 3rem !important;
  }

  .xl\:mr-7 {
    margin-right: 4rem !important;
  }

  .xl\:mr-8 {
    margin-right: 5rem !important;
  }

  .xl\:-mr-1 {
    margin-right: -.25rem !important;
  }

  .xl\:-mr-2 {
    margin-right: -.5rem !important;
  }

  .xl\:-mr-3 {
    margin-right: -1rem !important;
  }

  .xl\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .xl\:-mr-5 {
    margin-right: -2rem !important;
  }

  .xl\:-mr-6 {
    margin-right: -3rem !important;
  }

  .xl\:-mr-7 {
    margin-right: -4rem !important;
  }

  .xl\:-mr-8 {
    margin-right: -5rem !important;
  }

  .xl\:mr-auto {
    margin-right: auto !important;
  }

  .xl\:ml-0 {
    margin-left: 0 !important;
  }

  .xl\:ml-1 {
    margin-left: .25rem !important;
  }

  .xl\:ml-2 {
    margin-left: .5rem !important;
  }

  .xl\:ml-3 {
    margin-left: 1rem !important;
  }

  .xl\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .xl\:ml-5 {
    margin-left: 2rem !important;
  }

  .xl\:ml-6 {
    margin-left: 3rem !important;
  }

  .xl\:ml-7 {
    margin-left: 4rem !important;
  }

  .xl\:ml-8 {
    margin-left: 5rem !important;
  }

  .xl\:-ml-1 {
    margin-left: -.25rem !important;
  }

  .xl\:-ml-2 {
    margin-left: -.5rem !important;
  }

  .xl\:-ml-3 {
    margin-left: -1rem !important;
  }

  .xl\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .xl\:-ml-5 {
    margin-left: -2rem !important;
  }

  .xl\:-ml-6 {
    margin-left: -3rem !important;
  }

  .xl\:-ml-7 {
    margin-left: -4rem !important;
  }

  .xl\:-ml-8 {
    margin-left: -5rem !important;
  }

  .xl\:ml-auto {
    margin-left: auto !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xl\:mb-1 {
    margin-bottom: .25rem !important;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem !important;
  }

  .xl\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .xl\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .xl\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .xl\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .xl\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .xl\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -.25rem !important;
  }

  .xl\:-mb-2 {
    margin-bottom: -.5rem !important;
  }

  .xl\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .xl\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .xl\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .xl\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .xl\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .xl\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xl\:mx-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .xl\:mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .xl\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:-mx-1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .xl\:-mx-2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .xl\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xl\:my-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .xl\:my-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .xl\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:-my-1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .xl\:-my-2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .xl\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
}

.shadow-2 {
  box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
}

.shadow-3 {
  box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
}

.shadow-4 {
  box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
}

.shadow-5 {
  box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
}

.shadow-6 {
  box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
}

.shadow-7 {
  box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
}

.shadow-8 {
  box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
}

.focus\:shadow-none:focus, .hover\:shadow-none:hover, .active\:shadow-none:active {
  box-shadow: none !important;
}

.focus\:shadow-1:focus, .hover\:shadow-1:hover, .active\:shadow-1:active {
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
}

.focus\:shadow-2:focus, .hover\:shadow-2:hover, .active\:shadow-2:active {
  box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
}

.focus\:shadow-3:focus, .hover\:shadow-3:hover, .active\:shadow-3:active {
  box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
}

.focus\:shadow-4:focus, .hover\:shadow-4:hover, .active\:shadow-4:active {
  box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
}

.focus\:shadow-5:focus, .hover\:shadow-5:hover, .active\:shadow-5:active {
  box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
}

.focus\:shadow-6:focus, .hover\:shadow-6:hover, .active\:shadow-6:active {
  box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
}

.focus\:shadow-7:focus, .hover\:shadow-7:hover, .active\:shadow-7:active {
  box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
}

.focus\:shadow-8:focus, .hover\:shadow-8:hover, .active\:shadow-8:active {
  box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
}

@media screen and (width >= 576px) {
  .sm\:shadow-none {
    box-shadow: none !important;
  }

  .sm\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .sm\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .sm\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .sm\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .sm\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .sm\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .sm\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .sm\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .sm\:focus\:shadow-none:focus, .sm\:hover\:shadow-none:hover, .sm\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .sm\:focus\:shadow-1:focus, .sm\:hover\:shadow-1:hover, .sm\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .sm\:focus\:shadow-2:focus, .sm\:hover\:shadow-2:hover, .sm\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .sm\:focus\:shadow-3:focus, .sm\:hover\:shadow-3:hover, .sm\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .sm\:focus\:shadow-4:focus, .sm\:hover\:shadow-4:hover, .sm\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .sm\:focus\:shadow-5:focus, .sm\:hover\:shadow-5:hover, .sm\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .sm\:focus\:shadow-6:focus, .sm\:hover\:shadow-6:hover, .sm\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .sm\:focus\:shadow-7:focus, .sm\:hover\:shadow-7:hover, .sm\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .sm\:focus\:shadow-8:focus, .sm\:hover\:shadow-8:hover, .sm\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:shadow-none {
    box-shadow: none !important;
  }

  .md\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .md\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .md\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .md\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .md\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .md\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .md\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .md\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .md\:focus\:shadow-none:focus, .md\:hover\:shadow-none:hover, .md\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .md\:focus\:shadow-1:focus, .md\:hover\:shadow-1:hover, .md\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .md\:focus\:shadow-2:focus, .md\:hover\:shadow-2:hover, .md\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .md\:focus\:shadow-3:focus, .md\:hover\:shadow-3:hover, .md\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .md\:focus\:shadow-4:focus, .md\:hover\:shadow-4:hover, .md\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .md\:focus\:shadow-5:focus, .md\:hover\:shadow-5:hover, .md\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .md\:focus\:shadow-6:focus, .md\:hover\:shadow-6:hover, .md\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .md\:focus\:shadow-7:focus, .md\:hover\:shadow-7:hover, .md\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .md\:focus\:shadow-8:focus, .md\:hover\:shadow-8:hover, .md\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:shadow-none {
    box-shadow: none !important;
  }

  .lg\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .lg\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .lg\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .lg\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .lg\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .lg\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .lg\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .lg\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .lg\:focus\:shadow-none:focus, .lg\:hover\:shadow-none:hover, .lg\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .lg\:focus\:shadow-1:focus, .lg\:hover\:shadow-1:hover, .lg\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .lg\:focus\:shadow-2:focus, .lg\:hover\:shadow-2:hover, .lg\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .lg\:focus\:shadow-3:focus, .lg\:hover\:shadow-3:hover, .lg\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .lg\:focus\:shadow-4:focus, .lg\:hover\:shadow-4:hover, .lg\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .lg\:focus\:shadow-5:focus, .lg\:hover\:shadow-5:hover, .lg\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .lg\:focus\:shadow-6:focus, .lg\:hover\:shadow-6:hover, .lg\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .lg\:focus\:shadow-7:focus, .lg\:hover\:shadow-7:hover, .lg\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .lg\:focus\:shadow-8:focus, .lg\:hover\:shadow-8:hover, .lg\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:shadow-none {
    box-shadow: none !important;
  }

  .xl\:shadow-1 {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .xl\:shadow-2 {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .xl\:shadow-3 {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .xl\:shadow-4 {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .xl\:shadow-5 {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .xl\:shadow-6 {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .xl\:shadow-7 {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .xl\:shadow-8 {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }

  .xl\:focus\:shadow-none:focus, .xl\:hover\:shadow-none:hover, .xl\:active\:shadow-none:active {
    box-shadow: none !important;
  }

  .xl\:focus\:shadow-1:focus, .xl\:hover\:shadow-1:hover, .xl\:active\:shadow-1:active {
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  }

  .xl\:focus\:shadow-2:focus, .xl\:hover\:shadow-2:hover, .xl\:active\:shadow-2:active {
    box-shadow: 0 4px 10px #00000008, 0 0 2px #0000000f, 0 2px 6px #0000001f !important;
  }

  .xl\:focus\:shadow-3:focus, .xl\:hover\:shadow-3:hover, .xl\:active\:shadow-3:active {
    box-shadow: 0 1px 8px #00000014, 0 3px 4px #0000001a, 0 1px 4px -1px #0000001a !important;
  }

  .xl\:focus\:shadow-4:focus, .xl\:hover\:shadow-4:hover, .xl\:active\:shadow-4:active {
    box-shadow: 0 1px 10px #0000001f, 0 4px 5px #00000024, 0 2px 4px -1px #0003 !important;
  }

  .xl\:focus\:shadow-5:focus, .xl\:hover\:shadow-5:hover, .xl\:active\:shadow-5:active {
    box-shadow: 0 1px 7px #0000001a, 0 4px 5px -2px #0000001f, 0 10px 15px -5px #0003 !important;
  }

  .xl\:focus\:shadow-6:focus, .xl\:hover\:shadow-6:hover, .xl\:active\:shadow-6:active {
    box-shadow: 0 3px 5px #0000000f, 0 7px 9px #0000001f, 0 20px 25px -8px #0000002e !important;
  }

  .xl\:focus\:shadow-7:focus, .xl\:hover\:shadow-7:hover, .xl\:active\:shadow-7:active {
    box-shadow: 0 7px 30px #00000014, 0 22px 30px 2px #00000026, 0 8px 10px #00000026 !important;
  }

  .xl\:focus\:shadow-8:focus, .xl\:hover\:shadow-8:hover, .xl\:active\:shadow-8:active {
    box-shadow: 0 9px 46px 8px #0000001f, 0 24px 38px 3px #00000024, 0 11px 15px #0003 !important;
  }
}

.border-none {
  border-style: none;
  border-width: 0 !important;
}

.border-1 {
  border-style: solid;
  border-width: 1px !important;
}

.border-2 {
  border-style: solid;
  border-width: 2px !important;
}

.border-3 {
  border-style: solid;
  border-width: 3px !important;
}

.border-top-none {
  border-top-style: none;
  border-top-width: 0 !important;
}

.border-top-1 {
  border-top-style: solid;
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-style: solid;
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-style: solid;
  border-top-width: 3px !important;
}

.border-right-none {
  border-right-style: none;
  border-right-width: 0 !important;
}

.border-right-1 {
  border-right-style: solid;
  border-right-width: 1px !important;
}

.border-right-2 {
  border-right-style: solid;
  border-right-width: 2px !important;
}

.border-right-3 {
  border-right-style: solid;
  border-right-width: 3px !important;
}

.border-left-none {
  border-left-style: none;
  border-left-width: 0 !important;
}

.border-left-1 {
  border-left-style: solid;
  border-left-width: 1px !important;
}

.border-left-2 {
  border-left-style: solid;
  border-left-width: 2px !important;
}

.border-left-3 {
  border-left-style: solid;
  border-left-width: 3px !important;
}

.border-bottom-none {
  border-bottom-style: none;
  border-bottom-width: 0 !important;
}

.border-bottom-1 {
  border-bottom-style: solid;
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-style: solid;
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-style: solid;
  border-bottom-width: 3px !important;
}

.border-x-none {
  border-left-style: none;
  border-right-style: none;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}

.border-x-1 {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.border-x-2 {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.border-x-3 {
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.border-y-none {
  border-top-style: none;
  border-bottom-style: none;
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.border-y-1 {
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.border-y-2 {
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.border-y-3 {
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

@media screen and (width >= 576px) {
  .sm\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .sm\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .sm\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .sm\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .sm\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .sm\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .sm\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .sm\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .sm\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .sm\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .sm\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .sm\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .sm\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .sm\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .sm\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .sm\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .sm\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .sm\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .sm\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .sm\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .sm\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .sm\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .sm\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .sm\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .sm\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .sm\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .sm\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .sm\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

@media screen and (width >= 768px) {
  .md\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .md\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .md\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .md\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .md\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .md\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .md\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .md\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .md\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .md\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .md\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .md\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .md\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .md\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .md\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .md\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .md\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .md\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .md\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .md\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .md\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .md\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .md\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .md\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .md\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .md\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .md\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .md\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .lg\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .lg\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .lg\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .lg\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .lg\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .lg\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .lg\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .lg\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .lg\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .lg\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .lg\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .lg\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .lg\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .lg\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .lg\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .lg\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .lg\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .lg\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .lg\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .lg\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .lg\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .lg\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .lg\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .lg\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .lg\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .lg\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .lg\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:border-none {
    border-style: none;
    border-width: 0 !important;
  }

  .xl\:border-1 {
    border-style: solid;
    border-width: 1px !important;
  }

  .xl\:border-2 {
    border-style: solid;
    border-width: 2px !important;
  }

  .xl\:border-3 {
    border-style: solid;
    border-width: 3px !important;
  }

  .xl\:border-top-none {
    border-top-style: none;
    border-top-width: 0 !important;
  }

  .xl\:border-top-1 {
    border-top-style: solid;
    border-top-width: 1px !important;
  }

  .xl\:border-top-2 {
    border-top-style: solid;
    border-top-width: 2px !important;
  }

  .xl\:border-top-3 {
    border-top-style: solid;
    border-top-width: 3px !important;
  }

  .xl\:border-right-none {
    border-right-style: none;
    border-right-width: 0 !important;
  }

  .xl\:border-right-1 {
    border-right-style: solid;
    border-right-width: 1px !important;
  }

  .xl\:border-right-2 {
    border-right-style: solid;
    border-right-width: 2px !important;
  }

  .xl\:border-right-3 {
    border-right-style: solid;
    border-right-width: 3px !important;
  }

  .xl\:border-left-none {
    border-left-style: none;
    border-left-width: 0 !important;
  }

  .xl\:border-left-1 {
    border-left-style: solid;
    border-left-width: 1px !important;
  }

  .xl\:border-left-2 {
    border-left-style: solid;
    border-left-width: 2px !important;
  }

  .xl\:border-left-3 {
    border-left-style: solid;
    border-left-width: 3px !important;
  }

  .xl\:border-bottom-none {
    border-bottom-style: none;
    border-bottom-width: 0 !important;
  }

  .xl\:border-bottom-1 {
    border-bottom-style: solid;
    border-bottom-width: 1px !important;
  }

  .xl\:border-bottom-2 {
    border-bottom-style: solid;
    border-bottom-width: 2px !important;
  }

  .xl\:border-bottom-3 {
    border-bottom-style: solid;
    border-bottom-width: 3px !important;
  }

  .xl\:border-x-none {
    border-left-style: none;
    border-right-style: none;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

  .xl\:border-x-1 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
  }

  .xl\:border-x-2 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 2px !important;
    border-right-width: 2px !important;
  }

  .xl\:border-x-3 {
    border-left-style: solid;
    border-right-style: solid;
    border-left-width: 3px !important;
    border-right-width: 3px !important;
  }

  .xl\:border-y-none {
    border-top-style: none;
    border-bottom-style: none;
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
  }

  .xl\:border-y-1 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
  }

  .xl\:border-y-2 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;
  }

  .xl\:border-y-3 {
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-width: 3px !important;
    border-bottom-width: 3px !important;
  }
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (width >= 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }

  .sm\:border-dashed {
    border-style: dashed !important;
  }

  .sm\:border-dotted {
    border-style: dotted !important;
  }

  .sm\:border-double {
    border-style: double !important;
  }
}

@media screen and (width >= 768px) {
  .md\:border-solid {
    border-style: solid !important;
  }

  .md\:border-dashed {
    border-style: dashed !important;
  }

  .md\:border-dotted {
    border-style: dotted !important;
  }

  .md\:border-double {
    border-style: double !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:border-solid {
    border-style: solid !important;
  }

  .lg\:border-dashed {
    border-style: dashed !important;
  }

  .lg\:border-dotted {
    border-style: dotted !important;
  }

  .lg\:border-double {
    border-style: double !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:border-solid {
    border-style: solid !important;
  }

  .xl\:border-dashed {
    border-style: dashed !important;
  }

  .xl\:border-dotted {
    border-style: dotted !important;
  }

  .xl\:border-double {
    border-style: double !important;
  }
}

.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-round-xs {
  border-radius: .125rem !important;
}

.border-round-sm {
  border-radius: .25rem !important;
}

.border-round-md {
  border-radius: .375rem !important;
}

.border-round-lg {
  border-radius: .5rem !important;
}

.border-round-xl {
  border-radius: .75rem !important;
}

.border-round-2xl {
  border-radius: 1rem !important;
}

.border-round-3xl {
  border-radius: 1.5rem !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (width >= 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }

  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .sm\:border-round-xs {
    border-radius: .125rem !important;
  }

  .sm\:border-round-sm {
    border-radius: .25rem !important;
  }

  .sm\:border-round-md {
    border-radius: .375rem !important;
  }

  .sm\:border-round-lg {
    border-radius: .5rem !important;
  }

  .sm\:border-round-xl {
    border-radius: .75rem !important;
  }

  .sm\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .sm\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .sm\:border-circle {
    border-radius: 50% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }

  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .md\:border-round-xs {
    border-radius: .125rem !important;
  }

  .md\:border-round-sm {
    border-radius: .25rem !important;
  }

  .md\:border-round-md {
    border-radius: .375rem !important;
  }

  .md\:border-round-lg {
    border-radius: .5rem !important;
  }

  .md\:border-round-xl {
    border-radius: .75rem !important;
  }

  .md\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .md\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .md\:border-circle {
    border-radius: 50% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }

  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .lg\:border-round-xs {
    border-radius: .125rem !important;
  }

  .lg\:border-round-sm {
    border-radius: .25rem !important;
  }

  .lg\:border-round-md {
    border-radius: .375rem !important;
  }

  .lg\:border-round-lg {
    border-radius: .5rem !important;
  }

  .lg\:border-round-xl {
    border-radius: .75rem !important;
  }

  .lg\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .lg\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .lg\:border-circle {
    border-radius: 50% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }

  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }

  .xl\:border-round-xs {
    border-radius: .125rem !important;
  }

  .xl\:border-round-sm {
    border-radius: .25rem !important;
  }

  .xl\:border-round-md {
    border-radius: .375rem !important;
  }

  .xl\:border-round-lg {
    border-radius: .5rem !important;
  }

  .xl\:border-round-xl {
    border-radius: .75rem !important;
  }

  .xl\:border-round-2xl {
    border-radius: 1rem !important;
  }

  .xl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }

  .xl\:border-circle {
    border-radius: 50% !important;
  }
}

.border-noround-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-noround-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-noround-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-noround-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-round-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.border-round-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.border-round-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-left-xs {
  border-top-left-radius: .125rem !important;
  border-bottom-left-radius: .125rem !important;
}

.border-round-top-xs {
  border-top-left-radius: .125rem !important;
  border-top-right-radius: .125rem !important;
}

.border-round-right-xs {
  border-top-right-radius: .125rem !important;
  border-bottom-right-radius: .125rem !important;
}

.border-round-bottom-xs {
  border-bottom-right-radius: .125rem !important;
  border-bottom-left-radius: .125rem !important;
}

.border-round-left-sm {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.border-round-top-sm {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.border-round-right-sm {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.border-round-bottom-sm {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.border-round-left-md {
  border-top-left-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.border-round-top-md {
  border-top-left-radius: .375rem !important;
  border-top-right-radius: .375rem !important;
}

.border-round-right-md {
  border-top-right-radius: .375rem !important;
  border-bottom-right-radius: .375rem !important;
}

.border-round-bottom-md {
  border-bottom-right-radius: .375rem !important;
  border-bottom-left-radius: .375rem !important;
}

.border-round-left-lg {
  border-top-left-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.border-round-top-lg {
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
}

.border-round-right-lg {
  border-top-right-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
}

.border-round-bottom-lg {
  border-bottom-right-radius: .5rem !important;
  border-bottom-left-radius: .5rem !important;
}

.border-round-left-xl {
  border-top-left-radius: .75rem !important;
  border-bottom-left-radius: .75rem !important;
}

.border-round-top-xl {
  border-top-left-radius: .75rem !important;
  border-top-right-radius: .75rem !important;
}

.border-round-right-xl {
  border-top-right-radius: .75rem !important;
  border-bottom-right-radius: .75rem !important;
}

.border-round-bottom-xl {
  border-bottom-right-radius: .75rem !important;
  border-bottom-left-radius: .75rem !important;
}

.border-round-left-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-top-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-round-right-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-bottom-2xl {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-left-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-round-top-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.border-round-right-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-round-bottom-3xl {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-circle-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.border-circle-top {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.border-circle-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.border-circle-bottom {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

@media screen and (width >= 576px) {
  .sm\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .sm\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .sm\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .sm\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .sm\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .sm\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .sm\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .sm\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .sm\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .sm\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .sm\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .sm\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .sm\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .sm\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .sm\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .sm\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .sm\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .sm\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .sm\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .sm\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .sm\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .sm\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .sm\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .sm\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .sm\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .sm\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .sm\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .sm\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .sm\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .sm\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .sm\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .sm\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .sm\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .md\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .md\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .md\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .md\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .md\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .md\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .md\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .md\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .md\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .md\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .md\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .md\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .md\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .md\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .md\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .md\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .md\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .md\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .md\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .md\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .md\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .md\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .md\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .md\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .md\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .md\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .md\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .md\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .md\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .md\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .md\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .md\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .md\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .md\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lg\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lg\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .lg\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .lg\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .lg\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .lg\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .lg\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .lg\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .lg\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .lg\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .lg\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .lg\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .lg\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .lg\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .lg\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .lg\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .lg\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .lg\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .lg\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .lg\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .lg\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .lg\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .lg\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .lg\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .lg\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .lg\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .lg\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .lg\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .lg\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .lg\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .lg\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .lg\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xl\:border-noround-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }

  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }

  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }

  .xl\:border-round-left-xs {
    border-top-left-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .xl\:border-round-top-xs {
    border-top-left-radius: .125rem !important;
    border-top-right-radius: .125rem !important;
  }

  .xl\:border-round-right-xs {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important;
  }

  .xl\:border-round-bottom-xs {
    border-bottom-right-radius: .125rem !important;
    border-bottom-left-radius: .125rem !important;
  }

  .xl\:border-round-left-sm {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .xl\:border-round-top-sm {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
  }

  .xl\:border-round-right-sm {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }

  .xl\:border-round-bottom-sm {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
  }

  .xl\:border-round-left-md {
    border-top-left-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .xl\:border-round-top-md {
    border-top-left-radius: .375rem !important;
    border-top-right-radius: .375rem !important;
  }

  .xl\:border-round-right-md {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
  }

  .xl\:border-round-bottom-md {
    border-bottom-right-radius: .375rem !important;
    border-bottom-left-radius: .375rem !important;
  }

  .xl\:border-round-left-lg {
    border-top-left-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .xl\:border-round-top-lg {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
  }

  .xl\:border-round-right-lg {
    border-top-right-radius: .5rem !important;
    border-bottom-right-radius: .5rem !important;
  }

  .xl\:border-round-bottom-lg {
    border-bottom-right-radius: .5rem !important;
    border-bottom-left-radius: .5rem !important;
  }

  .xl\:border-round-left-xl {
    border-top-left-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .xl\:border-round-top-xl {
    border-top-left-radius: .75rem !important;
    border-top-right-radius: .75rem !important;
  }

  .xl\:border-round-right-xl {
    border-top-right-radius: .75rem !important;
    border-bottom-right-radius: .75rem !important;
  }

  .xl\:border-round-bottom-xl {
    border-bottom-right-radius: .75rem !important;
    border-bottom-left-radius: .75rem !important;
  }

  .xl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .xl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }

  .xl\:border-round-bottom-2xl {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .xl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }

  .xl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }

  .xl\:border-round-bottom-3xl {
    border-bottom-right-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }

  .xl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .xl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }

  .xl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .xl\:border-circle-bottom {
    border-bottom-right-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (width >= 576px) {
  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-screen {
    width: 100vw !important;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-1 {
    width: 8.3333% !important;
  }

  .sm\:w-2 {
    width: 16.6667% !important;
  }

  .sm\:w-3 {
    width: 25% !important;
  }

  .sm\:w-4 {
    width: 33.3333% !important;
  }

  .sm\:w-5 {
    width: 41.6667% !important;
  }

  .sm\:w-6 {
    width: 50% !important;
  }

  .sm\:w-7 {
    width: 58.3333% !important;
  }

  .sm\:w-8 {
    width: 66.6667% !important;
  }

  .sm\:w-9 {
    width: 75% !important;
  }

  .sm\:w-10 {
    width: 83.3333% !important;
  }

  .sm\:w-11 {
    width: 91.6667% !important;
  }

  .sm\:w-12 {
    width: 100% !important;
  }

  .sm\:w-min {
    width: min-content !important;
  }

  .sm\:w-max {
    width: max-content !important;
  }

  .sm\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .sm\:w-1rem {
    width: 1rem !important;
  }

  .sm\:w-2rem {
    width: 2rem !important;
  }

  .sm\:w-3rem {
    width: 3rem !important;
  }

  .sm\:w-4rem {
    width: 4rem !important;
  }

  .sm\:w-5rem {
    width: 5rem !important;
  }

  .sm\:w-6rem {
    width: 6rem !important;
  }

  .sm\:w-7rem {
    width: 7rem !important;
  }

  .sm\:w-8rem {
    width: 8rem !important;
  }

  .sm\:w-9rem {
    width: 9rem !important;
  }

  .sm\:w-10rem {
    width: 10rem !important;
  }

  .sm\:w-11rem {
    width: 11rem !important;
  }

  .sm\:w-12rem {
    width: 12rem !important;
  }

  .sm\:w-13rem {
    width: 13rem !important;
  }

  .sm\:w-14rem {
    width: 14rem !important;
  }

  .sm\:w-15rem {
    width: 15rem !important;
  }

  .sm\:w-16rem {
    width: 16rem !important;
  }

  .sm\:w-17rem {
    width: 17rem !important;
  }

  .sm\:w-18rem {
    width: 18rem !important;
  }

  .sm\:w-19rem {
    width: 19rem !important;
  }

  .sm\:w-20rem {
    width: 20rem !important;
  }

  .sm\:w-21rem {
    width: 21rem !important;
  }

  .sm\:w-22rem {
    width: 22rem !important;
  }

  .sm\:w-23rem {
    width: 23rem !important;
  }

  .sm\:w-24rem {
    width: 24rem !important;
  }

  .sm\:w-25rem {
    width: 25rem !important;
  }

  .sm\:w-26rem {
    width: 26rem !important;
  }

  .sm\:w-27rem {
    width: 27rem !important;
  }

  .sm\:w-28rem {
    width: 28rem !important;
  }

  .sm\:w-29rem {
    width: 29rem !important;
  }

  .sm\:w-30rem {
    width: 30rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:w-full {
    width: 100% !important;
  }

  .md\:w-screen {
    width: 100vw !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-1 {
    width: 8.3333% !important;
  }

  .md\:w-2 {
    width: 16.6667% !important;
  }

  .md\:w-3 {
    width: 25% !important;
  }

  .md\:w-4 {
    width: 33.3333% !important;
  }

  .md\:w-5 {
    width: 41.6667% !important;
  }

  .md\:w-6 {
    width: 50% !important;
  }

  .md\:w-7 {
    width: 58.3333% !important;
  }

  .md\:w-8 {
    width: 66.6667% !important;
  }

  .md\:w-9 {
    width: 75% !important;
  }

  .md\:w-10 {
    width: 83.3333% !important;
  }

  .md\:w-11 {
    width: 91.6667% !important;
  }

  .md\:w-12 {
    width: 100% !important;
  }

  .md\:w-min {
    width: min-content !important;
  }

  .md\:w-max {
    width: max-content !important;
  }

  .md\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .md\:w-1rem {
    width: 1rem !important;
  }

  .md\:w-2rem {
    width: 2rem !important;
  }

  .md\:w-3rem {
    width: 3rem !important;
  }

  .md\:w-4rem {
    width: 4rem !important;
  }

  .md\:w-5rem {
    width: 5rem !important;
  }

  .md\:w-6rem {
    width: 6rem !important;
  }

  .md\:w-7rem {
    width: 7rem !important;
  }

  .md\:w-8rem {
    width: 8rem !important;
  }

  .md\:w-9rem {
    width: 9rem !important;
  }

  .md\:w-10rem {
    width: 10rem !important;
  }

  .md\:w-11rem {
    width: 11rem !important;
  }

  .md\:w-12rem {
    width: 12rem !important;
  }

  .md\:w-13rem {
    width: 13rem !important;
  }

  .md\:w-14rem {
    width: 14rem !important;
  }

  .md\:w-15rem {
    width: 15rem !important;
  }

  .md\:w-16rem {
    width: 16rem !important;
  }

  .md\:w-17rem {
    width: 17rem !important;
  }

  .md\:w-18rem {
    width: 18rem !important;
  }

  .md\:w-19rem {
    width: 19rem !important;
  }

  .md\:w-20rem {
    width: 20rem !important;
  }

  .md\:w-21rem {
    width: 21rem !important;
  }

  .md\:w-22rem {
    width: 22rem !important;
  }

  .md\:w-23rem {
    width: 23rem !important;
  }

  .md\:w-24rem {
    width: 24rem !important;
  }

  .md\:w-25rem {
    width: 25rem !important;
  }

  .md\:w-26rem {
    width: 26rem !important;
  }

  .md\:w-27rem {
    width: 27rem !important;
  }

  .md\:w-28rem {
    width: 28rem !important;
  }

  .md\:w-29rem {
    width: 29rem !important;
  }

  .md\:w-30rem {
    width: 30rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-screen {
    width: 100vw !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-1 {
    width: 8.3333% !important;
  }

  .lg\:w-2 {
    width: 16.6667% !important;
  }

  .lg\:w-3 {
    width: 25% !important;
  }

  .lg\:w-4 {
    width: 33.3333% !important;
  }

  .lg\:w-5 {
    width: 41.6667% !important;
  }

  .lg\:w-6 {
    width: 50% !important;
  }

  .lg\:w-7 {
    width: 58.3333% !important;
  }

  .lg\:w-8 {
    width: 66.6667% !important;
  }

  .lg\:w-9 {
    width: 75% !important;
  }

  .lg\:w-10 {
    width: 83.3333% !important;
  }

  .lg\:w-11 {
    width: 91.6667% !important;
  }

  .lg\:w-12 {
    width: 100% !important;
  }

  .lg\:w-min {
    width: min-content !important;
  }

  .lg\:w-max {
    width: max-content !important;
  }

  .lg\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .lg\:w-1rem {
    width: 1rem !important;
  }

  .lg\:w-2rem {
    width: 2rem !important;
  }

  .lg\:w-3rem {
    width: 3rem !important;
  }

  .lg\:w-4rem {
    width: 4rem !important;
  }

  .lg\:w-5rem {
    width: 5rem !important;
  }

  .lg\:w-6rem {
    width: 6rem !important;
  }

  .lg\:w-7rem {
    width: 7rem !important;
  }

  .lg\:w-8rem {
    width: 8rem !important;
  }

  .lg\:w-9rem {
    width: 9rem !important;
  }

  .lg\:w-10rem {
    width: 10rem !important;
  }

  .lg\:w-11rem {
    width: 11rem !important;
  }

  .lg\:w-12rem {
    width: 12rem !important;
  }

  .lg\:w-13rem {
    width: 13rem !important;
  }

  .lg\:w-14rem {
    width: 14rem !important;
  }

  .lg\:w-15rem {
    width: 15rem !important;
  }

  .lg\:w-16rem {
    width: 16rem !important;
  }

  .lg\:w-17rem {
    width: 17rem !important;
  }

  .lg\:w-18rem {
    width: 18rem !important;
  }

  .lg\:w-19rem {
    width: 19rem !important;
  }

  .lg\:w-20rem {
    width: 20rem !important;
  }

  .lg\:w-21rem {
    width: 21rem !important;
  }

  .lg\:w-22rem {
    width: 22rem !important;
  }

  .lg\:w-23rem {
    width: 23rem !important;
  }

  .lg\:w-24rem {
    width: 24rem !important;
  }

  .lg\:w-25rem {
    width: 25rem !important;
  }

  .lg\:w-26rem {
    width: 26rem !important;
  }

  .lg\:w-27rem {
    width: 27rem !important;
  }

  .lg\:w-28rem {
    width: 28rem !important;
  }

  .lg\:w-29rem {
    width: 29rem !important;
  }

  .lg\:w-30rem {
    width: 30rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-screen {
    width: 100vw !important;
  }

  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-1 {
    width: 8.3333% !important;
  }

  .xl\:w-2 {
    width: 16.6667% !important;
  }

  .xl\:w-3 {
    width: 25% !important;
  }

  .xl\:w-4 {
    width: 33.3333% !important;
  }

  .xl\:w-5 {
    width: 41.6667% !important;
  }

  .xl\:w-6 {
    width: 50% !important;
  }

  .xl\:w-7 {
    width: 58.3333% !important;
  }

  .xl\:w-8 {
    width: 66.6667% !important;
  }

  .xl\:w-9 {
    width: 75% !important;
  }

  .xl\:w-10 {
    width: 83.3333% !important;
  }

  .xl\:w-11 {
    width: 91.6667% !important;
  }

  .xl\:w-12 {
    width: 100% !important;
  }

  .xl\:w-min {
    width: min-content !important;
  }

  .xl\:w-max {
    width: max-content !important;
  }

  .xl\:w-fit {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .xl\:w-1rem {
    width: 1rem !important;
  }

  .xl\:w-2rem {
    width: 2rem !important;
  }

  .xl\:w-3rem {
    width: 3rem !important;
  }

  .xl\:w-4rem {
    width: 4rem !important;
  }

  .xl\:w-5rem {
    width: 5rem !important;
  }

  .xl\:w-6rem {
    width: 6rem !important;
  }

  .xl\:w-7rem {
    width: 7rem !important;
  }

  .xl\:w-8rem {
    width: 8rem !important;
  }

  .xl\:w-9rem {
    width: 9rem !important;
  }

  .xl\:w-10rem {
    width: 10rem !important;
  }

  .xl\:w-11rem {
    width: 11rem !important;
  }

  .xl\:w-12rem {
    width: 12rem !important;
  }

  .xl\:w-13rem {
    width: 13rem !important;
  }

  .xl\:w-14rem {
    width: 14rem !important;
  }

  .xl\:w-15rem {
    width: 15rem !important;
  }

  .xl\:w-16rem {
    width: 16rem !important;
  }

  .xl\:w-17rem {
    width: 17rem !important;
  }

  .xl\:w-18rem {
    width: 18rem !important;
  }

  .xl\:w-19rem {
    width: 19rem !important;
  }

  .xl\:w-20rem {
    width: 20rem !important;
  }

  .xl\:w-21rem {
    width: 21rem !important;
  }

  .xl\:w-22rem {
    width: 22rem !important;
  }

  .xl\:w-23rem {
    width: 23rem !important;
  }

  .xl\:w-24rem {
    width: 24rem !important;
  }

  .xl\:w-25rem {
    width: 25rem !important;
  }

  .xl\:w-26rem {
    width: 26rem !important;
  }

  .xl\:w-27rem {
    width: 27rem !important;
  }

  .xl\:w-28rem {
    width: 28rem !important;
  }

  .xl\:w-29rem {
    width: 29rem !important;
  }

  .xl\:w-30rem {
    width: 30rem !important;
  }
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-min {
  height: min-content !important;
}

.h-max {
  height: max-content !important;
}

.h-fit {
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (width >= 576px) {
  .sm\:h-full {
    height: 100% !important;
  }

  .sm\:h-screen {
    height: 100vh !important;
  }

  .sm\:h-auto {
    height: auto !important;
  }

  .sm\:h-min {
    height: min-content !important;
  }

  .sm\:h-max {
    height: max-content !important;
  }

  .sm\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .sm\:h-1rem {
    height: 1rem !important;
  }

  .sm\:h-2rem {
    height: 2rem !important;
  }

  .sm\:h-3rem {
    height: 3rem !important;
  }

  .sm\:h-4rem {
    height: 4rem !important;
  }

  .sm\:h-5rem {
    height: 5rem !important;
  }

  .sm\:h-6rem {
    height: 6rem !important;
  }

  .sm\:h-7rem {
    height: 7rem !important;
  }

  .sm\:h-8rem {
    height: 8rem !important;
  }

  .sm\:h-9rem {
    height: 9rem !important;
  }

  .sm\:h-10rem {
    height: 10rem !important;
  }

  .sm\:h-11rem {
    height: 11rem !important;
  }

  .sm\:h-12rem {
    height: 12rem !important;
  }

  .sm\:h-13rem {
    height: 13rem !important;
  }

  .sm\:h-14rem {
    height: 14rem !important;
  }

  .sm\:h-15rem {
    height: 15rem !important;
  }

  .sm\:h-16rem {
    height: 16rem !important;
  }

  .sm\:h-17rem {
    height: 17rem !important;
  }

  .sm\:h-18rem {
    height: 18rem !important;
  }

  .sm\:h-19rem {
    height: 19rem !important;
  }

  .sm\:h-20rem {
    height: 20rem !important;
  }

  .sm\:h-21rem {
    height: 21rem !important;
  }

  .sm\:h-22rem {
    height: 22rem !important;
  }

  .sm\:h-23rem {
    height: 23rem !important;
  }

  .sm\:h-24rem {
    height: 24rem !important;
  }

  .sm\:h-25rem {
    height: 25rem !important;
  }

  .sm\:h-26rem {
    height: 26rem !important;
  }

  .sm\:h-27rem {
    height: 27rem !important;
  }

  .sm\:h-28rem {
    height: 28rem !important;
  }

  .sm\:h-29rem {
    height: 29rem !important;
  }

  .sm\:h-30rem {
    height: 30rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:h-full {
    height: 100% !important;
  }

  .md\:h-screen {
    height: 100vh !important;
  }

  .md\:h-auto {
    height: auto !important;
  }

  .md\:h-min {
    height: min-content !important;
  }

  .md\:h-max {
    height: max-content !important;
  }

  .md\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .md\:h-1rem {
    height: 1rem !important;
  }

  .md\:h-2rem {
    height: 2rem !important;
  }

  .md\:h-3rem {
    height: 3rem !important;
  }

  .md\:h-4rem {
    height: 4rem !important;
  }

  .md\:h-5rem {
    height: 5rem !important;
  }

  .md\:h-6rem {
    height: 6rem !important;
  }

  .md\:h-7rem {
    height: 7rem !important;
  }

  .md\:h-8rem {
    height: 8rem !important;
  }

  .md\:h-9rem {
    height: 9rem !important;
  }

  .md\:h-10rem {
    height: 10rem !important;
  }

  .md\:h-11rem {
    height: 11rem !important;
  }

  .md\:h-12rem {
    height: 12rem !important;
  }

  .md\:h-13rem {
    height: 13rem !important;
  }

  .md\:h-14rem {
    height: 14rem !important;
  }

  .md\:h-15rem {
    height: 15rem !important;
  }

  .md\:h-16rem {
    height: 16rem !important;
  }

  .md\:h-17rem {
    height: 17rem !important;
  }

  .md\:h-18rem {
    height: 18rem !important;
  }

  .md\:h-19rem {
    height: 19rem !important;
  }

  .md\:h-20rem {
    height: 20rem !important;
  }

  .md\:h-21rem {
    height: 21rem !important;
  }

  .md\:h-22rem {
    height: 22rem !important;
  }

  .md\:h-23rem {
    height: 23rem !important;
  }

  .md\:h-24rem {
    height: 24rem !important;
  }

  .md\:h-25rem {
    height: 25rem !important;
  }

  .md\:h-26rem {
    height: 26rem !important;
  }

  .md\:h-27rem {
    height: 27rem !important;
  }

  .md\:h-28rem {
    height: 28rem !important;
  }

  .md\:h-29rem {
    height: 29rem !important;
  }

  .md\:h-30rem {
    height: 30rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:h-full {
    height: 100% !important;
  }

  .lg\:h-screen {
    height: 100vh !important;
  }

  .lg\:h-auto {
    height: auto !important;
  }

  .lg\:h-min {
    height: min-content !important;
  }

  .lg\:h-max {
    height: max-content !important;
  }

  .lg\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .lg\:h-1rem {
    height: 1rem !important;
  }

  .lg\:h-2rem {
    height: 2rem !important;
  }

  .lg\:h-3rem {
    height: 3rem !important;
  }

  .lg\:h-4rem {
    height: 4rem !important;
  }

  .lg\:h-5rem {
    height: 5rem !important;
  }

  .lg\:h-6rem {
    height: 6rem !important;
  }

  .lg\:h-7rem {
    height: 7rem !important;
  }

  .lg\:h-8rem {
    height: 8rem !important;
  }

  .lg\:h-9rem {
    height: 9rem !important;
  }

  .lg\:h-10rem {
    height: 10rem !important;
  }

  .lg\:h-11rem {
    height: 11rem !important;
  }

  .lg\:h-12rem {
    height: 12rem !important;
  }

  .lg\:h-13rem {
    height: 13rem !important;
  }

  .lg\:h-14rem {
    height: 14rem !important;
  }

  .lg\:h-15rem {
    height: 15rem !important;
  }

  .lg\:h-16rem {
    height: 16rem !important;
  }

  .lg\:h-17rem {
    height: 17rem !important;
  }

  .lg\:h-18rem {
    height: 18rem !important;
  }

  .lg\:h-19rem {
    height: 19rem !important;
  }

  .lg\:h-20rem {
    height: 20rem !important;
  }

  .lg\:h-21rem {
    height: 21rem !important;
  }

  .lg\:h-22rem {
    height: 22rem !important;
  }

  .lg\:h-23rem {
    height: 23rem !important;
  }

  .lg\:h-24rem {
    height: 24rem !important;
  }

  .lg\:h-25rem {
    height: 25rem !important;
  }

  .lg\:h-26rem {
    height: 26rem !important;
  }

  .lg\:h-27rem {
    height: 27rem !important;
  }

  .lg\:h-28rem {
    height: 28rem !important;
  }

  .lg\:h-29rem {
    height: 29rem !important;
  }

  .lg\:h-30rem {
    height: 30rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:h-full {
    height: 100% !important;
  }

  .xl\:h-screen {
    height: 100vh !important;
  }

  .xl\:h-auto {
    height: auto !important;
  }

  .xl\:h-min {
    height: min-content !important;
  }

  .xl\:h-max {
    height: max-content !important;
  }

  .xl\:h-fit {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }

  .xl\:h-1rem {
    height: 1rem !important;
  }

  .xl\:h-2rem {
    height: 2rem !important;
  }

  .xl\:h-3rem {
    height: 3rem !important;
  }

  .xl\:h-4rem {
    height: 4rem !important;
  }

  .xl\:h-5rem {
    height: 5rem !important;
  }

  .xl\:h-6rem {
    height: 6rem !important;
  }

  .xl\:h-7rem {
    height: 7rem !important;
  }

  .xl\:h-8rem {
    height: 8rem !important;
  }

  .xl\:h-9rem {
    height: 9rem !important;
  }

  .xl\:h-10rem {
    height: 10rem !important;
  }

  .xl\:h-11rem {
    height: 11rem !important;
  }

  .xl\:h-12rem {
    height: 12rem !important;
  }

  .xl\:h-13rem {
    height: 13rem !important;
  }

  .xl\:h-14rem {
    height: 14rem !important;
  }

  .xl\:h-15rem {
    height: 15rem !important;
  }

  .xl\:h-16rem {
    height: 16rem !important;
  }

  .xl\:h-17rem {
    height: 17rem !important;
  }

  .xl\:h-18rem {
    height: 18rem !important;
  }

  .xl\:h-19rem {
    height: 19rem !important;
  }

  .xl\:h-20rem {
    height: 20rem !important;
  }

  .xl\:h-21rem {
    height: 21rem !important;
  }

  .xl\:h-22rem {
    height: 22rem !important;
  }

  .xl\:h-23rem {
    height: 23rem !important;
  }

  .xl\:h-24rem {
    height: 24rem !important;
  }

  .xl\:h-25rem {
    height: 25rem !important;
  }

  .xl\:h-26rem {
    height: 26rem !important;
  }

  .xl\:h-27rem {
    height: 27rem !important;
  }

  .xl\:h-28rem {
    height: 28rem !important;
  }

  .xl\:h-29rem {
    height: 29rem !important;
  }

  .xl\:h-30rem {
    height: 30rem !important;
  }
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

@media screen and (width >= 576px) {
  .sm\:min-w-0 {
    min-width: 0 !important;
  }

  .sm\:min-w-full {
    min-width: 100% !important;
  }

  .sm\:min-w-screen {
    min-width: 100vw !important;
  }

  .sm\:min-w-min {
    min-width: min-content !important;
  }

  .sm\:min-w-max {
    min-width: max-content !important;
  }
}

@media screen and (width >= 768px) {
  .md\:min-w-0 {
    min-width: 0 !important;
  }

  .md\:min-w-full {
    min-width: 100% !important;
  }

  .md\:min-w-screen {
    min-width: 100vw !important;
  }

  .md\:min-w-min {
    min-width: min-content !important;
  }

  .md\:min-w-max {
    min-width: max-content !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:min-w-0 {
    min-width: 0 !important;
  }

  .lg\:min-w-full {
    min-width: 100% !important;
  }

  .lg\:min-w-screen {
    min-width: 100vw !important;
  }

  .lg\:min-w-min {
    min-width: min-content !important;
  }

  .lg\:min-w-max {
    min-width: max-content !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:min-w-0 {
    min-width: 0 !important;
  }

  .xl\:min-w-full {
    min-width: 100% !important;
  }

  .xl\:min-w-screen {
    min-width: 100vw !important;
  }

  .xl\:min-w-min {
    min-width: min-content !important;
  }

  .xl\:min-w-max {
    min-width: max-content !important;
  }
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.max-w-fit {
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

@media screen and (width >= 576px) {
  .sm\:max-w-0 {
    max-width: 0 !important;
  }

  .sm\:max-w-full {
    max-width: 100% !important;
  }

  .sm\:max-w-screen {
    max-width: 100vw !important;
  }

  .sm\:max-w-min {
    max-width: min-content !important;
  }

  .sm\:max-w-max {
    max-width: max-content !important;
  }

  .sm\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .sm\:max-w-1rem {
    max-width: 1rem !important;
  }

  .sm\:max-w-2rem {
    max-width: 2rem !important;
  }

  .sm\:max-w-3rem {
    max-width: 3rem !important;
  }

  .sm\:max-w-4rem {
    max-width: 4rem !important;
  }

  .sm\:max-w-5rem {
    max-width: 5rem !important;
  }

  .sm\:max-w-6rem {
    max-width: 6rem !important;
  }

  .sm\:max-w-7rem {
    max-width: 7rem !important;
  }

  .sm\:max-w-8rem {
    max-width: 8rem !important;
  }

  .sm\:max-w-9rem {
    max-width: 9rem !important;
  }

  .sm\:max-w-10rem {
    max-width: 10rem !important;
  }

  .sm\:max-w-11rem {
    max-width: 11rem !important;
  }

  .sm\:max-w-12rem {
    max-width: 12rem !important;
  }

  .sm\:max-w-13rem {
    max-width: 13rem !important;
  }

  .sm\:max-w-14rem {
    max-width: 14rem !important;
  }

  .sm\:max-w-15rem {
    max-width: 15rem !important;
  }

  .sm\:max-w-16rem {
    max-width: 16rem !important;
  }

  .sm\:max-w-17rem {
    max-width: 17rem !important;
  }

  .sm\:max-w-18rem {
    max-width: 18rem !important;
  }

  .sm\:max-w-19rem {
    max-width: 19rem !important;
  }

  .sm\:max-w-20rem {
    max-width: 20rem !important;
  }

  .sm\:max-w-21rem {
    max-width: 21rem !important;
  }

  .sm\:max-w-22rem {
    max-width: 22rem !important;
  }

  .sm\:max-w-23rem {
    max-width: 23rem !important;
  }

  .sm\:max-w-24rem {
    max-width: 24rem !important;
  }

  .sm\:max-w-25rem {
    max-width: 25rem !important;
  }

  .sm\:max-w-26rem {
    max-width: 26rem !important;
  }

  .sm\:max-w-27rem {
    max-width: 27rem !important;
  }

  .sm\:max-w-28rem {
    max-width: 28rem !important;
  }

  .sm\:max-w-29rem {
    max-width: 29rem !important;
  }

  .sm\:max-w-30rem {
    max-width: 30rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:max-w-0 {
    max-width: 0 !important;
  }

  .md\:max-w-full {
    max-width: 100% !important;
  }

  .md\:max-w-screen {
    max-width: 100vw !important;
  }

  .md\:max-w-min {
    max-width: min-content !important;
  }

  .md\:max-w-max {
    max-width: max-content !important;
  }

  .md\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .md\:max-w-1rem {
    max-width: 1rem !important;
  }

  .md\:max-w-2rem {
    max-width: 2rem !important;
  }

  .md\:max-w-3rem {
    max-width: 3rem !important;
  }

  .md\:max-w-4rem {
    max-width: 4rem !important;
  }

  .md\:max-w-5rem {
    max-width: 5rem !important;
  }

  .md\:max-w-6rem {
    max-width: 6rem !important;
  }

  .md\:max-w-7rem {
    max-width: 7rem !important;
  }

  .md\:max-w-8rem {
    max-width: 8rem !important;
  }

  .md\:max-w-9rem {
    max-width: 9rem !important;
  }

  .md\:max-w-10rem {
    max-width: 10rem !important;
  }

  .md\:max-w-11rem {
    max-width: 11rem !important;
  }

  .md\:max-w-12rem {
    max-width: 12rem !important;
  }

  .md\:max-w-13rem {
    max-width: 13rem !important;
  }

  .md\:max-w-14rem {
    max-width: 14rem !important;
  }

  .md\:max-w-15rem {
    max-width: 15rem !important;
  }

  .md\:max-w-16rem {
    max-width: 16rem !important;
  }

  .md\:max-w-17rem {
    max-width: 17rem !important;
  }

  .md\:max-w-18rem {
    max-width: 18rem !important;
  }

  .md\:max-w-19rem {
    max-width: 19rem !important;
  }

  .md\:max-w-20rem {
    max-width: 20rem !important;
  }

  .md\:max-w-21rem {
    max-width: 21rem !important;
  }

  .md\:max-w-22rem {
    max-width: 22rem !important;
  }

  .md\:max-w-23rem {
    max-width: 23rem !important;
  }

  .md\:max-w-24rem {
    max-width: 24rem !important;
  }

  .md\:max-w-25rem {
    max-width: 25rem !important;
  }

  .md\:max-w-26rem {
    max-width: 26rem !important;
  }

  .md\:max-w-27rem {
    max-width: 27rem !important;
  }

  .md\:max-w-28rem {
    max-width: 28rem !important;
  }

  .md\:max-w-29rem {
    max-width: 29rem !important;
  }

  .md\:max-w-30rem {
    max-width: 30rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:max-w-0 {
    max-width: 0 !important;
  }

  .lg\:max-w-full {
    max-width: 100% !important;
  }

  .lg\:max-w-screen {
    max-width: 100vw !important;
  }

  .lg\:max-w-min {
    max-width: min-content !important;
  }

  .lg\:max-w-max {
    max-width: max-content !important;
  }

  .lg\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .lg\:max-w-1rem {
    max-width: 1rem !important;
  }

  .lg\:max-w-2rem {
    max-width: 2rem !important;
  }

  .lg\:max-w-3rem {
    max-width: 3rem !important;
  }

  .lg\:max-w-4rem {
    max-width: 4rem !important;
  }

  .lg\:max-w-5rem {
    max-width: 5rem !important;
  }

  .lg\:max-w-6rem {
    max-width: 6rem !important;
  }

  .lg\:max-w-7rem {
    max-width: 7rem !important;
  }

  .lg\:max-w-8rem {
    max-width: 8rem !important;
  }

  .lg\:max-w-9rem {
    max-width: 9rem !important;
  }

  .lg\:max-w-10rem {
    max-width: 10rem !important;
  }

  .lg\:max-w-11rem {
    max-width: 11rem !important;
  }

  .lg\:max-w-12rem {
    max-width: 12rem !important;
  }

  .lg\:max-w-13rem {
    max-width: 13rem !important;
  }

  .lg\:max-w-14rem {
    max-width: 14rem !important;
  }

  .lg\:max-w-15rem {
    max-width: 15rem !important;
  }

  .lg\:max-w-16rem {
    max-width: 16rem !important;
  }

  .lg\:max-w-17rem {
    max-width: 17rem !important;
  }

  .lg\:max-w-18rem {
    max-width: 18rem !important;
  }

  .lg\:max-w-19rem {
    max-width: 19rem !important;
  }

  .lg\:max-w-20rem {
    max-width: 20rem !important;
  }

  .lg\:max-w-21rem {
    max-width: 21rem !important;
  }

  .lg\:max-w-22rem {
    max-width: 22rem !important;
  }

  .lg\:max-w-23rem {
    max-width: 23rem !important;
  }

  .lg\:max-w-24rem {
    max-width: 24rem !important;
  }

  .lg\:max-w-25rem {
    max-width: 25rem !important;
  }

  .lg\:max-w-26rem {
    max-width: 26rem !important;
  }

  .lg\:max-w-27rem {
    max-width: 27rem !important;
  }

  .lg\:max-w-28rem {
    max-width: 28rem !important;
  }

  .lg\:max-w-29rem {
    max-width: 29rem !important;
  }

  .lg\:max-w-30rem {
    max-width: 30rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:max-w-0 {
    max-width: 0 !important;
  }

  .xl\:max-w-full {
    max-width: 100% !important;
  }

  .xl\:max-w-screen {
    max-width: 100vw !important;
  }

  .xl\:max-w-min {
    max-width: min-content !important;
  }

  .xl\:max-w-max {
    max-width: max-content !important;
  }

  .xl\:max-w-fit {
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
  }

  .xl\:max-w-1rem {
    max-width: 1rem !important;
  }

  .xl\:max-w-2rem {
    max-width: 2rem !important;
  }

  .xl\:max-w-3rem {
    max-width: 3rem !important;
  }

  .xl\:max-w-4rem {
    max-width: 4rem !important;
  }

  .xl\:max-w-5rem {
    max-width: 5rem !important;
  }

  .xl\:max-w-6rem {
    max-width: 6rem !important;
  }

  .xl\:max-w-7rem {
    max-width: 7rem !important;
  }

  .xl\:max-w-8rem {
    max-width: 8rem !important;
  }

  .xl\:max-w-9rem {
    max-width: 9rem !important;
  }

  .xl\:max-w-10rem {
    max-width: 10rem !important;
  }

  .xl\:max-w-11rem {
    max-width: 11rem !important;
  }

  .xl\:max-w-12rem {
    max-width: 12rem !important;
  }

  .xl\:max-w-13rem {
    max-width: 13rem !important;
  }

  .xl\:max-w-14rem {
    max-width: 14rem !important;
  }

  .xl\:max-w-15rem {
    max-width: 15rem !important;
  }

  .xl\:max-w-16rem {
    max-width: 16rem !important;
  }

  .xl\:max-w-17rem {
    max-width: 17rem !important;
  }

  .xl\:max-w-18rem {
    max-width: 18rem !important;
  }

  .xl\:max-w-19rem {
    max-width: 19rem !important;
  }

  .xl\:max-w-20rem {
    max-width: 20rem !important;
  }

  .xl\:max-w-21rem {
    max-width: 21rem !important;
  }

  .xl\:max-w-22rem {
    max-width: 22rem !important;
  }

  .xl\:max-w-23rem {
    max-width: 23rem !important;
  }

  .xl\:max-w-24rem {
    max-width: 24rem !important;
  }

  .xl\:max-w-25rem {
    max-width: 25rem !important;
  }

  .xl\:max-w-26rem {
    max-width: 26rem !important;
  }

  .xl\:max-w-27rem {
    max-width: 27rem !important;
  }

  .xl\:max-w-28rem {
    max-width: 28rem !important;
  }

  .xl\:max-w-29rem {
    max-width: 29rem !important;
  }

  .xl\:max-w-30rem {
    max-width: 30rem !important;
  }
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (width >= 576px) {
  .sm\:min-h-0 {
    min-height: 0 !important;
  }

  .sm\:min-h-full {
    min-height: 100% !important;
  }

  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}

@media screen and (width >= 768px) {
  .md\:min-h-0 {
    min-height: 0 !important;
  }

  .md\:min-h-full {
    min-height: 100% !important;
  }

  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:min-h-0 {
    min-height: 0 !important;
  }

  .lg\:min-h-full {
    min-height: 100% !important;
  }

  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:min-h-0 {
    min-height: 0 !important;
  }

  .xl\:min-h-full {
    min-height: 100% !important;
  }

  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}

.max-h-0 {
  max-height: 0 !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-min {
  max-height: min-content !important;
}

.max-h-max {
  max-height: max-content !important;
}

.max-h-fit {
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

@media screen and (width >= 576px) {
  .sm\:max-h-0 {
    max-height: 0 !important;
  }

  .sm\:max-h-full {
    max-height: 100% !important;
  }

  .sm\:max-h-screen {
    max-height: 100vh !important;
  }

  .sm\:max-h-min {
    max-height: min-content !important;
  }

  .sm\:max-h-max {
    max-height: max-content !important;
  }

  .sm\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .sm\:max-h-1rem {
    max-height: 1rem !important;
  }

  .sm\:max-h-2rem {
    max-height: 2rem !important;
  }

  .sm\:max-h-3rem {
    max-height: 3rem !important;
  }

  .sm\:max-h-4rem {
    max-height: 4rem !important;
  }

  .sm\:max-h-5rem {
    max-height: 5rem !important;
  }

  .sm\:max-h-6rem {
    max-height: 6rem !important;
  }

  .sm\:max-h-7rem {
    max-height: 7rem !important;
  }

  .sm\:max-h-8rem {
    max-height: 8rem !important;
  }

  .sm\:max-h-9rem {
    max-height: 9rem !important;
  }

  .sm\:max-h-10rem {
    max-height: 10rem !important;
  }

  .sm\:max-h-11rem {
    max-height: 11rem !important;
  }

  .sm\:max-h-12rem {
    max-height: 12rem !important;
  }

  .sm\:max-h-13rem {
    max-height: 13rem !important;
  }

  .sm\:max-h-14rem {
    max-height: 14rem !important;
  }

  .sm\:max-h-15rem {
    max-height: 15rem !important;
  }

  .sm\:max-h-16rem {
    max-height: 16rem !important;
  }

  .sm\:max-h-17rem {
    max-height: 17rem !important;
  }

  .sm\:max-h-18rem {
    max-height: 18rem !important;
  }

  .sm\:max-h-19rem {
    max-height: 19rem !important;
  }

  .sm\:max-h-20rem {
    max-height: 20rem !important;
  }

  .sm\:max-h-21rem {
    max-height: 21rem !important;
  }

  .sm\:max-h-22rem {
    max-height: 22rem !important;
  }

  .sm\:max-h-23rem {
    max-height: 23rem !important;
  }

  .sm\:max-h-24rem {
    max-height: 24rem !important;
  }

  .sm\:max-h-25rem {
    max-height: 25rem !important;
  }

  .sm\:max-h-26rem {
    max-height: 26rem !important;
  }

  .sm\:max-h-27rem {
    max-height: 27rem !important;
  }

  .sm\:max-h-28rem {
    max-height: 28rem !important;
  }

  .sm\:max-h-29rem {
    max-height: 29rem !important;
  }

  .sm\:max-h-30rem {
    max-height: 30rem !important;
  }
}

@media screen and (width >= 768px) {
  .md\:max-h-0 {
    max-height: 0 !important;
  }

  .md\:max-h-full {
    max-height: 100% !important;
  }

  .md\:max-h-screen {
    max-height: 100vh !important;
  }

  .md\:max-h-min {
    max-height: min-content !important;
  }

  .md\:max-h-max {
    max-height: max-content !important;
  }

  .md\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .md\:max-h-1rem {
    max-height: 1rem !important;
  }

  .md\:max-h-2rem {
    max-height: 2rem !important;
  }

  .md\:max-h-3rem {
    max-height: 3rem !important;
  }

  .md\:max-h-4rem {
    max-height: 4rem !important;
  }

  .md\:max-h-5rem {
    max-height: 5rem !important;
  }

  .md\:max-h-6rem {
    max-height: 6rem !important;
  }

  .md\:max-h-7rem {
    max-height: 7rem !important;
  }

  .md\:max-h-8rem {
    max-height: 8rem !important;
  }

  .md\:max-h-9rem {
    max-height: 9rem !important;
  }

  .md\:max-h-10rem {
    max-height: 10rem !important;
  }

  .md\:max-h-11rem {
    max-height: 11rem !important;
  }

  .md\:max-h-12rem {
    max-height: 12rem !important;
  }

  .md\:max-h-13rem {
    max-height: 13rem !important;
  }

  .md\:max-h-14rem {
    max-height: 14rem !important;
  }

  .md\:max-h-15rem {
    max-height: 15rem !important;
  }

  .md\:max-h-16rem {
    max-height: 16rem !important;
  }

  .md\:max-h-17rem {
    max-height: 17rem !important;
  }

  .md\:max-h-18rem {
    max-height: 18rem !important;
  }

  .md\:max-h-19rem {
    max-height: 19rem !important;
  }

  .md\:max-h-20rem {
    max-height: 20rem !important;
  }

  .md\:max-h-21rem {
    max-height: 21rem !important;
  }

  .md\:max-h-22rem {
    max-height: 22rem !important;
  }

  .md\:max-h-23rem {
    max-height: 23rem !important;
  }

  .md\:max-h-24rem {
    max-height: 24rem !important;
  }

  .md\:max-h-25rem {
    max-height: 25rem !important;
  }

  .md\:max-h-26rem {
    max-height: 26rem !important;
  }

  .md\:max-h-27rem {
    max-height: 27rem !important;
  }

  .md\:max-h-28rem {
    max-height: 28rem !important;
  }

  .md\:max-h-29rem {
    max-height: 29rem !important;
  }

  .md\:max-h-30rem {
    max-height: 30rem !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:max-h-0 {
    max-height: 0 !important;
  }

  .lg\:max-h-full {
    max-height: 100% !important;
  }

  .lg\:max-h-screen {
    max-height: 100vh !important;
  }

  .lg\:max-h-min {
    max-height: min-content !important;
  }

  .lg\:max-h-max {
    max-height: max-content !important;
  }

  .lg\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .lg\:max-h-1rem {
    max-height: 1rem !important;
  }

  .lg\:max-h-2rem {
    max-height: 2rem !important;
  }

  .lg\:max-h-3rem {
    max-height: 3rem !important;
  }

  .lg\:max-h-4rem {
    max-height: 4rem !important;
  }

  .lg\:max-h-5rem {
    max-height: 5rem !important;
  }

  .lg\:max-h-6rem {
    max-height: 6rem !important;
  }

  .lg\:max-h-7rem {
    max-height: 7rem !important;
  }

  .lg\:max-h-8rem {
    max-height: 8rem !important;
  }

  .lg\:max-h-9rem {
    max-height: 9rem !important;
  }

  .lg\:max-h-10rem {
    max-height: 10rem !important;
  }

  .lg\:max-h-11rem {
    max-height: 11rem !important;
  }

  .lg\:max-h-12rem {
    max-height: 12rem !important;
  }

  .lg\:max-h-13rem {
    max-height: 13rem !important;
  }

  .lg\:max-h-14rem {
    max-height: 14rem !important;
  }

  .lg\:max-h-15rem {
    max-height: 15rem !important;
  }

  .lg\:max-h-16rem {
    max-height: 16rem !important;
  }

  .lg\:max-h-17rem {
    max-height: 17rem !important;
  }

  .lg\:max-h-18rem {
    max-height: 18rem !important;
  }

  .lg\:max-h-19rem {
    max-height: 19rem !important;
  }

  .lg\:max-h-20rem {
    max-height: 20rem !important;
  }

  .lg\:max-h-21rem {
    max-height: 21rem !important;
  }

  .lg\:max-h-22rem {
    max-height: 22rem !important;
  }

  .lg\:max-h-23rem {
    max-height: 23rem !important;
  }

  .lg\:max-h-24rem {
    max-height: 24rem !important;
  }

  .lg\:max-h-25rem {
    max-height: 25rem !important;
  }

  .lg\:max-h-26rem {
    max-height: 26rem !important;
  }

  .lg\:max-h-27rem {
    max-height: 27rem !important;
  }

  .lg\:max-h-28rem {
    max-height: 28rem !important;
  }

  .lg\:max-h-29rem {
    max-height: 29rem !important;
  }

  .lg\:max-h-30rem {
    max-height: 30rem !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:max-h-0 {
    max-height: 0 !important;
  }

  .xl\:max-h-full {
    max-height: 100% !important;
  }

  .xl\:max-h-screen {
    max-height: 100vh !important;
  }

  .xl\:max-h-min {
    max-height: min-content !important;
  }

  .xl\:max-h-max {
    max-height: max-content !important;
  }

  .xl\:max-h-fit {
    max-height: -moz-fit-content !important;
    max-height: fit-content !important;
  }

  .xl\:max-h-1rem {
    max-height: 1rem !important;
  }

  .xl\:max-h-2rem {
    max-height: 2rem !important;
  }

  .xl\:max-h-3rem {
    max-height: 3rem !important;
  }

  .xl\:max-h-4rem {
    max-height: 4rem !important;
  }

  .xl\:max-h-5rem {
    max-height: 5rem !important;
  }

  .xl\:max-h-6rem {
    max-height: 6rem !important;
  }

  .xl\:max-h-7rem {
    max-height: 7rem !important;
  }

  .xl\:max-h-8rem {
    max-height: 8rem !important;
  }

  .xl\:max-h-9rem {
    max-height: 9rem !important;
  }

  .xl\:max-h-10rem {
    max-height: 10rem !important;
  }

  .xl\:max-h-11rem {
    max-height: 11rem !important;
  }

  .xl\:max-h-12rem {
    max-height: 12rem !important;
  }

  .xl\:max-h-13rem {
    max-height: 13rem !important;
  }

  .xl\:max-h-14rem {
    max-height: 14rem !important;
  }

  .xl\:max-h-15rem {
    max-height: 15rem !important;
  }

  .xl\:max-h-16rem {
    max-height: 16rem !important;
  }

  .xl\:max-h-17rem {
    max-height: 17rem !important;
  }

  .xl\:max-h-18rem {
    max-height: 18rem !important;
  }

  .xl\:max-h-19rem {
    max-height: 19rem !important;
  }

  .xl\:max-h-20rem {
    max-height: 20rem !important;
  }

  .xl\:max-h-21rem {
    max-height: 21rem !important;
  }

  .xl\:max-h-22rem {
    max-height: 22rem !important;
  }

  .xl\:max-h-23rem {
    max-height: 23rem !important;
  }

  .xl\:max-h-24rem {
    max-height: 24rem !important;
  }

  .xl\:max-h-25rem {
    max-height: 25rem !important;
  }

  .xl\:max-h-26rem {
    max-height: 26rem !important;
  }

  .xl\:max-h-27rem {
    max-height: 27rem !important;
  }

  .xl\:max-h-28rem {
    max-height: 28rem !important;
  }

  .xl\:max-h-29rem {
    max-height: 29rem !important;
  }

  .xl\:max-h-30rem {
    max-height: 30rem !important;
  }
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

@media screen and (width >= 576px) {
  .sm\:static {
    position: static !important;
  }

  .sm\:fixed {
    position: fixed !important;
  }

  .sm\:absolute {
    position: absolute !important;
  }

  .sm\:relative {
    position: relative !important;
  }

  .sm\:sticky {
    position: sticky !important;
  }
}

@media screen and (width >= 768px) {
  .md\:static {
    position: static !important;
  }

  .md\:fixed {
    position: fixed !important;
  }

  .md\:absolute {
    position: absolute !important;
  }

  .md\:relative {
    position: relative !important;
  }

  .md\:sticky {
    position: sticky !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:static {
    position: static !important;
  }

  .lg\:fixed {
    position: fixed !important;
  }

  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:relative {
    position: relative !important;
  }

  .lg\:sticky {
    position: sticky !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:static {
    position: static !important;
  }

  .xl\:fixed {
    position: fixed !important;
  }

  .xl\:absolute {
    position: absolute !important;
  }

  .xl\:relative {
    position: relative !important;
  }

  .xl\:sticky {
    position: sticky !important;
  }
}

.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (width >= 576px) {
  .sm\:top-auto {
    top: auto !important;
  }

  .sm\:top-0 {
    top: 0 !important;
  }

  .sm\:top-50 {
    top: 50% !important;
  }

  .sm\:top-100 {
    top: 100% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:top-auto {
    top: auto !important;
  }

  .md\:top-0 {
    top: 0 !important;
  }

  .md\:top-50 {
    top: 50% !important;
  }

  .md\:top-100 {
    top: 100% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:top-auto {
    top: auto !important;
  }

  .lg\:top-0 {
    top: 0 !important;
  }

  .lg\:top-50 {
    top: 50% !important;
  }

  .lg\:top-100 {
    top: 100% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:top-auto {
    top: auto !important;
  }

  .xl\:top-0 {
    top: 0 !important;
  }

  .xl\:top-50 {
    top: 50% !important;
  }

  .xl\:top-100 {
    top: 100% !important;
  }
}

.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0 !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (width >= 576px) {
  .sm\:left-auto {
    left: auto !important;
  }

  .sm\:left-0 {
    left: 0 !important;
  }

  .sm\:left-50 {
    left: 50% !important;
  }

  .sm\:left-100 {
    left: 100% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:left-auto {
    left: auto !important;
  }

  .md\:left-0 {
    left: 0 !important;
  }

  .md\:left-50 {
    left: 50% !important;
  }

  .md\:left-100 {
    left: 100% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:left-auto {
    left: auto !important;
  }

  .lg\:left-0 {
    left: 0 !important;
  }

  .lg\:left-50 {
    left: 50% !important;
  }

  .lg\:left-100 {
    left: 100% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:left-auto {
    left: auto !important;
  }

  .xl\:left-0 {
    left: 0 !important;
  }

  .xl\:left-50 {
    left: 50% !important;
  }

  .xl\:left-100 {
    left: 100% !important;
  }
}

.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0 !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (width >= 576px) {
  .sm\:right-auto {
    right: auto !important;
  }

  .sm\:right-0 {
    right: 0 !important;
  }

  .sm\:right-50 {
    right: 50% !important;
  }

  .sm\:right-100 {
    right: 100% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:right-auto {
    right: auto !important;
  }

  .md\:right-0 {
    right: 0 !important;
  }

  .md\:right-50 {
    right: 50% !important;
  }

  .md\:right-100 {
    right: 100% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:right-auto {
    right: auto !important;
  }

  .lg\:right-0 {
    right: 0 !important;
  }

  .lg\:right-50 {
    right: 50% !important;
  }

  .lg\:right-100 {
    right: 100% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:right-auto {
    right: auto !important;
  }

  .xl\:right-0 {
    right: 0 !important;
  }

  .xl\:right-50 {
    right: 50% !important;
  }

  .xl\:right-100 {
    right: 100% !important;
  }
}

.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (width >= 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }

  .sm\:bottom-0 {
    bottom: 0 !important;
  }

  .sm\:bottom-50 {
    bottom: 50% !important;
  }

  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}

@media screen and (width >= 768px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }

  .md\:bottom-0 {
    bottom: 0 !important;
  }

  .md\:bottom-50 {
    bottom: 50% !important;
  }

  .md\:bottom-100 {
    bottom: 100% !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }

  .lg\:bottom-0 {
    bottom: 0 !important;
  }

  .lg\:bottom-50 {
    bottom: 50% !important;
  }

  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }

  .xl\:bottom-0 {
    bottom: 0 !important;
  }

  .xl\:bottom-50 {
    bottom: 50% !important;
  }

  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (width >= 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }

  .sm\:overflow-hidden {
    overflow: hidden !important;
  }

  .sm\:overflow-visible {
    overflow: visible !important;
  }

  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}

@media screen and (width >= 768px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }

  .md\:overflow-hidden {
    overflow: hidden !important;
  }

  .md\:overflow-visible {
    overflow: visible !important;
  }

  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }

  .lg\:overflow-hidden {
    overflow: hidden !important;
  }

  .lg\:overflow-visible {
    overflow: visible !important;
  }

  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }

  .xl\:overflow-hidden {
    overflow: hidden !important;
  }

  .xl\:overflow-visible {
    overflow: visible !important;
  }

  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (width >= 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

@media screen and (width >= 768px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (width >= 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

@media screen and (width >= 768px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}

.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (width >= 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }

  .sm\:z-0 {
    z-index: 0 !important;
  }

  .sm\:z-1 {
    z-index: 1 !important;
  }

  .sm\:z-2 {
    z-index: 2 !important;
  }

  .sm\:z-3 {
    z-index: 3 !important;
  }

  .sm\:z-4 {
    z-index: 4 !important;
  }

  .sm\:z-5 {
    z-index: 5 !important;
  }
}

@media screen and (width >= 768px) {
  .md\:z-auto {
    z-index: auto !important;
  }

  .md\:z-0 {
    z-index: 0 !important;
  }

  .md\:z-1 {
    z-index: 1 !important;
  }

  .md\:z-2 {
    z-index: 2 !important;
  }

  .md\:z-3 {
    z-index: 3 !important;
  }

  .md\:z-4 {
    z-index: 4 !important;
  }

  .md\:z-5 {
    z-index: 5 !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:z-auto {
    z-index: auto !important;
  }

  .lg\:z-0 {
    z-index: 0 !important;
  }

  .lg\:z-1 {
    z-index: 1 !important;
  }

  .lg\:z-2 {
    z-index: 2 !important;
  }

  .lg\:z-3 {
    z-index: 3 !important;
  }

  .lg\:z-4 {
    z-index: 4 !important;
  }

  .lg\:z-5 {
    z-index: 5 !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:z-auto {
    z-index: auto !important;
  }

  .xl\:z-0 {
    z-index: 0 !important;
  }

  .xl\:z-1 {
    z-index: 1 !important;
  }

  .xl\:z-2 {
    z-index: 2 !important;
  }

  .xl\:z-3 {
    z-index: 3 !important;
  }

  .xl\:z-4 {
    z-index: 4 !important;
  }

  .xl\:z-5 {
    z-index: 5 !important;
  }
}

.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (width >= 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }

  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}

@media screen and (width >= 768px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .md\:bg-repeat-round {
    background-repeat: round !important;
  }

  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (width >= 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }

  .sm\:bg-cover {
    background-size: cover !important;
  }

  .sm\:bg-contain {
    background-size: contain !important;
  }
}

@media screen and (width >= 768px) {
  .md\:bg-auto {
    background-size: auto !important;
  }

  .md\:bg-cover {
    background-size: cover !important;
  }

  .md\:bg-contain {
    background-size: contain !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }

  .lg\:bg-cover {
    background-size: cover !important;
  }

  .lg\:bg-contain {
    background-size: contain !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }

  .xl\:bg-cover {
    background-size: cover !important;
  }

  .xl\:bg-contain {
    background-size: contain !important;
  }
}

.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: 0 !important;
}

.bg-left-bottom {
  background-position: 0 100% !important;
}

.bg-left-top {
  background-position: 0 0 !important;
}

.bg-right {
  background-position: 100% !important;
}

.bg-right-bottom {
  background-position: 100% 100% !important;
}

.bg-right-top {
  background-position: 100% 0 !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (width >= 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }

  .sm\:bg-center {
    background-position: center !important;
  }

  .sm\:bg-left {
    background-position: 0 !important;
  }

  .sm\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .sm\:bg-left-top {
    background-position: 0 0 !important;
  }

  .sm\:bg-right {
    background-position: 100% !important;
  }

  .sm\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .sm\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .sm\:bg-top {
    background-position: top !important;
  }
}

@media screen and (width >= 768px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }

  .md\:bg-center {
    background-position: center !important;
  }

  .md\:bg-left {
    background-position: 0 !important;
  }

  .md\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .md\:bg-left-top {
    background-position: 0 0 !important;
  }

  .md\:bg-right {
    background-position: 100% !important;
  }

  .md\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .md\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .md\:bg-top {
    background-position: top !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }

  .lg\:bg-center {
    background-position: center !important;
  }

  .lg\:bg-left {
    background-position: 0 !important;
  }

  .lg\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .lg\:bg-left-top {
    background-position: 0 0 !important;
  }

  .lg\:bg-right {
    background-position: 100% !important;
  }

  .lg\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .lg\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .lg\:bg-top {
    background-position: top !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }

  .xl\:bg-center {
    background-position: center !important;
  }

  .xl\:bg-left {
    background-position: 0 !important;
  }

  .xl\:bg-left-bottom {
    background-position: 0 100% !important;
  }

  .xl\:bg-left-top {
    background-position: 0 0 !important;
  }

  .xl\:bg-right {
    background-position: 100% !important;
  }

  .xl\:bg-right-bottom {
    background-position: 100% 100% !important;
  }

  .xl\:bg-right-top {
    background-position: 100% 0 !important;
  }

  .xl\:bg-top {
    background-position: top !important;
  }
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color, border-color, color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: .1s !important;
}

.transition-duration-150 {
  transition-duration: .15s !important;
}

.transition-duration-200 {
  transition-duration: .2s !important;
}

.transition-duration-300 {
  transition-duration: .3s !important;
}

.transition-duration-400 {
  transition-duration: .4s !important;
}

.transition-duration-500 {
  transition-duration: .5s !important;
}

.transition-duration-1000 {
  transition-duration: 1s !important;
}

.transition-duration-2000 {
  transition-duration: 2s !important;
}

.transition-duration-3000 {
  transition-duration: 3s !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.transition-delay-100 {
  transition-delay: .1s !important;
}

.transition-delay-150 {
  transition-delay: .15s !important;
}

.transition-delay-200 {
  transition-delay: .2s !important;
}

.transition-delay-300 {
  transition-delay: .3s !important;
}

.transition-delay-400 {
  transition-delay: .4s !important;
}

.transition-delay-500 {
  transition-delay: .5s !important;
}

.transition-delay-1000 {
  transition-delay: 1s !important;
}

.translate-x-0 {
  transform: translateX(0%) !important;
}

.translate-x-100 {
  transform: translateX(100%) !important;
}

.-translate-x-100 {
  transform: translateX(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0%) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (width >= 576px) {
  .sm\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .sm\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .sm\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .sm\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (width >= 768px) {
  .md\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .md\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .md\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .md\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .lg\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .lg\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .lg\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:translate-x-0 {
    transform: translateX(0%) !important;
  }

  .xl\:translate-x-100 {
    transform: translateX(100%) !important;
  }

  .xl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }

  .xl\:translate-y-0 {
    transform: translateY(0%) !important;
  }

  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }

  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}

.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (width >= 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (width >= 768px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }

  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }

  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }

  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }

  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }

  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: 100% 0 !important;
}

.origin-right {
  transform-origin: 100% !important;
}

.origin-bottom-right {
  transform-origin: 100% 100% !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: 0 100% !important;
}

.origin-left {
  transform-origin: 0 !important;
}

.origin-top-left {
  transform-origin: top-left !important;
}

@media screen and (width >= 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }

  .sm\:origin-top {
    transform-origin: top !important;
  }

  .sm\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .sm\:origin-right {
    transform-origin: 100% !important;
  }

  .sm\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }

  .sm\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .sm\:origin-left {
    transform-origin: 0 !important;
  }

  .sm\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@media screen and (width >= 768px) {
  .md\:origin-center {
    transform-origin: center !important;
  }

  .md\:origin-top {
    transform-origin: top !important;
  }

  .md\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .md\:origin-right {
    transform-origin: 100% !important;
  }

  .md\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .md\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .md\:origin-left {
    transform-origin: 0 !important;
  }

  .md\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@media screen and (width >= 992px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }

  .lg\:origin-top {
    transform-origin: top !important;
  }

  .lg\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .lg\:origin-right {
    transform-origin: 100% !important;
  }

  .lg\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lg\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .lg\:origin-left {
    transform-origin: 0 !important;
  }

  .lg\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@media screen and (width >= 1200px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }

  .xl\:origin-top {
    transform-origin: top !important;
  }

  .xl\:origin-top-right {
    transform-origin: 100% 0 !important;
  }

  .xl\:origin-right {
    transform-origin: 100% !important;
  }

  .xl\:origin-bottom-right {
    transform-origin: 100% 100% !important;
  }

  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xl\:origin-bottom-left {
    transform-origin: 0 100% !important;
  }

  .xl\:origin-left {
    transform-origin: 0 !important;
  }

  .xl\:origin-top-left {
    transform-origin: top-left !important;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scalein {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: scaleY(.8);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes slidedown {
  0% {
    max-height: 0;
  }

  100% {
    max-height: auto;
  }
}

@keyframes slideup {
  0% {
    max-height: 1000px;
  }

  100% {
    max-height: 0;
  }
}

@keyframes fadeinleft {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

@keyframes fadeinright {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeoutright {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes fadeinup {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeoutup {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fadeindown {
  0% {
    opacity: 0;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transition: transform .12s cubic-bezier(0, 0, .2, 1), opacity .12s cubic-bezier(0, 0, .2, 1);
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes animate-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes flip {
  from {
    transform: perspective(2000px)rotateX(-100deg);
  }

  to {
    transform: perspective(2000px)rotateX(0);
  }
}

@keyframes flipleft {
  from {
    opacity: 0;
    transform: perspective(2000px)rotateY(-100deg);
  }

  to {
    opacity: 1;
    transform: perspective(2000px)rotateY(0);
  }
}

@keyframes flipright {
  from {
    opacity: 0;
    transform: perspective(2000px)rotateY(100deg);
  }

  to {
    opacity: 1;
    transform: perspective(2000px)rotateY(0);
  }
}

@keyframes flipup {
  from {
    opacity: 0;
    transform: perspective(2000px)rotateX(-100deg);
  }

  to {
    opacity: 1;
    transform: perspective(2000px)rotateX(0);
  }
}

@keyframes zoomin {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomindown {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

@keyframes zoominleft {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

@keyframes zoominright {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

@keyframes zoominup {
  from {
    opacity: 0;
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.fadein {
  animation: .15s linear fadein;
}

.fadeout {
  animation: .15s linear fadeout;
}

.slidedown {
  animation: .45s ease-in-out slidedown;
}

.slideup {
  animation: .45s cubic-bezier(0, 1, 0, 1) slideup;
}

.scalein {
  animation: .15s linear scalein;
}

.fadeinleft {
  animation: .15s linear fadeinleft;
}

.fadeoutleft {
  animation: .15s linear fadeoutleft;
}

.fadeinright {
  animation: .15s linear fadeinright;
}

.fadeoutright {
  animation: .15s linear fadeoutright;
}

.fadeinup {
  animation: .15s linear fadeinup;
}

.fadeoutup {
  animation: .15s linear fadeoutup;
}

.fadeindown {
  animation: .15s linear fadeindown;
}

.fadeoutdown {
  animation: .15s linear fadeoutdown;
}

.animate-width {
  animation: 1s linear animate-width;
}

.flip {
  backface-visibility: visible;
  animation: .15s linear flip;
}

.flipup {
  backface-visibility: visible;
  animation: .15s linear flipup;
}

.flipleft {
  backface-visibility: visible;
  animation: .15s linear flipleft;
}

.flipright {
  backface-visibility: visible;
  animation: .15s linear flipright;
}

.zoomin {
  animation: .15s linear zoomin;
}

.zoomindown {
  animation: .15s linear zoomindown;
}

.zoominleft {
  animation: .15s linear zoominleft;
}

.zoominright {
  animation: .15s linear zoominright;
}

.zoominup {
  animation: .15s linear zoominup;
}

.animation-duration-100 {
  animation-duration: .1s !important;
}

.animation-duration-150 {
  animation-duration: .15s !important;
}

.animation-duration-200 {
  animation-duration: .2s !important;
}

.animation-duration-300 {
  animation-duration: .3s !important;
}

.animation-duration-400 {
  animation-duration: .4s !important;
}

.animation-duration-500 {
  animation-duration: .5s !important;
}

.animation-duration-1000 {
  animation-duration: 1s !important;
}

.animation-duration-2000 {
  animation-duration: 2s !important;
}

.animation-duration-3000 {
  animation-duration: 3s !important;
}

.animation-delay-100 {
  animation-delay: .1s !important;
}

.animation-delay-150 {
  animation-delay: .15s !important;
}

.animation-delay-200 {
  animation-delay: .2s !important;
}

.animation-delay-300 {
  animation-delay: .3s !important;
}

.animation-delay-400 {
  animation-delay: .4s !important;
}

.animation-delay-500 {
  animation-delay: .5s !important;
}

.animation-delay-1000 {
  animation-delay: 1s !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, .2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-classic, .fa-sharp, .fas, .fa-solid, .far, .fa-regular, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .fa-classic, .fa-solid, .far, .fa-regular {
  font-family: "Font Awesome 6 Free";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .0833333em;
}

.fa-sm {
  vertical-align: .0535714em;
  font-size: .875em;
  line-height: .0714286em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .0416667em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-g:before {
  content: "G";
}

.fa-notes-medical:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-snowflake:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-tarp:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-file-medical:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-angle-down:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-share:before, .fa-mail-forward:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.f88bf5bd.woff2") format("woff2"), url("fa-brands-400.4ca064ed.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-pixiv:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-brave:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-opensuse:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-square-letterboxd:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-google-scholar:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-signal-messenger:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-mintbit:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-brave-reverse:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-letterboxd:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-upwork:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-webflow:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.dfc33a0e.woff2") format("woff2"), url("fa-regular-400.1089164c.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.1f6e23c0.woff2") format("woff2"), url("fa-solid-900.89d7d2cc.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

.router-link-active[data-v-ad6b25] {
  background-color: #e5e7eb;
  border-radius: 6px;
}

.layout[data-v-64f1d6] {
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.layout__header[data-v-64f1d6] {
  margin-bottom: 2rem;
}

.layout__content[data-v-64f1d6] {
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  display: flex;
}

.p-progressbar-determinate .p-progressbar-value-animate {
  transition: width ease-in-out !important;
}

.p-autocomplete > ul {
  width: 100%;
}

/*# sourceMappingURL=index.635581a7.css.map */

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}